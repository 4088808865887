import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidationService } from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import * as i18nIsoCountries from 'i18n-iso-countries';
import {
  AsYouType,
  CountryCode,
  isValidNumberForRegion,
  parsePhoneNumber,
} from 'libphonenumber-js';
import {
  IOTPResponse,
  IUserProfileForm,
} from 'src/app/dtos/userProfile/userProfile.dto';
import {
  INotificationRequest,
  IUserNotification,
  NotificationTypes,
} from 'src/app/models/user.model';
@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
})
export class UserprofileComponent {
  @Input('defaultCountryCode') set defaultCountryCodeSetter(
    defaultCountryCode: string
  ) {
    this.defaultCountryCode = defaultCountryCode;
  }
  @Input('countries') set countriesSetter(countries: any[]) {
    this.countries = countries;
  }
  @Input('userProfileDetails') set userProfileDetailsSetter(
    userProfileDetails: IUserProfileForm
  ) {
    this.userProfileDetails = userProfileDetails;
    this.initializeForm();
    this.formatPhoneToInternational();
  }
  @Input('userNotifications') set userNotificationsSetter(
    userNotifications: IUserNotification[]
  ) {
    this.userNotifications = userNotifications;
    this.initializeNotificationForm();
  }
  @Input('otpToggleResponse') set otpToggleResponseSetter(
    containerResponse: IOTPResponse
  ) {
    this.otpToggleResponse = containerResponse;
  }
  @Input() public DTFormat;
  @Input() public fetching = false;
  @Input() public languageTypes;
  @Input() public timeZoneTypes;
  @Output() public updatedProfileInfo = new EventEmitter<any>();
  @Output() public updateUserNotifications = new EventEmitter<any>();
  @Output() public otpToggleEmitter = new EventEmitter<any>();
  @Output() public passwordConfirmationEmitter = new EventEmitter<any>();
  private otpToggleResponse: IOTPResponse;
  public userProfileForm: FormGroup;
  public userNotificationsForm: FormGroup;
  public loading = false;
  public phoneForm: FormGroup;
  public showPasswordEdit = false;
  public passwordEmpty = true;
  public countries = [];
  public defaultCountryCode = 'CAN';
  public message = {
    confirmPassword: () =>
      this.translocoService.translate('COMMON.PASSWORD_MUST_MATCH'),
  };
  public extension = {
    extension: () => 'Extension must be digits (maximum of 10 digits)',
  };
  public userProfileDetails: IUserProfileForm;
  public userNotifications: IUserNotification[];
  public phoneNumber = new AsYouType();
  public activateUserInfoTab = true;
  public defaultInterval = 24;
  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private translocoService: TranslocoService
  ) {}

  /*Handling Password Checkbox  Click */
  public handleUpdatePassword(status) {
    this.showPasswordEdit =
      status.checked && status.checked === true ? true : false;
  }

  private initializeForm() {
    const userProfileDetails: IUserProfileForm =
      this.userProfileDetails ||
      ({
        firstName: '',
        lastName: '',
        userName: '',
        OTPRequired: null,
        phoneCountry: 'CA',
        phone: '',
        phoneExtension: '',
        languageCode: '',
        timeZoneCode: '',
        dateTypeKey: 1,
      } as IUserProfileForm);
    this.userProfileForm = this.formBuilder.group(
      {
        firstName: [userProfileDetails.firstName, [Validators.required]],
        lastName: [userProfileDetails.lastName, [Validators.required]],
        userName: [
          userProfileDetails.userName,
          [Validators.required, Validators.email],
        ],
        OTPRequired: userProfileDetails.OTPRequired || false,
        password: [
          '',
          Validators.compose([
            this.validationService.patternValidator('number'),
            this.validationService.patternValidator('upperCase'),
            this.validationService.patternValidator('lowerCase'),
            this.validationService.specialCharacterValidator(),
            this.validationService.minimumLength(8),
          ]),
        ],
        confirmPassword: [''],
        languageCode: [userProfileDetails.languageCode, [Validators.required]],
        timeZoneCode: [userProfileDetails.timeZoneCode, [Validators.required]],
        dateTypeKey: [userProfileDetails.dateTypeKey, [Validators.required]],
      },
      {
        validator: this.validationService.MustMatch(
          'password',
          'confirmPassword'
        ),
      }
    );
    this.phoneForm = this.formBuilder.group(
      {
        phoneCountry: [this.formatCountryCodeForDropdown()],
        phoneExtension: [
          `${userProfileDetails?.phoneExtension || ''}`,
          Validators.compose([
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(10),
          ]),
        ],
        phone: [
          `${userProfileDetails?.phone}`,
          Validators.compose([
            Validators.required,
            this.validatePhoneNumber(parsePhoneNumber),
          ]),
        ],
      },
      {
        validators: this.validatePhoneForm('phone', 'phoneExtension'),
      }
    );
  }

  public initializeNotificationForm() {
    if (this.userNotifications == null) {
      return;
    }
    this.userNotificationsForm = this.formBuilder.group({
      poaReceived: [
        this.getFieldValue('userNotificationKey', NotificationTypes.POA) ===
          null || !this.getFieldValue('isActive', NotificationTypes.POA)
          ? false
          : true,
      ],
      poaInterval: [
        {
          value:
            this.getFieldValue('userNotificationKey', NotificationTypes.POA) ===
              null ||
            this.getFieldValue('interval', NotificationTypes.POA) === 0
              ? null
              : this.getFieldValue('interval', NotificationTypes.POA),
          disabled:
            this.getFieldValue('userNotificationKey', NotificationTypes.POA) ===
              null || !this.getFieldValue('isActive', NotificationTypes.POA)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('poaReceived').value
          ),
        ],
      ],
      poReceived: [
        this.getFieldValue('userNotificationKey', NotificationTypes.PO) ===
          null || !this.getFieldValue('isActive', NotificationTypes.PO)
          ? false
          : true,
      ],
      poInterval: [
        {
          value:
            this.getFieldValue('userNotificationKey', NotificationTypes.PO) ===
              null || this.getFieldValue('interval', NotificationTypes.PO) === 0
              ? null
              : this.getFieldValue('interval', NotificationTypes.PO),
          disabled:
            this.getFieldValue('userNotificationKey', NotificationTypes.PO) ===
              null || !this.getFieldValue('isActive', NotificationTypes.PO)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('poReceived').value
          ),
        ],
      ],
      invoiceReceived: [
        this.getFieldValue('userNotificationKey', NotificationTypes.Invoice) ===
          null || !this.getFieldValue('isActive', NotificationTypes.Invoice)
          ? false
          : true,
      ],
      invoiceInterval: [
        {
          value:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.Invoice
            ) === null ||
            this.getFieldValue('interval', NotificationTypes.Invoice) === 0
              ? null
              : this.getFieldValue('interval', NotificationTypes.Invoice),
          disabled:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.Invoice
            ) === null ||
            !this.getFieldValue('isActive', NotificationTypes.Invoice)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('invoiceReceived').value
          ),
        ],
      ],
      asnReceived: [
        this.getFieldValue('userNotificationKey', NotificationTypes.ASN) ===
          null || !this.getFieldValue('isActive', NotificationTypes.ASN)
          ? false
          : true,
      ],
      asnInterval: [
        {
          value:
            this.getFieldValue('userNotificationKey', NotificationTypes.ASN) ===
              null ||
            this.getFieldValue('interval', NotificationTypes.ASN) === 0
              ? null
              : this.getFieldValue('interval', NotificationTypes.ASN),
          disabled:
            this.getFieldValue('userNotificationKey', NotificationTypes.ASN) ===
              null || !this.getFieldValue('isActive', NotificationTypes.ASN)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('asnReceived').value
          ),
        ],
      ],
      collectionBatch: [
        this.getFieldValue(
          'userNotificationKey',
          NotificationTypes.CollectionBatch
        ) === null ||
        !this.getFieldValue('isActive', NotificationTypes.CollectionBatch)
          ? false
          : true,
      ],
      collectionBatchInterval: [
        {
          value:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.CollectionBatch
            ) === null ||
            this.getFieldValue(
              'interval',
              NotificationTypes.CollectionBatch
            ) === 0
              ? null
              : this.getFieldValue(
                  'interval',
                  NotificationTypes.CollectionBatch
                ),
          disabled:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.CollectionBatch
            ) === null ||
            !this.getFieldValue('isActive', NotificationTypes.CollectionBatch)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('collectionBatch').value
          ),
        ],
      ],
      invoiceKeptOnHold: [
        this.getFieldValue(
          'userNotificationKey',
          NotificationTypes.InvoiceKeptOnHold
        ) === null ||
        !this.getFieldValue('isActive', NotificationTypes.InvoiceKeptOnHold)
          ? false
          : true,
      ],
      invoiceKeptOnHoldInterval: [
        {
          value:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.InvoiceKeptOnHold
            ) === null ||
            this.getFieldValue(
              'interval',
              NotificationTypes.InvoiceKeptOnHold
            ) === 0
              ? null
              : this.getFieldValue(
                  'interval',
                  NotificationTypes.InvoiceKeptOnHold
                ),
          disabled:
            this.getFieldValue(
              'userNotificationKey',
              NotificationTypes.InvoiceKeptOnHold
            ) === null ||
            !this.getFieldValue('isActive', NotificationTypes.InvoiceKeptOnHold)
              ? true
              : false,
        },
        [
          Validators.pattern('^[0-9]+(.[0-9]{0,9})?$'),
          this.requiredIfValidator(
            () => this.userNotificationsForm.get('invoiceKeptOnHold').value
          ),
        ],
      ],
    });

    this.loading = true;

    this.userNotifications?.map((notifn) => {
      const valueChanges = this.userNotificationsForm?.controls[
        `'${notifn.checkboxCtl}`
      ]
        ? this.userNotificationsForm
            .get(notifn.checkboxCtl)
            .valueChanges.subscribe((value) => {
              this.userNotificationsForm
                ?.get(notifn.intervalCtl)
                ?.updateValueAndValidity({ emitEvent: false });
            })
        : '';
    });
  }

  private getFieldValue(field, value) {
    let data;
    switch (field) {
      case 'notificationTypeKey':
        data =
          this.userNotifications.filter((e) => {
            return e.notificationTypeKey === value;
          }).length > 0;

        break;
      case 'userNotificationKey':
        const notifn = this.userNotifications.find(
          (n) => n.notificationTypeKey === value
        );
        data = notifn.userNotificationKey;

        break;
      case 'interval':
        const userNotifns = this.userNotifications.find(
          (n) => n.notificationTypeKey === value
        );
        data = userNotifns.interval;
        break;
      case 'isActive':
        const userNotifn = this.userNotifications.find(
          (n) => n.notificationTypeKey === value
        );
        data = userNotifn.isActive;
        break;
      default:
        break;
    }
    return data;
  }
  public checkBoxStatus(event) {
    switch (event) {
      case NotificationTypes.POA:
        if (!this.userNotificationsForm.value['poaReceived']) {
          this.userNotificationsForm.controls.poaInterval.setValue(null);
          this.userNotificationsForm.get('poaInterval').disable();
        } else {
          this.userNotificationsForm.get('poaInterval').enable();
          this.userNotificationsForm.controls.poaInterval.setValue(
            this.defaultInterval
          );
        }
        break;
      case NotificationTypes.PO:
        if (!this.userNotificationsForm.value['poReceived']) {
          this.userNotificationsForm.controls.poInterval.setValue(null);
          this.userNotificationsForm.get('poInterval').disable();
        } else {
          this.userNotificationsForm.controls.poInterval.setValue(
            this.defaultInterval
          );
          this.userNotificationsForm.get('poInterval').enable();
        }
        break;
      case NotificationTypes.Invoice:
        if (!this.userNotificationsForm.value['invoiceReceived']) {
          this.userNotificationsForm.controls.invoiceInterval.setValue(null);
          this.userNotificationsForm.get('invoiceInterval').disable();
        } else {
          this.userNotificationsForm.controls.invoiceInterval.setValue(
            this.defaultInterval
          );
          this.userNotificationsForm.get('invoiceInterval').enable();
        }
        break;
      case NotificationTypes.ASN:
        if (!this.userNotificationsForm.value['asnReceived']) {
          this.userNotificationsForm.controls.asnInterval.setValue(null);
          this.userNotificationsForm.get('asnInterval').disable();
        } else {
          this.userNotificationsForm.controls.asnInterval.setValue(
            this.defaultInterval
          );
          this.userNotificationsForm.get('asnInterval').enable();
        }
        break;
      case NotificationTypes.CollectionBatch:
        if (!this.userNotificationsForm.value['collectionBatch']) {
          this.userNotificationsForm.controls.collectionBatchInterval.setValue(
            null
          );
          this.userNotificationsForm.get('collectionBatchInterval').disable();
        } else {
          this.userNotificationsForm.controls.collectionBatchInterval.setValue(
            this.defaultInterval
          );
          this.userNotificationsForm.get('collectionBatchInterval').enable();
        }
        break;
      case NotificationTypes.InvoiceKeptOnHold:
        if (!this.userNotificationsForm.value['invoiceKeptOnHold']) {
          this.userNotificationsForm.controls.invoiceKeptOnHoldInterval.setValue(
            null
          );
          this.userNotificationsForm.get('invoiceKeptOnHoldInterval').disable();
        } else {
          this.userNotificationsForm.controls.invoiceKeptOnHoldInterval.setValue(
            this.defaultInterval
          );
          this.userNotificationsForm.get('invoiceKeptOnHoldInterval').enable();
        }
        break;
    }
  }
  public requiredIfValidator(predicate) {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }
  public passwordFieldEmptyCheck(passwordStatus) {
    this.passwordEmpty = passwordStatus ? false : true;
  }

  public handleVerification(event: any) {
    try {
      const reducedUserForm = {};
      reducedUserForm['OTPRequired'] = event.desiredState;
      this.otpToggleEmitter.emit({
        ...reducedUserForm,
      });
    } catch {
      this.otpToggleResponse = {
        success: false,
        message: this.translocoService.translate('COMMON.UNKNOWN_ERROR'),
      };
    }
  }
  public handleNotificationsUpdate() {
    const request: INotificationRequest[] = [];
    this.userNotifications.map((ctl) => {
      let interval;
      let isActive;
      switch (ctl.notificationTypeKey) {
        case NotificationTypes.POA:
          interval = this.userNotificationsForm.get('poaInterval').value
            ? Number(this.userNotificationsForm.get('poaInterval').value)
            : 0;
          isActive = this.userNotificationsForm.get('poaReceived').value;
          break;
        case NotificationTypes.PO:
          interval = this.userNotificationsForm.get('poInterval').value
            ? Number(this.userNotificationsForm.get('poInterval').value)
            : 0;
          isActive = this.userNotificationsForm.get('poReceived').value;
          break;
        case NotificationTypes.Invoice:
          interval = this.userNotificationsForm.get('invoiceInterval').value
            ? Number(this.userNotificationsForm.get('invoiceInterval').value)
            : 0;
          isActive = this.userNotificationsForm.get('invoiceReceived').value;
          break;
        case NotificationTypes.ASN:
          interval = this.userNotificationsForm.get('asnInterval').value
            ? Number(this.userNotificationsForm.get('asnInterval').value)
            : 0;
          isActive = this.userNotificationsForm.get('asnReceived').value;
          break;
        case NotificationTypes.CollectionBatch:
          interval = this.userNotificationsForm.get('collectionBatchInterval')
            .value
            ? Number(
                this.userNotificationsForm.get('collectionBatchInterval').value
              )
            : 0;
          isActive = this.userNotificationsForm.get('collectionBatch').value;
          break;
        case NotificationTypes.InvoiceKeptOnHold:
          interval = this.userNotificationsForm.get('invoiceKeptOnHoldInterval')
            .value
            ? Number(
                this.userNotificationsForm.get('invoiceKeptOnHoldInterval')
                  .value
              )
            : 0;
          isActive = this.userNotificationsForm.get('invoiceKeptOnHold').value;
          break;
        default:
          break;
      }

      request.push({
        Interval: interval,
        IsActive: isActive,
        UserNotificationKey: ctl.userNotificationKey,
        UserKey: ctl.userKey,
        NotificationTypeKey: ctl.notificationTypeKey,
        AddedOn: ctl.addedOn,
        AddedBy: ctl.addedBy,
      });
    });

    this.updateUserNotifications.emit(request);
  }
  /*Handling Save Button Click */
  public handleSubmit() {
    try {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: i18nIsoCountries.alpha2ToAlpha3(
          this.phoneForm.controls.phoneCountry.value || 'CA'
        ),
        phone: parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value as CountryCode
        ).format('E.164'),
      });
    } catch {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: this.defaultCountryCode || 'CAN',
        phone: '',
        phoneExtension: '',
      });
    }
  }

  // bound to Phone control's input event
  public formatPhoneNumber() {
    try {
      if (this.phoneForm.controls.phone.value.length < 1) {
        this.phoneForm.controls.phoneCountry.setValue(
          i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN')
        );
      }
      this.phoneForm.controls.phone.setValue(
        parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value
        ).formatInternational()
      );
    } catch {
      /*empty*/
    }
  }

  // bound to Countries Dropdown control's onChange event
  public resetPhoneNumber() {
    this.phoneForm?.controls?.phone?.setValue('');
  }

  // sets country code from 3 to 2 numbers
  private formatCountryCodeForDropdown() {
    try {
      return i18nIsoCountries.alpha3ToAlpha2(
        this.userProfileDetails.phoneCountry.trim()
      );
    } catch {
      return i18nIsoCountries.alpha3ToAlpha2(this.defaultCountryCode || 'CAN');
    }
  }

  // helper function for userProfileDetailsSetter
  private formatPhoneToInternational() {
    try {
      this.phoneNumber.input(this.userProfileDetails.phone);

      this.phoneForm.controls.phone.setValue(
        this.phoneNumber.getNumber().formatInternational()
      );
    } catch {
      this.phoneForm.controls.phone.setValue(
        this.userProfileDetails ? this.userProfileDetails.phone : ''
      );
    }
  }

  public validatePhoneForm(
    phoneNumberControlName: string,
    ExtensionControlName: string
  ): ValidatorFn {
    return (formGroup: FormGroup) => {
      try {
        const phoneNumberControl = formGroup.controls[phoneNumberControlName];
        const extensionControl = formGroup.controls[ExtensionControlName];
        return extensionControl &&
          extensionControl.value &&
          extensionControl.value.length > 0 &&
          phoneNumberControl.value.length === 0
          ? { invalidPhoneNumber: true }
          : null;
      } catch (error) {
        return error;
      }
    };
  }

  public validatePhoneNumber(
    parser: (text: string) => { country?: any; isValid(): boolean }
  ): ValidatorFn {
    return (control: AbstractControl) => {
      try {
        const phoneNumber = parser(control.value.toString());
        return phoneNumber?.isValid() &&
          isValidNumberForRegion(
            control.value.toString(),
            this.phoneForm?.controls?.phoneCountry?.value
          )
          ? null
          : {
              invalidPhoneNumber: true,
            };
      } catch {
        return control?.value?.length < 1
          ? null
          : {
              invalidPhoneNumber: true,
            };
      }
    };
  }
}
