import { CdkTree } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  IterableDiffers,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LuiTreeNodeOutlet } from './outlet';

/**
 * Wrapper for the CdkTable with Material design styles.
 */
@Component({
  selector: 'lui-tree',
  exportAs: 'luiTree',
  template: `<ng-container luiTreeNodeOutlet></ng-container>`,
  encapsulation: ViewEncapsulation.None,
  // See note on CdkTree for explanation on why this uses the default change detection strategy.
  // tslint:disable-next-line:validate-decorators
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [{ provide: CdkTree, useExisting: LuiTree }],
})

// tslint:disable-next-line: component-class-suffix
export class LuiTree<T> extends CdkTree<T> {
  @HostBinding('attr.role') public get attributeRole() {
    return 'tree';
  }
  @HostBinding('class') public get class() {
    return 'lui-tree';
  }
  // Outlets within the tree's template where the dataNodes will be inserted.
  @ViewChild(LuiTreeNodeOutlet, { static: true })
  public _nodeOutlet: LuiTreeNodeOutlet;
  constructor(iter: IterableDiffers, changeDetectorRef: ChangeDetectorRef) {
    super(iter, changeDetectorRef);
  }
}
