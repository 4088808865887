import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateTypes } from '@lbmx/per-form-primeng';
import { Request } from '@lbmx/web-core';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-wizard-loader',
  templateUrl: './wizard-loader.component.html',
  styleUrls: ['./wizard-loader.component.scss'],
})
export class WizardLoaderComponent {
  public wizardConfig: Request = null;
  public redirectUrl: string;
  public templateTypes = TemplateTypes;
  public baseUrl = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configProvider: ConfigProvider
  ) {
    this.baseUrl = configProvider.AppSetting?.uriBase?.apiBaseUrl;
    this.wizardConfig =
      this.router.getCurrentNavigation()?.extras?.state ??
      this.route.snapshot.data?.wizardConfig ??
      null;
    this.redirectUrl = this.route.snapshot.data?.redirectUrl;
    if (!this.wizardConfig) {
      this.router.navigate([this.redirectUrl], { relativeTo: this.route });
    }
  }

  public redirect() {
    this.router.navigate([this.redirectUrl], { relativeTo: this.route });
  }
}
