import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ColumnConfig } from '@lbmx/root-services';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
interface SortEvent {
  direction: 'asc' | 'desc';
  ctrlKey: boolean;
}

@Component({
  selector: '[luiTableHeader]',
  templateUrl: './table-header.html',
  styleUrls: ['./table-header.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class LuiTableHeader {
  @HostBinding('class.selected') get isSortColumnSorted() {
    return this.isSorted;
  }

  @Input('luiTableHeader') set columnConfig({
    isResizable,
    isSortable,
  }: ColumnConfig) {
    this.isResizable = isResizable ?? true;
    this.isSortable = isSortable ?? true;
  }

  @Input() public columnKey: string;
  @Input() public columnsToDisplay: string[];
  @Input() set colWidth(width: number) {
    this.width = width;
  }
  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    public ele: ElementRef
  ) {
    fromEvent(this.documentRef, 'mouseup')
      .pipe(filter(() => !!this.width))
      .subscribe(() => {
        this.isResizing = false;
        this.widthChange.emit(this.width);
      });
  }

  @HostBinding('style.min-width.px')
  public width: number | null = null;

  @Input() public isSorted = false;
  @Output() public widthChange: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() public sortChange: EventEmitter<SortEvent> =
    new EventEmitter<SortEvent>();
  @Output() public columnChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  public resizeElement = document.body.getElementsByClassName('resize-control');
  public topElement = document.body.getElementsByClassName('sort-asc');
  public bottomElement = document.body.getElementsByClassName('sort-desc');
  public isResizing = false;
  public isSortable = false;
  public isResizable = true;
  public onResize(width: number) {
    this.isResizing = true;
    this.width = width;
  }
  public sortColumn(event: KeyboardEvent, dir: 'asc' | 'desc') {
    const config: SortEvent = {
      ctrlKey: event.ctrlKey,
      direction: dir,
    };
    // tslint:disable-next-line:no-unused-expression
    this.isSortable && this.sortChange.emit(config);
  }
  public dragStart(event: CdkDragStart) {
    Array.from(this.resizeElement).forEach((ele) => {
      ele.classList.remove('resize');
    });
    Array.from(this.topElement).forEach((ele) => {
      ele.classList.remove('top-control');
    });
    Array.from(this.bottomElement).forEach((ele) => {
      ele.classList.remove('bottom-control');
    });
  }
  public dropped(event: CdkDragDrop<string[]>) {
    Array.from(this.resizeElement).forEach((ele) => {
      ele.classList.add('resize');
    });
    Array.from(this.topElement).forEach((ele) => {
      ele.classList.add('top-control');
    });
    Array.from(this.bottomElement).forEach((ele) => {
      ele.classList.add('bottom-control');
    });
    let columns: string[] = [];
    if (event) {
      columns = event.container.data.slice(1, -1);
      moveItemInArray(columns, event.previousIndex, event.currentIndex);
      columns.unshift('select');
      columns.push('fill');
      this.columnChange.emit(columns);
    }
  }
}
