import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { openClose } from './animations';
import { LuiAccordionItem } from './item';
import { COMPONENT_NAMESPACE, ROLES } from './roles';

@Component({
  selector: 'lui-accordion-standard-item',
  templateUrl: './standard-item.html',
  animations: [openClose],
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable-next-line: component-class-suffix
export class LuiAccordionStandardItem {
  @Input() public header: string;

  @Input() public leftAlignIcon = false;

  @Input() public filtersSelected = false;

  @Input() public filterIconPath: string;

  @ViewChild(LuiAccordionItem) public accordionItem: LuiAccordionItem;

  public namespace = COMPONENT_NAMESPACE;

  public panel = ROLES.PANEL;
}
