import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import { EnvService } from '../services/env.service';

@Injectable()
export class ConfigProvider implements OnDestroy {
  //#region Properties

  private appSetting: AppSetting = null;
  public subscriptions: Subscription[] = [];

  //#endregion

  constructor(private http: HttpClient, private env: EnvService) {}

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  //#region Methods
  get AppSetting(): AppSetting {
    return this.appSetting;
  }

  public load() {
    return new Promise((resolve, reject) => {
      let jsonFile;

      if (this.env.Name === 'debug') {
        jsonFile = `assets/config/config.debug.json`;
      } else {
        jsonFile = `assets/config/config.json`;
      }

      this.subscriptions.push(
        this.http
          .get(jsonFile)
          .pipe(map((data) => data as AppSetting))
          .subscribe((data) => {
            this.appSetting = data;
            resolve(true);
          })
      );
    });
  }
  //#endregion
}
