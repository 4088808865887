import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenerateFormService, PerForm } from '@lbmx/per-form';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { BaseHttpService } from '@lbmx/web-core';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { schemaDefinition } from './exclude-options.ui';

export interface UserExcludeOptions {
  excludeOption?: [];
}

export interface ExcludeOptionsForm {
  fields: object;
  errorCode?: number;
}

export interface SelectedExcludeOptions {
  record?: {
    ExcludeOption?: number;
  };
  errorCode?: number;
}

@Component({
  selector: 'lib-exclude-options-container',
  template: ` <lib-exclude-options
    [form]="form"
    (closeDialog)="closeDialog.emit()"
    (saveDialog)="save($event)"
  ></lib-exclude-options>`,
  styles: ['body .ui-dialog .ui-dialog-content { overflow-auto; }']
})
export class ExcludeOptionsContainerComponent implements OnInit {
  @Input() public baseUrl: string;
  @Input() public selectedExcludeOptions: any;
  @Input() public exclusionType: string;
  @Output() public closeDialog = new EventEmitter();
  @Output() public updateExcludeOptions = new EventEmitter();

  public form: PerForm;
  public schemaDefinition = schemaDefinition;
  constructor(
    private http: BaseHttpService,
    private spinner: SpinnerService,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService,
    private formGenerator: GenerateFormService
  ) {}

  public ngOnInit(): void {
    this.http
      .fetchWithFqn({
        endpoint: `${this.baseUrl}` + '/exclude-options/form',
        httpMethod: 'GET',
      })
      .pipe(
        tap(({ fields }: any) => {
          this.form = this.formGenerator.init(
            this.schemaDefinition(fields),
            this.selectedExcludeOptions
          );
        }),
        catchError(() =>
          of(
            this.toast.errorNotify(
              this.translocoService.translate('MESSAGES.GENERIC_ERROR')
            )
          )
        )
      )
      .subscribe();
  }

  public save(formValues: any) {
    this.spinner.on();
    formValues.selectedExcludeOptions = this.selectedExcludeOptions;
    formValues.exclusionType = this.exclusionType;

    this.http
      .fetchWithFqn({
        endpoint: `${this.baseUrl}/exclude-options`,
        httpMethod: 'POST',
        body: formValues,
      })
      .pipe(
        tap((response: any) => {
          this.toast.successNotify(
            this.translocoService.translate('GROUP.SUCCESS_TOAST_MESSAGE')
          );
          this.closeDialog.emit();
          this.updateExcludeOptions.emit();
        }),
        catchError(() =>
          of(
            this.toast.errorNotify(
              this.translocoService.translate('MESSAGES.GENERIC_ERROR')
            )
          )
        ),
        finalize(() => this.spinner.off())
      )
      .subscribe();
  }
}
