import { Routes } from '@angular/router';
import { ErrorComponent } from 'src/app/features/secure/error/error.component';
import { ExportsComponent } from 'src/app/features/secure/exports/exports.component';
import { DashboardTemplateComponent } from 'src/app/features/secure/marketplace-analytics/dashboard-template/dashboard-template.component';
import { OrderedByLocationExclusionComponent } from 'src/app/features/secure/marketplace-analytics/exclusions/ordered-by-location-exclusion/ordered-by-location-exclusion.component';
import { SupplierLocationExclusionComponent } from 'src/app/features/secure/marketplace-analytics/exclusions/supplier-location-exclusion/supplier-location-exclusion.component';
import { FlexmonsterComponent } from 'src/app/features/secure/marketplace-analytics/flexmonster/flexmonster.component';
import { ReportBuilderLandingComponent } from 'src/app/features/secure/marketplace-analytics/report-builder-landing/report-builder-landing.component';
import { ReportsComponent } from 'src/app/features/secure/marketplace-analytics/reports/reports.component';
import { AuthGuardService } from 'src/app/services/authGuard/authGuard.service';
import { DefaultRouteService } from 'src/app/services/defaultRoute/default-route.service';
import { WizardLoaderComponent } from './../../features/secure/demo/wizard-loader/wizard-loader.component';
import { HomeComponent } from './../../features/secure/home/home.component';
import { UserProfileContainerComponent } from './../../features/secure/userprofile/user-profile-container';

export const SECURE_ROUTES: Routes = [
  {
    path: 'default',
    canActivate: [DefaultRouteService],
    component: HomeComponent,
  },
  {
    path: 'home',
    data: { breadcrumb: 'BREADCRUMB.HOME' },
    component: HomeComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'UserModule',
    children: [
      {
        path: '',
        redirectTo: 'userProfile',
        pathMatch: 'full',
      },
      {
        path: 'userProfile',
        data: { breadcrumb: 'BREADCRUMB.USER_PROFILE' },
        component: UserProfileContainerComponent,
      },
    ],
  },
  {
    path: 'analyticsModule',
    data: { breadcrumb: { label: 'BREADCRUMB.ANALYTICS', disable: true } },
    children: [
      {
          path: 'admin-centre',
          data: {
            breadcrumb: 'BREADCRUMB.ADMIN_CENTRE',
          },
          children: [
            {
            path: 'ordered-by-location-exclusion',
            data: {
              breadcrumb: { label: 'BREADCRUMB.ORDERED_BY_LOCATION_EXCLUSIONS', disable: true },
            },
            component: OrderedByLocationExclusionComponent,
            },
            {
              path: 'supplier-location-exclusion',
              data: {
                breadcrumb: { label: 'BREADCRUMB.SUPPLIER_LOCATION_EXCLUSIONS', disable: true },
              },
              component: SupplierLocationExclusionComponent,
            }
          ]
      },
      {
        path: 'dashboards',
        data: { breadcrumb: { label: 'BREADCRUMB.DASHBOARDS', disable: true } },
        children: [
          {
            path: 'reports',
            data: {
              breadcrumb: { label: 'BREADCRUMB.REPORTS', disable: true },
              appSettingKeys: ['uriAnalytics', 'getReportsDashboardConfig'],
            },
            component: ReportsComponent,
          },
          {
            path: 'report-builder',
            data: {
              breadcrumb: 'BREADCRUMB.REPORT_BUILDER',
            },
            component: ReportBuilderLandingComponent,
          },
          {
            path: 'reportBuilder',
            data: {
              breadcrumb: {
                label: 'BREADCRUMB.REPORT_BUILDER',
                disable: true,
              },
            },
            children: [
              {
                path: 'reportCreation',
                data: { breadcrumb: 'BREADCRUMB.REPORT_CREATION' },
                component: FlexmonsterComponent,
              },
            ],
          },
          {
            path: ':dashboard',
            component: DashboardTemplateComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'exportsModule',
    data: {
      breadcrumb: {
        label: 'NAV.EXPORT',
        disable: true,
      },
    },
    children: [
      {
        path: 'exports',
        data: {
          breadcrumb: {
            label: 'NAV.VIEW_EXPORTS',
            disable: true,
          },
        },
        component: ExportsComponent,
      },
    ],
  },
  {
    path: 'demo',
    data: {
      breadcrumb: {
        label: 'NAV.DEMO',
        disable: true,
      },
    },
    children: [
      {
        path: '',
        data: {
          breadcrumb: {
            label: 'NAV.DEMO',
            disable: true,
          },
          wizardConfig: {
            endpoint: `/rebate/form/program/new`,
            httpMethod: 'POST',
          },
        },
        component: WizardLoaderComponent,
      },
      {
        path: 'wizard',
        data: {
          breadcrumb: {
            label: 'Wizard',
            disable: true,
          },
          redirectUrl: '/demo',
        },
        component: WizardLoaderComponent,
      },
    ],
  },
  {
    path: 'PlaygroundModule',
    data: { breadcrumb: 'playgroundModule' },
    loadChildren: () =>
      import('../../wrapper-modules/playground-wrapper.module').then(
        (m) => m.PlaygroundWrapperModule
      ),
  },
  {
    path: 'PIMModule',
    data: { breadcrumb: 'BREADCRUMB.PIM' },
    loadChildren: () =>
      import('../../wrapper-modules/pim-wrapper.module').then(
        (m) => m.PimWrapperModule
      ),
  },
  {
    path: 'pdx-module',
    data: { breadcrumb: 'BREADCRUMB.PDX' },
    loadChildren: () =>
      import('../../wrapper-modules/pim-wrapper.module').then(
        (m) => m.PimWrapperModule
      ),
  },
  {
    path: 'AdministrationModule',
    loadChildren: () =>
      import('../../wrapper-modules/administration-wrapper.module').then(
        (m) => m.AdministrationWrapperModule
      ),
  },
  {
    path: 'MarketplacePartnersModule',
    data: { breadcrumb: { label: 'BREADCRUMB.PARTNERS', disable: true } },
    loadChildren: () =>
      import('../../wrapper-modules/marketplace-partners-wrapper.module').then(
        (m) => m.MarketplacePartnersWrapperModule
      ),
  },
  {
    path: 'MarketplaceModule',
    data: { breadcrumb: 'BREADCRUMB.DOCUMENTS' },
    loadChildren: () =>
      import('../../wrapper-modules/marketplace-wrapper.module').then(
        (m) => m.MarketplaceWrapperModule
      ),
  },
  {
    path: 'pay-module',
    data: { breadcrumb: 'BREADCRUMB.PAY' },
    loadChildren: () =>
      import('../../wrapper-modules/pay-wrapper.module').then(
        (m) => m.PayWrapperModule
      ),
  },
  {
    path: 'RebatesModule',
    data: { breadcrumb: 'BREADCRUMB.REBATES' },
    loadChildren: () =>
      import('../../wrapper-modules/rebates-wrapper.module').then(
        (m) => m.RebatesWrapperModule
      ),
  },

  {
    path: 'AutomationsModule',
    data: { breadcrumb: 'BREADCRUMB.CONFIGURATION' },
    loadChildren: () =>
      import('../../wrapper-modules/automations-wrapper.module').then(
        (m) => m.AutomationsWrapperModule
      ),
  },
  {
    path: 'member-module',
    data: { breadcrumb: 'BREADCRUMB.CONFIGURATION' },
    loadChildren: () =>
      import('../../wrapper-modules/member-wrapper.module').then(
        (m) => m.MemberWrapperModule
      ),
  },
];
