import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { queryChange } from '@lbmx/action-ui';
import { GenerateFormService, PerForm } from '@lbmx/per-form';
import { LoadingState, SearchService } from '@lbmx/root-services';
import { BehaviorSubject, merge } from 'rxjs';
import { delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { TableDataSource } from '../../lui-legacy-components/data-source/table-data-source';
import { DefaultPaginatorComponent } from '../../lui-legacy-components/default-paginator/default-paginator.component';
import { LuiTableHeader } from '../../lui-legacy-components/table-header/table-header';
import { detailsForm } from './demo.ui';
@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements AfterViewInit {
  public dataSource = new TableDataSource<{ [key: string]: any }>();

  @ViewChild(DefaultPaginatorComponent)
  public paginator: DefaultPaginatorComponent;
  @ViewChild(LuiTableHeader) public tableHeader: LuiTableHeader;
  public idField = 'poNumber';
  public columns: string[] = this.dataSource.columnsToDisplay;
  public loadingState = LoadingState;
  public form$ = new BehaviorSubject<PerForm>(null);
  constructor(
    public searchService: SearchService,
    private formGeneratorService: GenerateFormService
  ) {
    this.dataSource.searchService = this.searchService;
    const add = this.dataSource.selection.changed.pipe(
      queryChange<
        {
          source: SelectionModel<string>;
          added: string[];
          removed: string[];
        },
        string[]
      >('added'),
      filter((x) => x.length >= 1),
      map((x) => x[0]),
      map((x) => this.dataSource.data.find((y) => y[this.idField] === x)),
      tap(() => this.form$.next(null)),
      delay(0),
      tap((row) =>
        this.form$.next(this.formGeneratorService.init(detailsForm(row)))
      )
    );
    const remove = this.dataSource.selection.changed.pipe(
      queryChange<
        {
          source: SelectionModel<string>;
          added: string[];
          removed: string[];
        },
        string[]
      >('removed'),
      filter((x) => x.length >= 1),
      map((x) => x[0]),
      withLatestFrom(add),
      filter(([x, y]) => y[this.idField] === x),
      tap(() => this.form$.next(null))
    );
    merge(add, remove).subscribe();
  }

  public loading = true;
  public loading$ = this.searchService.status$.pipe(
    map((status) => {
      return status === LoadingState.LOADING;
    })
  );
  public tableWidth = 0;
  public columnsTest = ['poNumber'];
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });
  }
}
