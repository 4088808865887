import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FieldsDefinition,
  Form,
  GenerateFormService,
  PerForm,
} from '@lbmx/per-form';
import urlJoin from 'proper-url-join';
import { combineLatest, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  public reportEndpoint = '';
  public form: PerForm;
  public params: Form;
  private _onDestroySubject = new Subject();

  constructor(
    private formGenerator: GenerateFormService,
    public http: HttpClient,
    public configPrv: ConfigProvider,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit(): void {
    const configuration = this.route.data.pipe(
      map(
        (data: {
          appSettingKeys: string[];
          path: string;
          [key: string]: any;
        }): string => {
          const [appSettingKey, appSettingSubKey]: string[] =
            data?.appSettingKeys;
          return urlJoin(
            this.configPrv.AppSetting[appSettingKey][appSettingSubKey],
            data?.path
          );
        }
      )
    );
    combineLatest([this.route.queryParams.pipe(first()), configuration])
      .pipe(
        tap(
          ([params, _]) =>
            (this.params = params?.filters ? JSON.parse(params.filters) : {})
        ),
        switchMap(([_, url]) =>
          this.http
            .get(url, {
              withCredentials: true,
            })
            .pipe(
              tap((response: { fields: FieldsDefinition<Form> }) => {
                this.form = this.formGenerator.init(
                  {
                    layout: {
                      'grid-template-columns': '1fr 1fr 1fr 1fr',
                      'column-gap': '1.5em',
                    },
                    fields: response.fields,
                  },
                  this.params
                );
              }),
              switchMap(() =>
                this.form.values$.pipe(
                  tap(
                    (values: Form) =>
                      (this.reportEndpoint = values?.report || '')
                  ),
                  tap((filters) =>
                    this.router.navigate(['./'], {
                      relativeTo: this.route,
                      queryParams:
                        Object.keys(filters).length !== 0
                          ? { filters: JSON.stringify(filters) }
                          : {},
                    })
                  )
                )
              )
            )
        ),
        takeUntil(this._onDestroySubject)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._onDestroySubject.next(true);
  }
}
