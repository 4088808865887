import { ActionCreator, DispatchSchema } from '@lbmx/action-ui';
import { AnalyticsState } from '@lbmx/app-state/lib/interfaces';

export interface KpiSelectionModalUiState {
  analytics: AnalyticsState;
  selectedCategory: string;
  selectedKpi: string;
  categoryDropdown: Array<{ label: string; value: string }>;
  kpiDropdown: Array<{ label: string; value: string }>;
  showCategories: boolean;
  categoryFilter: string[];
}

export interface KpiSelectionModalUiActions {
  setAnalyticsState: ActionCreator<KpiSelectionModalUiState>;
  setSelectedCategory: ActionCreator<KpiSelectionModalUiState>;
  setSelectedKpi: ActionCreator<KpiSelectionModalUiState>;
  setCategoryFilter: ActionCreator<KpiSelectionModalUiState>;
  setShowCategories: ActionCreator<KpiSelectionModalUiState>;
  setKpiDropdown: ActionCreator<KpiSelectionModalUiState>;
}

export const defaultState: KpiSelectionModalUiState = {
  analytics: {} as AnalyticsState,
  selectedCategory: '',
  selectedKpi: '',
  categoryDropdown: [],
  kpiDropdown: [],
  showCategories: true,
  categoryFilter: [],
};

export const schema: DispatchSchema<
  KpiSelectionModalUiActions,
  KpiSelectionModalUiState
> = {
  setAnalyticsState: {
    command: (
      analyticsState: AnalyticsState,
      { categoryFilter }: KpiSelectionModalUiState
    ) => {
      const allowedCategories =
        categoryFilter.length > 0
          ? categoryFilter
          : Object.keys(analyticsState.categories);

      return {
        analytics: analyticsState,
        categoryDropdown: Object.entries(analyticsState.categories).reduce(
          (dropdownOptions, [category, description]) => {
            return allowedCategories.includes(category)
              ? [
                  ...dropdownOptions,
                  {
                    label: description,
                    value: category,
                  },
                ]
              : dropdownOptions;
          },
          []
        ),
      };
    },
  },
  setSelectedCategory: {
    command: (category: string, { analytics }: KpiSelectionModalUiState) => {
      const filteredKpis = Object.entries(analytics.kpis).filter(([kpi]) => {
        const [categoryName] = kpi.split('.');
        return category === categoryName;
      });
      return {
        selectedCategory: category,
        kpiDropdown: filteredKpis.map(([kpi, config]) => ({
          label: config.description,
          value: kpi,
        })),
      };
    },
  },
  setKpiDropdown: {
    command: (_, { analytics, categoryFilter }: KpiSelectionModalUiState) => {
      const filteredKpis = Object.entries(analytics.kpis).filter(([kpi]) => {
        const [categoryName] = kpi.split('.');
        return categoryFilter.includes(categoryName);
      });
      return {
        kpiDropdown: filteredKpis.map(([kpi, config]) => ({
          label: config.description,
          value: kpi,
        })),
      };
    },
  },
  setSelectedKpi: {
    command: (kpi: string) => ({
      selectedKpi: kpi,
    }),
  },
  setCategoryFilter: {
    command: (filters: string[]) => ({
      categoryFilter: filters,
    }),
  },
  setShowCategories: {
    command: (show: boolean, { showCategories }: KpiSelectionModalUiState) => {
      return {
        showCategories: show === undefined ? showCategories : show,
      };
    },
  },
};
