import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OtpService } from '../otp/otp.service';
import { ErrorService } from './error.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: ErrorService,
    private otpService: OtpService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const status = err?.status;
        if (err?.error?.errorCode === 6) {
          this.otpService.status = status;
          this.otpService.request =
            status !== 403 && status !== 410 ? request : null;
          return of({});
        }
        return this.errorHandler.handleHttpErrorResponse(err);
      })
    );
  }
}
