import { ActionCreator, DispatchSchema } from '@lbmx/action-ui';
import {
  AnalyticsStatus,
  ChartTabConfig,
  ColorType,
  GridArea,
  Icons,
  Kpi,
  RedirectConfig,
  SearchFilters,
} from '@lbmx/analytics';
import { Form, FormDefinition } from '@lbmx/per-form';
import { ChartOptions } from 'chart.js';
import { ColumnsConfig } from '../report-loader/report-loader.ui';

export enum WidgetTypes {
  CHART = 'chart',
  KPI = 'kpi',
  GAUGE = 'gauge',
}

export interface Kpis {
  [key: string]: DashboardKpi;
}

export interface DashboardKpi extends Kpi {
  description: string;
  icons: Icons;
}

export interface SetWidget {
  index: number;
  name: string;
}

export interface WidgetTabConfig {
  [key: string]: {
    description: string;
    type: string;
    widgets: ChartTabConfig | Kpi;
  };
}

export interface AvailableWidget {
  type: string;
  label: string;
  endpoint: string;
  defaultQuery: SearchFilters;
  colorType?: ColorType;
  legendField?: string;
  options?: ChartOptions;
  subLabel?: string;
  toolTips?: Array<{ value: any; label: string }>;
  valueField?: string;
  valueFormattedField?: string;
  varianceField?: string;
  varianceFormattedField?: string;
  xAxisField?: string;
  yAxisField?: string;
  redirect?: RedirectConfig;
  columns?: ColumnsConfig[];
}
export interface DashboardState {
  configured: string[];
  types: string[];
  available: {
    [key: string]: AvailableWidget;
  };
  gridArea: number[][];
  smallGridArea?: GridArea;
  mediumGridArea?: GridArea;
  status: AnalyticsStatus;
  settings?: boolean;
}

export interface DashboardActions {
  finalize: ActionCreator<DashboardState>;
  setConfigured: ActionCreator<DashboardState>;
  setWidget: ActionCreator<DashboardState>;
  setStatus: ActionCreator<DashboardState>;
  setGridArea: ActionCreator<DashboardState>;
  updateState: ActionCreator<DashboardState>;
}

export const dashboardSchema: DispatchSchema<DashboardActions, DashboardState> =
  {
    finalize: {
      command: (_, state: DashboardState) => {
        const status = state.status < 0 ? state.status : AnalyticsStatus.DONE;
        return { status };
      },
    },
    setConfigured: { command: (configured: string[]) => ({ configured }) },
    setGridArea: { command: (gridArea: number[][]) => ({ gridArea }) },
    setStatus: { command: (status: AnalyticsStatus) => ({ status }) },
    setWidget: {
      command: ({ index, name }: SetWidget, { configured }: DashboardState) => {
        const newConfigured = [...configured];
        newConfigured[index] = name;
        return { configured: [...newConfigured] };
      },
    },
    updateState: {
      command: (state: Partial<DashboardState>) => ({ ...state }),
    },
  };

export const defaultDashboardState: DashboardState = {
  configured: [],
  types: [],
  gridArea: [],
  available: {},
  status: AnalyticsStatus.READY,
};

export interface ConfigResponse {
  record: {
    label: string;
    filters: FormDefinition<Form>;
    widgets: {
      configured: string[];
      grid: number[][];
      available: {
        [key: string]: {
          type: string;
          label: string;
          endpoint: string;
          defaultQuery: SearchFilters;
          legendField?: string;
          subLabel?: string;
          toolTips?: Array<{ value: any; label: string }>;
          valueField?: string;
          valueFormattedField?: string;
          varianceField?: string;
          varianceFormattedField?: string;
          xAxisField?: string;
          yAxisField?: string;
        };
      };
    };
  };
}
