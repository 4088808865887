import { Router, UrlTree } from '@angular/router';
import { UserProfile } from '@lbmx/types';
import { Observable, of } from 'rxjs';
import { routePermissions } from '../../features/secure/main-navigation-container/permissions';

export default function checkAuthorization(
  profile: UserProfile,
  config: { [key: string]: any }
): Observable<boolean | UrlTree> {
  return of(isAuthorized(profile.toolKeys, routePermissions, config.url));
}

const isAuthorized = (
  toolKeys: number[],
  permissionItems: { [key: string]: number },
  url: string
): boolean => {
  return toolKeys.length > 0
    ? Object.entries(permissionItems).reduce(
        (permissions, [name, toolKey]: [string, number]) => {
          return { ...permissions, [name]: toolKeys.includes(toolKey) };
        },
        {
          '/PlaygroundModule': true,
          '/home': true,
          '/default': true,
          '/terms': true,
        }
      )[`/${url.split('/')[1]}`]
    : false;
};
