import { Injectable } from '@angular/core';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';

@Injectable({
  providedIn: 'root',
})
/**
 * @todo
 * This implementation for the app error is just for demo
 * Next step is to decide how to best use this framework setup
 * Or maybe even use this implementaion and extend on this behaviour
 * @param loggerSrv: logger service (from lbmx/phoenix-lib-core)
 */
export class AppError {
  constructor(
    private loggerSrv?: LoggerService,
    private toastrSrv?: ToastrNotificationService
  ) {}

  /**
   *
   * @param error
   * accepts and error to log
   * @param errorMessage
   * takes in a message to log
   */
  public log(error: any, errorMessage: string) {
    this.loggerSrv.error(errorMessage, JSON.stringify(error));
  }

  /**
   *
   * @param errorMessage
   * accepts a message to display as a toast
   * notification
   */
  public showErrorMsg(errorMessage: string) {
    this.toastrSrv.errorNotify(errorMessage);
  }
  public showInfoMsg(errorMessage: string) {
    this.toastrSrv.infoNotify(errorMessage);
  }
}
