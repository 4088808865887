import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavBarService } from 'src/app/services/navbar/nav-bar.service';
import { ILBMXMegaMenu } from '../lbmx-mega-menu.interface';

@Component({
  selector: 'lbmx-mega-menu',
  templateUrl: './lbmx-mega-menu.component.html',
})
export class LBMXMegaMenuComponent {
  @Input() public model: ILBMXMegaMenu[];
  private isMobile = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navbarService: NavBarService
  ) {}

  @Input('isMobile') set mobileBooleanSetter(isMobile: boolean) {
    if (isMobile) {
      this.isMobile = isMobile;
    }
  }

  public handleActiveLink(megaMenu: any): string {
    const navigate = megaMenu?.command?.navigate;
    if (navigate === undefined) {
      return '';
    }
    const routerLink = navigate[0];
    const routerLinkActiveOptions = !!megaMenu?.urlExact;
    const snapshot: any = this.route.snapshot;
    const currentRoute = snapshot._routerState.url;

    const regexPattern = routerLinkActiveOptions
      ? `^${routerLink}$`
      : `^${routerLink}`;
    const routeRegex = new RegExp(regexPattern);

    return routeRegex.test(currentRoute) ? 'selected' : '';
  }

  public handleItemClick(event: Event, command: any) {
    this.navbarService.runCommand(command);
    this.stop(event);
  }

  public stop(event: Event) {
    event.stopPropagation();
  }

  // UGLY FUNCTION
  public getIconOrLabel(element: any, gettingLabel: boolean) {
    if (gettingLabel) {
      if (this.isMobile) {
        return element.label;
      } else if (element.icon) {
        return '';
      } else {
        return element.label;
      }
    } else {
      if (this.isMobile) {
        return '';
      } else {
        if (element.icon) {
          return element.icon;
        } else {
          return '';
        }
      }
    }
  }
}
