import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { PageEvent, PageSizeOptions } from './default-paginator.ui';

@Component({
  selector: 'lui-default-paginator',
  exportAs: 'luiDefaultPaginator',
  templateUrl: './default-paginator.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DefaultPaginatorComponent implements OnInit {
  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = Math.max(value, 0);
  }
  @Input()
  get length() {
    return this._length;
  }
  set length(n: number) {
    this._length = n;
    this.updatePageLinks();
  }

  @Input()
  get pageSize() {
    return this._pageSize;
  }

  set pageSize(n: number) {
    this._pageSize = n;
  }

  @Input()
  get pageSizeOptions() {
    return this._pageSizeOptions;
  }

  set pageSizeOptions(options: PageSizeOptions[]) {
    this._pageSizeOptions = options;
  }

  public pageLinks: number[] | undefined;
  private _length: number;
  private _pageSize: number;
  private _pageSizeOptions: PageSizeOptions[] = [
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '75',
      value: 75,
    },
    {
      label: '100',
      value: 100,
    },
  ];
  public initialized: Observable<void>;
  private _pageIndex = 0;

  @Output() public readonly page: EventEmitter<PageEvent> =
    new EventEmitter<PageEvent>();

  public ngOnInit(): void {
    this.initialized = new Observable<void>((s) => {
      s.next();
      s.complete();
    });
  }

  public nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    this._emitPageEvent(previousPageIndex);
  }

  public previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    this._emitPageEvent(previousPageIndex);
  }

  public firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this._emitPageEvent(previousPageIndex);
  }

  public lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages() - 1;
    this._emitPageEvent(previousPageIndex);
  }

  public hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  public hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  public _changePageSize(event: any) {
    this._pageSize = event;
    this._pageIndex = 0;
    if (this.hasNextPage()) {
      this._emitPageEvent();
    }
  }

  private _emitPageEvent(previousPageIndex?: number) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  public getNumberOfPages(): number {
    if (!this._pageSize || !this.length) {
      return 0;
    }
    return Math.ceil(this.length / this.pageSize);
  }

  public calculatePageLinkBoundaries() {
    const numberOfPages = this.getNumberOfPages();
    const visiblePages = Math.min(5, numberOfPages);
    // calculate range, keep current in middle if necessary
    let start = Math.max(0, Math.ceil(this._pageIndex - visiblePages / 2));
    const end = Math.min(numberOfPages - 1, start + visiblePages - 1);
    // check when approaching to last page
    const delta = 5 - (end - start + 1);
    start = Math.max(0, start - delta);

    return [start, end];
  }

  public updatePageLinks() {
    this.pageLinks = [];
    const boundaries = this.calculatePageLinkBoundaries();
    const start = boundaries[0];
    const end = boundaries[1];
    if (end === -1) {
      this.pageLinks.push(1);
    } else {
      for (let i = start; i <= end; i++) {
        this.pageLinks.push(i + 1);
      }
    }
  }

  public onPageLinkClick(event: Event, page: number) {
    this.pageIndex = page;
    this._emitPageEvent();
    event.preventDefault();
  }
}
