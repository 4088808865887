import {
  CDK_TREE_NODE_OUTLET_NODE,
  CdkTreeNodeOutlet,
} from '@angular/cdk/tree';
import { Directive, Inject, Optional, ViewContainerRef } from '@angular/core';

/**
 * Outlet for nested CdkNode. Put `[matTreeNodeOutlet]` on a tag to place children dataNodes
 * inside the outlet.
 */
@Directive({
  selector: '[luiTreeNodeOutlet]',
  providers: [
    {
      provide: CdkTreeNodeOutlet,
      useExisting: LuiTreeNodeOutlet,
    },
  ],
})
// tslint:disable-next-line: directive-class-suffix
export class LuiTreeNodeOutlet implements CdkTreeNodeOutlet {
  constructor(
    public viewContainer: ViewContainerRef,
    @Inject(CDK_TREE_NODE_OUTLET_NODE) @Optional() public _node?: any
  ) {}
}
