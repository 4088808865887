import { Filter, Filters } from '@lbmx/analytics';
import { FieldsDefinition, FieldsSchema, Form } from '@lbmx/per-form';
import { add } from 'date-fns';

export const deconstructParams = (
  schema: FieldsDefinition<Form>,
  filters: Filters
): Form => {
  return Object.entries(filters || {}).reduce(
    (initialValues: Form, [key, filter]: [string, Filter]): Form => {
      return {
        ...initialValues,
        ...deconstructFilter(schema, key, filter),
      };
    },
    {}
  );
};

const deconstructFilter = (
  schema: FieldsDefinition<Form>,
  key: string,
  filter: Filter
): Form => {
  switch (schema[key].type) {
    case 'text':
      return fz(key, filter);
    case 'dateRange':
    case 'dateTimeRange':
      return dateRange(key, filter);
    case 'checkboxSelection':
    case 'multiSelect':
      return eqArray(key, filter);
    case 'dropdown':
      return eq(key, filter);
    default:
      return {};
  }
};

const fz = (key: string, filter: Filter): Form => {
  return filter?.fz ? (filter?.fz[0] ? { [key]: filter?.fz[0] } : {}) : {};
};

const dateRange = (key: string, filter: Filter): Form => {
  return filter?.gte || filter?.lt
    ? {
        [key]: [
          filter?.gte
            ? filter?.gte[0]
              ? new Date(filter.gte[0])
              : null
            : null,
          filter?.lt
            ? filter?.lt[0]
              ? add(new Date(filter.lt[0]), { days: -1 })
              : null
            : null,
        ],
      }
    : {};
};

const eqArray = (key: string, filter: Filter): Form => {
  return filter?.eq ? { [key]: filter.eq } : {};
};

const eq = (key: string, filter: Filter): Form => {
  return filter?.eq ? (filter.eq[0] ? { [key]: filter.eq[0] } : {}) : {};
};
