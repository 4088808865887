import { CdkTree, CdkTreeNode, CdkTreeNodeToggle } from '@angular/cdk/tree';
import { Directive, Input } from '@angular/core';

/**
 * Wrapper for the CdkTree's toggle with Material design styles.
 */
@Directive({
  selector: '[luiTreeNodeToggle]',
  providers: [{ provide: CdkTreeNodeToggle, useExisting: LuiTreeNodeToggle }],
})
// tslint:disable-next-line: directive-class-suffix
export class LuiTreeNodeToggle<T> extends CdkTreeNodeToggle<T> {
  @Input('matTreeNodeToggleRecursive') public set recursive(value: boolean) {
    super.recursive = value;
  }
  constructor(tree: CdkTree<T>, node: CdkTreeNode<T>) {
    super(tree, node);
  }
}
