import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavItem } from '@lbmx/types';
import { MegaMenuItem } from 'primeng';
import { BehaviorSubject } from 'rxjs';
interface INavItemWithId extends NavItem {
  id?: string;
}
@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
})
export class MainNavBarComponent implements OnInit {
  @Input('navItems') public set navItemSetter(navigationItems: NavItem[]) {
    const navItems = this.transform(navigationItems);
    this.navItemsBehaviorSubject.next(navItems);
  }

  public navItemsBehaviorSubject = new BehaviorSubject<MegaMenuItem[]>([]);
  public navItemsObservable = this.navItemsBehaviorSubject.asObservable();
  public isSticky = false;
  public stickyThreshold: number;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  public onScroll(event) {
    this.handleSticky();
  }

  public ngOnInit() {
    const navbar: any = document.getElementsByTagName('lbmx-nav-bar')[0];
    this.stickyThreshold = navbar.offsetTop;
  }

  private transform(navigationItems: INavItemWithId[]): MegaMenuItem[] {
    return navigationItems.map((item: INavItemWithId) => {
      const items = item.items || [];
      return {
        ...item,
        items: items.map((i) => [i]),
      };
    });
  }

  private handleSticky(): void {
    this.isSticky = window.scrollY > this.stickyThreshold;
  }
}
