import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectors } from '@lbmx/app-state';
import { select, Store } from '@ngrx/store';

import { AppSetting } from '@lbmx/types';
import { Subscription } from 'rxjs';
import { ConfigProvider } from '../../../provider/config-provider';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  //#region Properties
  public appSettings: AppSetting = this.configProvider.AppSetting;
  public userName = '';
  public companyName = '';
  public subscriptions: Subscription[] = [];
  public alt = '';
  public imageSource = './assets/images/LBMX-logo-colour.png';

  //#endregion

  constructor(private configProvider: ConfigProvider, private store: Store) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.store.pipe(select(selectors.userProfile)).subscribe((res) => {
        this.userName = res.userInfo.FullName;
        this.companyName = res.userInfo.CompanyName;
      })
    );
    this.imageSource = `${this.appSettings?.uriConfiguration?.configurationRootURL}/logo`;
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
}
