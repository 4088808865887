import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
} from '@angular/core';
import { LUI_ACCORDION_ITEM, LuiAccordionItem } from './item';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lui-accordion-item-control, [luiAccordionItemControl]',
  exportAs: 'luiAccordionItemControl',
})
// tslint:disable-next-line: directive-class-suffix
export class LuiAccordionItemControl {
  public clicked = new EventEmitter();

  constructor(
    @Inject(LUI_ACCORDION_ITEM)
    public accordionItem: LuiAccordionItem
  ) {}

  @HostBinding('attr.aria-controls')
  public readonly controlId = `lui-item-panel-${this.accordionItem.index}`;

  @HostListener('click')
  public click() {
    this.accordionItem.toggle();
  }

  @HostBinding('class')
  get nameSpace() {
    return 'lui-accordion-item-control';
  }
}
