// import {FlatTreeControl} from '@angular/cdk/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ViewEncapsulation } from '@angular/core';
import { openClose } from '../accordion/animations';
import {
  LuiTreeFlatDataSource,
  LuiTreeFlattener,
} from '../tree/data-source/flat-data-source';
import { FlatNode, NestedNode, TREE_DATA } from './tree.ui';

/**
 * @title Tree with flat nodes
 */
@Component({
  selector: 'app-tree',
  templateUrl: 'tree.component.html',
  styleUrls: ['tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [openClose],
})
export class TreeComponent {
  public treeValue: string[] = [];

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  public _transformer = (node: NestedNode, level: number): FlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      value: node.value,
      isExpanded: false,
      isSelected: false,
    };
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  public treeFlattener = new LuiTreeFlattener<NestedNode, FlatNode>(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // tslint:disable-next-line: member-ordering
  public dataSource = new LuiTreeFlatDataSource<NestedNode, FlatNode>(
    this.treeControl,
    this.treeFlattener
  );

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  public hasChild = (_: number, node: FlatNode) => node.expandable;

  public shouldRender(node: FlatNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  public getParentNode(node: FlatNode) {
    const nodeIndex = this.dataSource.flattenedData.indexOf(node);
    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this.dataSource.flattenedData[i].level === node.level - 1) {
        return this.dataSource.flattenedData[i];
      }
    }
    return null;
  }

  public unselectParents(node: FlatNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      parent.isSelected = false;
      parent = this.getParentNode(parent);
    }
  }

  public unselectDescendent(node: FlatNode) {
    const dependents = this.treeControl.getDescendants(node);
    dependents.forEach((dependent) => (dependent.isSelected = false));
  }

  public selectDescendent(node: FlatNode) {
    const dependents = this.treeControl.getDescendants(node);
    dependents.forEach((dependent) => (dependent.isSelected = true));
  }

  public shouldParentBeSelected(node: FlatNode) {
    const parent = this.getParentNode(node);
    if (parent) {
      const dependents = this.treeControl.getDescendants(parent);
      let should = true;
      dependents.forEach((dependent) => {
        if (!dependent.isSelected) {
          should = false;
        }
      });
      parent.isSelected = should;
      // tslint:disable-next-line:no-unused-expression
      should && this.shouldParentBeSelected(parent);
    }
  }

  public parentNode(node: FlatNode) {
    node.isSelected = !node.isSelected;
    // tslint:disable-next-line:no-unused-expression
    !node.isSelected && this.unselectParents(node);
    // tslint:disable-next-line:no-unused-expression
    !node.isSelected && this.unselectDescendent(node);
    // tslint:disable-next-line:no-unused-expression
    node.isSelected && this.selectDescendent(node);
    // tslint:disable-next-line:no-unused-expression
    node.isSelected && this.shouldParentBeSelected(node);
    this.updateValue();
  }

  public childNode(node: FlatNode) {
    node.isSelected = !node.isSelected;
    // tslint:disable-next-line:no-unused-expression
    !node.isSelected && this.unselectParents(node);
    // tslint:disable-next-line:no-unused-expression
    node.isSelected && this.shouldParentBeSelected(node);
    this.updateValue();
  }

  public updateValue() {
    this.treeValue = this.dataSource.flattenedData.reduce(
      (values: any[], node: FlatNode) =>
        node.isSelected ? [...values, node.value] : [...values],
      []
    );
  }
}
