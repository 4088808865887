import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import {
  FieldsDefinition,
  Form,
  GenerateFormService,
  PerForm,
} from '@lbmx/per-form';
import { SearchService } from '@lbmx/root-services';

import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { formDefinition } from './simple-search.ui';

@Component({
  selector: 'lui-simple-search',
  templateUrl: './simple-search.html',
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable-next-line: component-class-suffix
export class LuiSimpleSearch implements OnInit, OnDestroy {
  private _shouldDestroy = new Subject();
  private queryParams = this.activatedRoute.queryParams.pipe(
    map((params) => params?.term || '')
  );
  public fields: FieldsDefinition<Form> = {
    term: {
      type: 'text',
      label: '',
      defaultValue: '',
      componentProperties: {
        placeholder: 'Search',
      },
      required: false,
    },
  };
  public form: PerForm;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formGenerator: GenerateFormService,
    private searchService: SearchService
  ) {}

  public ngOnInit(): void {
    this.queryParams
      .pipe(
        tap((term) => {
          this.form = this.formGenerator.init(formDefinition(this.fields), {
            term,
          });
        }),
        takeUntil(this._shouldDestroy)
      )
      .subscribe();
  }

  public handleClear() {
    this.form.patch(this.form.defaultValues);
  }

  public handleSearch() {
    // todo
  }

  public ngOnDestroy(): void {
    this._shouldDestroy.next(true);
  }
}
