import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule, DialogModule, MenuModule } from 'primeng-lts';
import { LuiToolbar } from './toolbar';

@NgModule({
  declarations: [LuiToolbar],
  imports: [CommonModule, DialogModule, MenuModule, ButtonModule, TranslocoModule],
  exports: [LuiToolbar],
})
export class ToolbarModule {}
