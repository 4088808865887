export interface NestedNode {
  name: string;
  value: any;
  children?: NestedNode[];
}

export interface FlatNode {
  name: string;
  value: any;
  expandable: boolean;
  level: number;
  isExpanded: boolean;
  isSelected: boolean;
}

export const TREE_DATA: NestedNode[] = [
  {
    name: 'Fruit',
    value: 'fruit',
    children: [
      {
        name: 'Apple',
        value: 'apple',
      },
    ],
  },
  {
    name: 'Vegetables',
    value: 'vegetables',
    children: [
      {
        name: 'Green',
        value: 'green',
        children: [
          {
            name: 'Broccoli',
            value: 'broccoli',
          },
          {
            name: 'Brussels Sprouts',
            value: 'brusselsSprouts',
          },
        ],
      },
      {
        name: 'Yellow',
        value: 'yellow',
        children: [
          {
            name: 'Yellow Beans',
            value: 'yellowbeans',
          },
          {
            name: 'Squash',
            value: 'squash',
          },
        ],
      },
    ],
  },
];

export const transformFunction = (
  node: NestedNode,
  level: number
): FlatNode => {
  const { children, ...rest } = node;
  return {
    ...rest,
    level,
    expandable: children && children.length > 0,
    isExpanded: false,
    isSelected: false,
  };
};

export const getLevel = ({ level }: FlatNode): number => level;

export const isExpandable = ({ expandable }: FlatNode): boolean => expandable;

export const getChildren = ({ children }: NestedNode): NestedNode[] => children;
