import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { BaseHttpService } from '@lbmx/root-services';
import { SearchContainerLoaderService } from '@lbmx/search-container';
import {
  Request as BuilderRequest,
} from '@lbmx/web-core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng-lts';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { apiUrls } from 'src/app/features/automations-module/url-config';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-report-builder-landing',
  templateUrl: './report-builder-landing.component.html',
  styleUrls: ['./report-builder-landing.component.scss'],
  providers: [SearchContainerLoaderService, ConfirmationService],
})
export class ReportBuilderLandingComponent {
  constructor(
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private toast: ToastrNotificationService,
    private spinner: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private configPrv: ConfigProvider,
    private http: BaseHttpService,
    private searchContainerLoaderService: SearchContainerLoaderService
  ) {
    http.baseUrl = this.baseUrl;
  }

  public reportBuilderDetailsConfig: BuilderRequest;

  // search container loader states
  public selections: any[];
  public searchContainerName = 'analyticsReportSearch';

  public i18nSearchField = 'ACTIONS.SEARCH';
  public params: { [key: string]: any } = {};
  public toolbarActionsDispatcher = {
    new: (selection: any[]) => {
      this.selections = selection;
      this.router.navigate(['./../reportBuilder/reportCreation'], {
        relativeTo: this.route,
      });
    },

    delete: (selection: any[]) => {
      this.selections = selection;
      this.confirmationService.confirm({
        message: this.translocoService.translate(
          'REPORTBUILDER.DELETE_REPORT_MESSAGE'
        ),
        header: this.translocoService.translate(
          'REPORTBUILDER.DELETE_REPORT_HEADER'
        ),
        accept: () => {
          this.spinner.on();
          const selectedKeys = [...selection].map(
            ({ reportKey, ..._ }) => reportKey
          );

          this.http
            .fetch({
              endpoint: apiUrls.delete(),
              httpMethod: 'POST',
              body: {
                ReportBuilderKeys: selectedKeys,
              },
            })
            .pipe(
              tap(() => {
                this.toast.successNotify(
                  this.translocoService.translate('GROUP.SUCCESS_TOAST_MESSAGE')
                );

                this.searchContainerLoaderService.refreshSearch();
              }),
              catchError(() =>
                of(
                  this.toast.errorNotify(
                    this.translocoService.translate('MESSAGES.GENERIC_ERROR')
                  )
                )
              ),
              finalize(() => this.spinner.off())
            )
            .subscribe();
        },
        reject: () => {
          /* empty */
        },
      });
    },
  };
  public columnActions = {};

  public baseUrl = `${this.configPrv.AppSetting?.uriAnalytics?.queryEndpoint}`;
}
