import { CDK_TABLE_TEMPLATE, CdkTable } from '@angular/cdk/table';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ViewEncapsulation,
} from '@angular/core';

@Directive({
  selector: 'lui-table[recycleRows], table[luiTable][recycleRows]',
  providers: [],
})
// tslint:disable-next-line:directive-class-suffix
export class LuiRecycleRows {}

@Component({
  selector: 'lui-table, table[luiTable]',
  exportAs: 'luiTable',
  template: CDK_TABLE_TEMPLATE,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class:
      'lui-table ldc-data-table__table | leading-normal border-separate border-spacing-0',
    '[class.min-w-full]': '!fixedLayout',
    '[class.ldc-table-fixed-layout]': 'fixedLayout',
  },
  providers: [
    { provide: CdkTable, useExisting: LuiTable },
    // { provide: CDK_TABLE, useExisting: LuiTable },

    // Prevent nested tables from seeing this table's StickyPositioningListener.
  ],
  encapsulation: ViewEncapsulation.None,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  // tslint:disable-next-line:validate-decorators
  changeDetection: ChangeDetectionStrategy.Default,
})
// tslint:disable-next-line:component-class-suffix
export class LuiTable<T> extends CdkTable<T> {
  /** Overrides the sticky CSS class set by the `CdkTable`. */
  // override stickyCssClass = 'lui-mdc-table-sticky';
}
