import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { actions, selectors } from '@lbmx/app-state';
import { AppSetting, UserProfile } from '@lbmx/types';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  templateUrl: './terms-services.component.html',
  styleUrls: [],
})
export class TermsServicesComponent implements OnInit, OnDestroy {
  private navigateToUrl: string;
  public user: UserProfile;
  private appSettings: AppSetting = this.configPrv.AppSetting;
  public lbmxLogoImage = './assets/images/lbmx_logo.png';
  public subscriptions: Subscription[] = [];
  public shouldRedirect = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private configPrv: ConfigProvider
  ) {}

  public ngOnInit() {
    this.navigateToUrl =
      this.route.snapshot.queryParams['nextUrl'] || '/default';
    this.subscriptions.push(
      this.store
        .select(selectors.userProfile)
        .pipe(
          tap((profile) => {
            this.user = profile;
            if (this.shouldRedirect && this.user.userInfo.IsTermsSigned) {
              this.router.navigate([this.navigateToUrl]);
            }
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  public onAccept(isAccepted: boolean) {
    if (isAccepted) {
      this.shouldRedirect = true;
      this._updateUserProfile();
      this.router.navigate([this.navigateToUrl]);
    } else {
      this.router.navigate(['logout']);
    }
  }

  public onClose() {
    this.router.navigate([this.navigateToUrl]);
  }

  private _updateUserProfile() {
    this.store.dispatch(
      actions.agreeToTerms({
        endPoints: this.appSettings,
      })
    );
  }
}
