import { Component } from '@angular/core';
import { Action, ActionDispatcher, ActionUiService } from '@lbmx/action-ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { takeUntil, tap } from 'rxjs/operators';
import {
  defaultState,
  KpiSelectionModalUiActions,
  KpiSelectionModalUiState,
  schema,
} from './kpi-selection-modal.ui';

@Component({
  selector: 'app-kpi-selection-modal',
  templateUrl: './kpi-selection-modal.component.html',
  providers: [ActionUiService],
})
export class KpiSelectionModalComponent {
  public state: KpiSelectionModalUiState;
  public actions: ActionDispatcher<
    KpiSelectionModalUiActions,
    KpiSelectionModalUiState
  > = Action.createDispatcher(schema);

  constructor(
    public actionUi: ActionUiService<KpiSelectionModalUiState>,
    public ref: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.actionUi.initialize(defaultState, this.actions);

    this.actionUi.state
      .pipe(
        tap((state) => (this.state = { ...state })),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();

    this.actions.setShowCategories.dispatch(
      this.dialogConfig.data.showCategories
    );

    if (this.dialogConfig.data.categoryFilter) {
      this.actions.setCategoryFilter.dispatch(
        this.dialogConfig.data.categoryFilter
      );
    }

    this.actions.setAnalyticsState.dispatch(
      this.dialogConfig.data.analyticsState
    );

    if (!this.state.showCategories) {
      this.actions.setKpiDropdown.dispatch();
    }
  }

  public closeDialog(save = true) {
    const data = save ? this.state.selectedKpi : null;
    this.ref.close(data);
  }
}
