import { Component, Input } from '@angular/core';
import { ActiveTabService } from './active-tab-service';
import { ROLES } from './roles';

@Component({
  selector: 'lui-tab-header',
  templateUrl: './tab-header.html',
})

// tslint:disable-next-line: component-class-suffix
export class LuiTabHeader {
  public roleList = ROLES.TABLIST;
  public roleButton = ROLES.TAB;
  @Input() public buttonsToDisplay: string[];
  constructor(public activeTabSrv: ActiveTabService) {}
}
