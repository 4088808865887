import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { selectors } from '@lbmx/app-state';
import { UserState } from '@lbmx/types';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DefaultRouteService implements CanActivate {
  constructor(private router: Router, private store: Store<UserState>) {}

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectors.userProfile),
      switchMap((profile) => {
        return of(
          !profile.defaultModule || this.router.parseUrl(profile.defaultModule)
        );
      }),
      catchError(() => of(this.router.parseUrl('/home')))
    );
  }
}
