import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '@lbmx/phoenix-lib-auth';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private configProvider: ConfigProvider,
    private authSrv: AuthenticateService
  ) {}

  public ngOnInit() {
    this.authSrv.logOut(this.configProvider.AppSetting);
  }
}
