import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerForm } from '@lbmx/per-form';

@Component({
  selector: 'lib-exclude-options',
  templateUrl: './exclude-options.component.html',
})
export class ExcludeOptionsComponent implements OnInit {
  @Input() public form: PerForm;
  @Output() public closeDialog = new EventEmitter();
  @Output() public saveDialog = new EventEmitter();
  constructor()
  {
    /* empty */
  }

  public ngOnInit(): void {
    /* empty */
  }

  public save() {
    this.saveDialog.emit(this.form.values);
  }
  public close() {
    this.closeDialog.emit();
  }
}
