import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[onMouseMove]',
})
export class OnMouseMoveDirective implements OnDestroy {
  public self = fromEvent(this.el.nativeElement, 'mousemove')
    .pipe(debounceTime(500))
    .subscribe(() => {
      this.handler();
    });

  @Input() public handler: () => void;

  constructor(private el: ElementRef) {
    /** empty block */
  }

  public ngOnDestroy(): void {
    if (this.self) {
      this.self.unsubscribe();
    }
  }
}
