import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionDispatcher, ActionUiService } from '@lbmx/action-ui';
import {
  AnalyticsStatus,
  ChartResponse,
  ColorType,
  Filters,
  Icons,
  KpiResponse,
  TemplateTypes,
} from '@lbmx/analytics';
import { GenerateFormService, PerForm } from '@lbmx/per-form';
import { DialogService } from 'primeng-lts';
import { forkJoin, of, Subject } from 'rxjs';
import {
  catchError,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { createFilters } from '../filter';
import { deconstructParams } from '../params';
import { WidgetSelectionComponent } from '../widget-selection/widget-selection.component';
import {
  AvailableWidget,
  ConfigResponse,
  DashboardActions,
  dashboardSchema,
  DashboardState,
  defaultDashboardState,
  WidgetTabConfig,
  WidgetTypes,
} from './dashboard-loader.ui';
import { mockConfig } from './mock-config';

@Component({
  selector: 'app-dashboard-loader',
  templateUrl: './dashboard-loader.component.html',
  styleUrls: ['./dashboard-loader.component.scss'],
  providers: [ActionUiService, DialogService],
})
export class DashboardLoaderComponent implements OnInit {
  @Input() public configEndpoint = '';
  @Input() public chartAdaptor: (
    response: any,
    config?: any,
    type?: string
  ) => ChartResponse;
  @Input() public kpiAdaptor: (
    response: any,
    config?: any,
    type?: string
  ) => KpiResponse;
  @Input() public chartNoDataTest: (
    response: any,
    config?: any,
    type?: string
  ) => boolean;
  @Input() public paramListener = true;

  public header = '';
  public fetch = new Subject();
  public templateTypes = TemplateTypes;
  public widgetTypes = WidgetTypes;
  public actions: ActionDispatcher<DashboardActions, DashboardState> =
    Action.createDispatcher(dashboardSchema);
  public state: DashboardState;
  public form: PerForm;
  public dashboardFilters: Filters = {};
  public icons: Icons = [
    { icon: 'pi pi-refresh', callback: (controls) => controls.refresh() },
    {
      icon: 'pi pi-ellipsis-v',
      callback: (controls) => this.setChart(controls.index),
    },
  ];
  public dashConfig: WidgetTabConfig = {};

  constructor(
    public actionUi: ActionUiService<DashboardState>,
    private formGenerator: GenerateFormService,
    public dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private configPrv: ConfigProvider
  ) {
    this.actionUi.initialize(defaultDashboardState, this.actions);
    this.actionUi.state
      .pipe(
        tap((state) => (this.state = { ...state })),
        tap(
          ({ available }) =>
            (this.dashConfig = Object.entries(available).reduce(
              (
                widgets: WidgetTabConfig,
                [key, availableWidget]: [string, AvailableWidget]
              ): WidgetTabConfig => {
                const type = key.toLowerCase().includes(WidgetTypes.KPI)
                  ? WidgetTypes.KPI
                  : WidgetTypes.CHART;
                switch (type) {
                  case WidgetTypes.KPI:
                    return {
                      ...widgets,
                      [key]: {
                        description: availableWidget?.label || '',
                        type,
                        widgets: {
                          type: availableWidget.type,
                          title: availableWidget?.label,
                          subTitle: availableWidget?.subLabel || '',
                          query: availableWidget.defaultQuery || {},
                          endpoint:
                            this.configPrv.AppSetting.uriAnalytics
                              .queryEndpoint + availableWidget.endpoint,
                          kpiConfig: { ...availableWidget },
                          redirect: availableWidget?.redirect ?? null,
                        },
                      },
                    };
                  case WidgetTypes.CHART:
                    return {
                      ...widgets,
                      [key]: {
                        description: availableWidget?.label || '',
                        type,
                        widgets: {
                          [key]: {
                            label: availableWidget?.label || '',
                            query: availableWidget?.defaultQuery || {},
                            endpoint:
                              this.configPrv.AppSetting.uriAnalytics
                                .queryEndpoint + availableWidget.endpoint,
                            type:
                              availableWidget.type === 'line'
                                ? 'projected'
                                : availableWidget.type,
                            colorType:
                              availableWidget.colorType || ColorType.SERIES,
                            options: availableWidget?.options || {},
                            config: { ...availableWidget },
                            columns: availableWidget?.columns,
                            redirect: availableWidget?.redirect ?? null,
                          },
                        },
                      },
                    };
                }
              },
              {}
            ))
        ),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();
    this.fetch
      .pipe(
        tap(() => this.actions.setStatus.dispatch(AnalyticsStatus.LOADING)),
        switchMap(() =>
          forkJoin([
            this.http
              .get<ConfigResponse>(this.configEndpoint, {
                withCredentials: true,
              })
              .pipe(
                // map(() => mockConfig),
                map(({ record }) => record)
              ),
            this.route.queryParams.pipe(first()),
          ]).pipe(
            catchError((err) => {
              this.actions.setStatus.dispatch(AnalyticsStatus.ERROR);
              this.router.navigate(['/home']);
              return of(err);
            })
          )
        ),
        tap(([{ label, filters, widgets }, params]) => {
          this.header = label;
          this.form = this.formGenerator.init(
            {
              fields: filters,
              layout:
                window.innerWidth > 592
                  ? {
                      'grid-template-columns': '1fr 1fr 1fr 1fr',
                      'column-gap': '1.5em',
                    }
                  : {},
            },
            deconstructParams(
              filters,
              this.paramListener ?
              params?.filters ? JSON.parse(params?.filters) : {} : {}
            )
          );
          this.actions.updateState.dispatch({
            gridArea: widgets?.grid?.layout || [],
            types: widgets?.grid?.types || [],
            configured: widgets?.configured || [],
            available: widgets?.available || {},
            smallGridArea: {
              breakPoint: 592,
              gridArea: widgets?.configured.reduce(
                (grid: number[][], _: string, index: number): number[][] => [
                  ...grid,
                  [index],
                ],
                []
              ),
            },
          });
          this.form.values$
            .pipe(
              map(
                (dashboardFilters) =>
                  (this.dashboardFilters = createFilters(
                    this.form.schema,
                    dashboardFilters
                  ))
              ),
              tap((formFilters) =>
                this.router.navigate(['./'], {
                  relativeTo: this.route,
                  queryParams:
                    Object.keys(formFilters).length !== 0
                      ? { filters: JSON.stringify(formFilters) }
                      : {},
                })
              ),
              takeUntil(this.actionUi.shouldDestroy)
            )
            .subscribe();
        }),
        tap(() => this.actions.finalize.dispatch()),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.fetch.next();
  }

  public setChart(index: number) {
    const type = this.state.types[index];
    const ref = this.dialogService.open(WidgetSelectionComponent, {
      data: {
        state: { ...this.dashConfig },
        type,
      },
      showHeader: false,
      styleClass: 'kpi-dialog',
    });
    ref.onClose
      .pipe(
        tap((name) => name && this.actions.setWidget.dispatch({ index, name })),
        tap(
          (name) =>
            name &&
            this.http
              .post(
                this.configEndpoint,
                {
                  widgets: [...this.state.configured],
                },
                { withCredentials: true }
              )
              .pipe(takeUntil(this.actionUi.shouldDestroy))
              .subscribe()
        ),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();
  }
}
