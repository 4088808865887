import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Directive({
  selector: '[resizable]',
})
export class ResizableDirective {
  @Output()
  public readonly resizable = fromEvent<MouseEvent>(
    this.elementRef.nativeElement,
    'mousedown'
  ).pipe(
    tap((e) => {
      e.preventDefault();
      this.elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement.classList.add(
        'resizing'
      );
    }),
    switchMap(() => {
      const { right, width } = this.elementRef.nativeElement
        .closest('.cell')
        .getBoundingClientRect();
      return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
        map(({ clientX }) => width + clientX - right
        ),
        distinctUntilChanged(),
        takeUntil(fromEvent(this.documentRef, 'mouseup'))
      );
    })
  );
  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef<HTMLElement>
  ) {
    fromEvent(this.documentRef, 'mouseup')
      .pipe(
        tap(() => {
          this.elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement.classList.remove(
            'resizing'
          );
        })
      )
      .subscribe();
  }
}
