import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsServicesComponent } from './features/secure/terms-services/terms-services.component';

// Public
import { PublicComponent } from './layouts/public/public.component';
import { PUBLIC_ROUTES } from './layouts/public/public.routes';

// Secure
import { SecureComponent } from './layouts/secure/secure.component';
import { SECURE_ROUTES } from './layouts/secure/secure.routes';

import { AuthGuardService } from './services/authGuard/authGuard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'default',
    pathMatch: 'full',
  },
  {
    path: '',
    component: PublicComponent,
    data: { title: 'Public Views' },
    children: PUBLIC_ROUTES,
  },
  {
    path: '',
    component: SecureComponent,
    data: { title: 'Secure Views' },
    children: SECURE_ROUTES,
    canActivateChild: [AuthGuardService],
  },
  {
    path: 'terms',
    component: TermsServicesComponent,
    canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      useHash: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
