import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import {
  IResetLinkValidationResponse,
  PasswordRecoveryRequest,
  ResetPasswordRequest,
  ResetToken,
} from 'src/app/dtos/forgotPassword/forgotPassword.dto';
import { ConfigProvider } from 'src/app/provider/config-provider';

import { BaseAPIService, LoggerService } from '@lbmx/phoenix-lib-core';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService implements OnDestroy {
  public subscriptions: Subscription[] = [];

  constructor(
    private configPrv: ConfigProvider,
    private baseAPISrv: BaseAPIService,
    private loggerSrv: LoggerService
  ) {}

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  //#region Methods
  public getPasswordRecovery(user: string) {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.recoverPassword);

    const request: PasswordRecoveryRequest = {
      username: user,
    };

    this.subscriptions.push(
      this.baseAPISrv
        .post<string>(request)
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            this.loggerSrv.info(
              'Sent password reset link',
              'PasswordResetService.getPasswordRecovery',
              response
            );
          },
          (err: any) => {
            this.loggerSrv.error(
              'Error sending password reset link',
              'PasswordResetService.getPasswordRecovery',
              err
            );
          }
        )
    );
  }

  public validatePasswordResetLink(
    token: ResetToken
  ): Observable<IResetLinkValidationResponse> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.validateResetPasswordLink);
    return this.baseAPISrv.get<IResetLinkValidationResponse>({
      params: { fromObject: { token: token.token } },
    });
  }

  public resetPassword(
    resetRequest: ResetPasswordRequest
  ): Observable<boolean> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.resetPassword);
    const didSucceed = new Subject<boolean>();
    this.subscriptions.push(
      this.baseAPISrv
        .post<string>(resetRequest, { responseType: 'text' })
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            this.loggerSrv.info(
              'Updated password',
              'PasswordResetService.resetPassword',
              response
            );
            didSucceed.next(true);
          },
          (err: any) => {
            this.loggerSrv.error(
              'Failed to update password',
              'PasswordResetService.resetPassword',
              err
            );
            didSucceed.next(false);
          }
        )
    );
    return didSucceed.asObservable();
  }
  //#endregion
}
