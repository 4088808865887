import { ActionCreator, DispatchSchema } from '@lbmx/action-ui';
import { LoadingState } from '@lbmx/root-services';

export interface LSCState {
  showFilterButton: boolean;
  filtersApplied: boolean;
  showSimpleSearch: boolean;
  showToolbar: boolean;
  showResults: boolean;
  showResultsArea: boolean;
  showAdvancedSearch: boolean;
  status: LoadingState;
  toolbarStyles: object;
}

export interface LSCActions {
  toggleShowSimpleSearch: ActionCreator<LSCState>;
  toggleAdvancedSearch: ActionCreator<LSCState>;
  setFiltersApplied: ActionCreator<LSCState>;
}

export const lscSchema: DispatchSchema<LSCActions, LSCState> = {
  toggleShowSimpleSearch: {
    command: (_, state: LSCState) => {
      return { showSimpleSearch: !state.showSimpleSearch };
    },
  },
  toggleAdvancedSearch: {
    command: (_, state: LSCState) => {
      const _showAdvancedSearch = !state.showAdvancedSearch;
      const toolbarStyles = _showAdvancedSearch
        ? {
            toolbarStyles: {
              ...defaultLSCState.toolbarStyles,
              'padding-left': '1em',
            },
          }
        : { toolbarStyles: defaultLSCState.toolbarStyles };

      return { showAdvancedSearch: _showAdvancedSearch, ...toolbarStyles };
    },
  },
  setFiltersApplied: {
    command: (filtersApplied, _: LSCState) => {
      return { filtersApplied };
    },
  },
};

export const defaultLSCState: LSCState = {
  showFilterButton: true,
  filtersApplied: false,
  showSimpleSearch: true,
  showResults: true,
  showResultsArea: true,
  showToolbar: true,
  showAdvancedSearch: false,
  status: LoadingState.READY,
  toolbarStyles: {
    width: '100%',
  },
};
