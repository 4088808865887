import { FormControlRole, FormDefinition } from '@lbmx/per-form';
import { TranslocoService } from '@ngneat/transloco/public-api';
import { WidgetTypes } from '../dashboard-loader/dashboard-loader.ui';

export interface DropdownOptions {
  value: string;
  label: string;
}

export interface SelectionForm {
  selectWidget: string;
}

export const formSchema = (
  options: DropdownOptions[],
  type: WidgetTypes,
  close: () => void,
  transloco: TranslocoService
): FormDefinition<SelectionForm> => ({
  layout: {
    'row-gap': '1em',
  },
  controls: {
    cancel: {
      label: transloco.translate('ACTIONS.CANCEL'),
      callback: close,
      role: FormControlRole.SECONDARY,
    },
    save: {
      label: transloco.translate('ACTIONS.SAVE'),
      disabled: (form) => {
        return !form?.values?.selectWidget;
      },
      componentProperties: {
        style: {
          'flex-grow': 1,
        },
      },
    },
  },
  fields: {
    selectWidget: {
      type: 'dropdown',
      label: '',
      defaultValue: null,
      options: [...options],
      componentProperties: {
        placeholder:
          type === WidgetTypes.KPI
            ? transloco.translate('ANALYTICS.SELECT_KPI')
            : transloco.translate('ANALYTICS.SELECT_CHART'),
        filter: true,
      },
    },
  },
});
