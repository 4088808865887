import { Component, OnInit } from '@angular/core';
import { selectors } from '@lbmx/app-state';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account/account.service';
import { IHomeView } from './home-view/home-view.component';

@Component({
  selector: 'app-home-view-container',
  template: `<app-home-view [view]="homeFeed"></app-home-view>`,
})
export class HomeViewContainerComponent implements OnInit {
  public homeFeed: IHomeView | void;
  public subscriptions: Subscription[] = [];

  constructor(
    private accountService: AccountService,
    private spinner: SpinnerService,
    private store: Store<any>,
    private transloco: TranslocoService,
    private toast: ToastrNotificationService
  ) {}

  public ngOnInit(): void {
    this.spinner.on();
    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.userFeature),
          first((user) => user.marketplace !== ''),
          tap(() => {
            this.subscriptions.push(
              this.accountService
                .getHome()
                .pipe(
                  tap((home: any) => {
                    this.homeFeed = this.setFeed(home);
                  }),
                  catchError(() => {
                    this.homeFeed = this.setFeed(null);
                    return of(
                      this.toast.errorNotify(
                        this.transloco.translate(
                          'SUPPLIER.FAILURE_TOAST_MESSAGE'
                        )
                      )
                    );
                  }),
                  finalize(() => this.spinner.off())
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  public setFeed(home: any): IHomeView {
    return {
      lbmxNews:
        home?.details.lbmxNews ||
        `${this.transloco.translate('HOME_VIEW.DEFAULT_LBMX_NEWS')}`,
      marketplaceNews:
        home?.details.marketplaceNews ||
        `<p>${this.transloco.translate(
          'HOME_VIEW.DEFAULT_MARKETPLACE_NEWS'
        )}</p>`,
      welcomeMessage:
        home?.details.welcomeMessage ||
        `<br/><p class="ql-align-center">${this.transloco.translate(
          'HOME_VIEW.WELCOME'
        )}</p><p class="ql-align-center">${this.transloco.translate(
          'HOME_VIEW.LBMX_MARKETPLACE'
        )}</p><p class="ql-align-center">${this.transloco.translate(
          'HOME_VIEW.YOUR_SECURE'
        )}</p><p class="ql-align-center">${this.transloco.translate(
          'HOME_VIEW.BUSINESS_PLATFORM'
        )}</p><p><br></p>`,
      lbmxSupport:
        home?.details.supportNews ||
        `<p>${this.transloco.translate(
          'HOME_VIEW.NEED_HELP'
        )}<a href="mailto:support@LBMX.com" rel="noopener noreferrer" target="_blank">${this.transloco.translate(
          'HOME_VIEW.SUPPORT_LBMX'
        )}</a></p>`,

      marketplaceImg:
        home?.details && home?.details.marketplaceImgKey
          ? this.accountService.getURL(
              home?.details.marketplaceImgKey,
              home?.details.accountKey
            )
          : './assets/images/Welcome-image02_2x.jpg',
      welcomeImg:
        home?.details &&
        home?.details.welcomeImgKey &&
        home?.details.welcomeImgKey !== 0
          ? this.accountService.getURL(
              home?.details.welcomeImgKey,
              home?.details.accountKey
            )
          : './assets/images/Welcome-image01_2x.jpg',
    };
  }
}
