import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';

import { PageEvent, PageSizeOptions } from './paginator.ui';
const DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'lui-paginator',
  exportAs: 'luiPaginator',
  templateUrl: './paginator.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LuiPaginatorComponent implements OnInit, AfterContentChecked {
  private _length: number;
  private _pageSize: number;
  private _pageSizeOptions: PageSizeOptions[] = [
    {
      label: 25,
      value: 25,
    },
    {
      label: 50,
      value: 50,
    },
    {
      label: 75,
      value: 75,
    },
    {
      label: 100,
      value: 100,
    },
  ];
  public initialized: Observable<void>;
  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = Math.max(value, 0);
  }
  private _pageIndex = 0;
  @Input()
  get length() {
    return this._length;
  }
  set length(n: number) {
    this._length = n;
  }

  @Input()
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(n: number) {
    this._pageSize = n;
    this._updateDisplayedPageSizeOptions();
  }

  @Input()
  get pageSizeOptions() {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(options: PageSizeOptions[]) {
    this._pageSizeOptions = options;
    this._updateDisplayedPageSizeOptions();
  }

  @Output() public readonly page: EventEmitter<PageEvent> =
    new EventEmitter<PageEvent>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._updateDisplayedPageSizeOptions();
    this.initialized = new Observable<void>((s) => {
      s.next();
      s.complete();
    });
  }
  public ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  public nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    this._emitPageEvent(previousPageIndex);
  }

  public previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    this._emitPageEvent(previousPageIndex);
  }

  public firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this._emitPageEvent(previousPageIndex);
  }

  public lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages() - 1;
    this._emitPageEvent(previousPageIndex);
  }

  public hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  public hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  public _changePageSize(event: any) {
    this._pageSize = event;
    this._pageIndex = 0;
    if (this.hasNextPage()) {
      this._emitPageEvent();
    }
  }
  private _emitPageEvent(previousPageIndex?: number) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  private _updateDisplayedPageSizeOptions() {
    if (!this._pageSize) {
      this._pageSize = DEFAULT_PAGE_SIZE;
    }
  }
  public getNumberOfPages(): number {
    if (!this._pageSize || !this.length) {
      return 0;
    }
    return Math.ceil(this.length / this.pageSize);
  }
  public getRangeLabel: (
    page: number,
    pageSize: number,
    length: number
  ) => string = (page: number, pageSize: number, length: number) => {
    if (
      length === 0 ||
      pageSize === 0 ||
      length === undefined ||
      pageSize === undefined
    ) {
      return `0 of 0`;
    }
    length = Math.max(length, 0);
    const startIndex =
      this.pageIndex * this.pageSize < length
        ? this.pageIndex * this.pageSize
        : length - this.pageSize;

    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    if (this.pageIndex > 0) {
      const lastPageIndex = Math.ceil(length / pageSize) - 1 || 0;
      const newPageIndex = Math.min(page, lastPageIndex);

      if (newPageIndex !== page) {
        this.pageIndex = newPageIndex;
        this._emitPageEvent();
      }
    }

    return `${startIndex + 1} - ${endIndex} of ${length}`;
  }
}
