import { Router, UrlTree } from '@angular/router';
import { UserProfile } from '@lbmx/types';
import { Observable, of } from 'rxjs';

export default function checkTerms(
  profile: UserProfile,
  config: { [key: string]: any }
): Observable<boolean | UrlTree> {
  const { userInfo } = profile;
  return userInfo.IsTermsSigned === 1
    ? of(true)
    : of(config.router.parseUrl(config.redirectUrl));
}
