import { Component, OnInit } from '@angular/core';
import { GenerateFormService, PerForm } from '@lbmx/per-form';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts';
import {
  WidgetTabConfig,
  WidgetTypes,
} from '../dashboard-loader/dashboard-loader.ui';
import {
  DropdownOptions,
  formSchema,
  SelectionForm,
} from './widget-selection.ui';

@Component({
  selector: 'app-widget-selection',
  templateUrl: './widget-selection.component.html',
  styleUrls: ['./widget-selection.component.scss'],
})
export class WidgetSelectionComponent implements OnInit {
  public dashState: WidgetTabConfig;
  public form: PerForm;
  public type: WidgetTypes;
  public widgetTypes = WidgetTypes;
  constructor(
    private formGenerator: GenerateFormService,
    public ref: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private translocoService: TranslocoService
  ) {
    this.dashState = this.dialogConfig.data.state;
    this.type = this.dialogConfig.data.type;
  }

  public ngOnInit(): void {
    this.form = this.formGenerator.init(
      formSchema(
        this.buildOptions(this.dashState),
        this.type,
        this.close,
        this.translocoService
      )
    );
  }

  public buildOptions(state: WidgetTabConfig): DropdownOptions[] {
    return Object.entries(state).reduce(
      (options, [key, value]): DropdownOptions[] => {
        return value.type === this.type
          ? [
              ...options,
              {
                label: value?.widgets?.subTitle
                  ? `${value?.description || ''} | ${value?.widgets?.subTitle}`
                  : value?.description || '',
                value: key,
              },
            ]
          : [...options];
      },
      []
    );
  }

  public submit(values: SelectionForm) {
    this.ref.close(values.selectWidget);
  }

  public close = () => this.ref.close();
}
