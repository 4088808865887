import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng';
import { LuiTreeModule } from '../tree/tree.module';
import { TreeComponent } from './tree.component';

@NgModule({
  declarations: [TreeComponent],
  imports: [CommonModule, FormsModule, LuiTreeModule, CheckboxModule],
  exports: [TreeComponent],
})
export class TreeAppModule {}
