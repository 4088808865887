import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const openClose = [
  trigger('openClose', [
    state('open', style({ height: '*' })),
    state(
      'closed',
      style({
        height: '0',
        overflow: 'hidden',
      })
    ),
    state('rotated', style({ transform: 'rotate(90deg)' })),
    state('default', style({ transform: 'rotate(0deg)' })),
    transition('open <=> closed', [
      animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
    ]),
    transition('rotated <=> default', [animate('0.25s')]),
  ]),
];
