import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { ColumnConfig, SearchService, Sort } from '@lbmx/root-services';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';

import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { DefaultPaginatorComponent } from '../default-paginator/default-paginator.component';

export type StateStorage = 'session' | 'local';
export interface ColumnWidthState {
  [key: string]: number;
}

export type ColumnToDisplayState = string[];

export interface LuiTableDataSourcePageEvent {
  pageIndex: number;
  pageSize: number;
  length: number;
}

export interface SessionStorageState {
  offset: number;
  selected: string[];
}

export interface LocalStorageState {
  columnWidths: ColumnWidthState;
  pageSize: number;
  sort: Sort;
  columnsToDisplay: string[];
}

export class TableDataSource<T> extends DataSource<T> {
  public set data(data: T[]) {
    this._data$.next(data);
  }

  public get data(): T[] {
    return this._data$.value;
  }

  get columnWidth() {
    return this._columnWidth$.value;
  }

  get columnsToDisplay() {
    return this._columnToDisplay$.value;
  }

  get selection() {
    return this._selection;
  }

  set paginator(paginator: DefaultPaginatorComponent | null) {
    this._paginator = paginator;
    this._setupPaginator();
  }

  set sort(sort: Sort) {
    this._sort$.next(sort);
  }

  get sort(): Sort {
    return this._sort$.value;
  }

  get isLegacy(): boolean {
    return this._isLegacy;
  }

  set isLegacy(toggle: boolean) {
    this._isLegacy = toggle;
  }

  get totalRecords(): number {
    return this._totalRecords;
  }

  set totalRecords(tot: number) {
    this._totalRecords = tot;
  }
  public set searchService(searchService: SearchService) {
    this._searchService = searchService;
    this._setupService();
  }

  constructor() {
    super();
    if (this._localData) {
      this._columnWidth$.next(this._localData.columnWidths);
      this._updateStateStorage('local', {
        columnWidths: this._localData.columnWidths,
      });
    }
    this.searchService?.totalRecords$.pipe(
      tap((records) => {
        this._totalRecords = records;
      })
    ).subscribe();
    this._setupSelection();
  }

  private gridKey = this.routerGridStateKey();
  private _isLegacy = sessionStorage.getItem(`${this.gridKey}isLegacy`)
    ? (JSON.parse(sessionStorage.getItem(`${this.gridKey}isLegacy`)) as boolean)
    : true;
  private _sessionData = JSON.parse(
    sessionStorage.getItem(`${this.gridKey}session`)
  );
  private _localData = JSON.parse(localStorage.getItem(`${this.gridKey}local`));
  private readonly _data$ = new BehaviorSubject<T[]>([]);
  private readonly _reset$ = new Subject();
  private _initialSelection = this._sessionData?.selected;
  private _selection = new SelectionModel<string>(true, this._initialSelection);
  private _columnToDisplay$ = new BehaviorSubject<ColumnToDisplayState>([]);
  private _sort$ = new BehaviorSubject<Sort>({});
  private _searchService: SearchService | null = null;
  private _searchSubscriptions: Subscription;
  private _paginatorSubscriptions: Subscription;
  private _totalRecords: number;
  private _columnWidth$ = new BehaviorSubject<ColumnWidthState>({});
  private _paginator: DefaultPaginatorComponent | null;
  private _sessionStorageConfigs: BehaviorSubject<SessionStorageState> =
    new BehaviorSubject<SessionStorageState>({
      offset: 0,
      selected: [],
    });

  private _localStorageConfigs = new BehaviorSubject<LocalStorageState>({
    columnWidths: {},
    pageSize: 0,
    sort: {},
    columnsToDisplay: [],
  });

  private routerGridStateKey() {
    return `${window.location.pathname.replace(/[/]/g, '_')}_`;
  }

  public updateColumnWidth(width: number, field: string) {
    this._columnWidth$.next({ ...this._columnWidth$.value, [field]: width });
    this._updateStateStorage('local', {
      columnWidths: { ...this._columnWidth$.value, [field]: width },
    });
  }

  public updateSort(sort: any, field: string) {
    const direction: 1 | -1 = sort?.direction === 'desc' ? -1 : 1;
    const newSort = { field, order: direction };

    if (
      this._sort$.value?.field === field &&
      this._sort$.value?.order === direction
    ) {
      this._sort$.next({});
    } else {
      this._sort$.next(newSort);
    }

    this._updateStateStorage('local', { sort: this._sort$.value });
  }

  public updateColumnToDisplay(columns: string[]) {
    this._columnToDisplay$.next(columns);
    this._updateStateStorage('local', {
      columnsToDisplay: columns.filter((a) => a !== 'select' && a !== 'fill'),
    });
  }

  public connect(): Observable<T[] | readonly T[]> {
    return this._data$;
  }

  public disconnect(): void {
    this._reset$.next(true);
  }

  private _updateStateStorage(
    storageType: StateStorage,
    state: { [key: string]: any }
  ) {
    const storageConfigs: any =
      storageType === 'local'
        ? this._localStorageConfigs
        : this._sessionStorageConfigs;
    Object.keys(state).forEach((key) =>
      storageConfigs.next({ ...storageConfigs.value, [key]: state[key] })
    );
  }

  private _setStateStorage(
    storageType: StateStorage,
    state: LocalStorageState | SessionStorageState
  ) {
    const key = this.gridKey + storageType;
    const storage = storageType === 'local' ? localStorage : sessionStorage;
    storage.setItem(key, JSON.stringify(state));
  }

  private _setupSelection() {
    this.selection.changed.subscribe(() => {
      this._updateStateStorage('session', {
        selected: this._selection.selected,
      });
    });
    if (this._sessionData && this._sessionData.selected.length > 0) {
      this._updateStateStorage('session', {
        selected: this._sessionData.selected,
      });
    }
  }

  private _setupService() {
    this._searchSubscriptions?.unsubscribe();

    const data$ = this._searchService.data$.pipe(
      tap((data) => this._data$.next(data as T[]))
    );

    const columnOrder$ = this._searchService.columns$.pipe(
      tap((columns) => {
        this._columnToDisplay$.next(
          this._localData?.columnsToDisplay.length > 0
            ? this._localData.columnsToDisplay
            : Object.entries(columns).reduce(
                (
                  columnsToDisplay: ColumnToDisplayState,
                  [key, column]: [string, ColumnConfig]
                ) =>
                  column.isDefault
                    ? [...columnsToDisplay, key]
                    : [...columnsToDisplay],
                []
              )
        );

        if (!this._columnToDisplay$.value.includes('select')) {
          this._columnToDisplay$.value.unshift('select');
        }
        if (!this._columnToDisplay$.value.includes('fill')) {
          this._columnToDisplay$.value.push('fill');
        }
        this._columnToDisplay$.next(this._columnToDisplay$.value);

        this._updateStateStorage('local', {
          columnsToDisplay: this._columnToDisplay$.value.filter(
            (column) => column !== 'select' && column !== 'fill'
          ),
          sort: this._sort$.value,
        });
      })
    );

    const getSort$ = combineLatest([
      this._searchService.sort$,
      this._searchService.defaultSort$,
    ]).pipe(
      tap(([sort, defaultSort]) => this._sort$.next(sort || defaultSort))
    );

    const setSort$ = this._sort$.pipe(
      distinctUntilChanged(),
      tap((sort) => {
        // tslint:disable-next-line:no-unused-expression
        sort && this._searchService.sort$.next(sort);
      })
    );

    const setLocal = this._localStorageConfigs.pipe(
      tap((a) => this._setStateStorage('local', a))
    );

    this._searchSubscriptions = merge(
      data$,
      columnOrder$,
      getSort$,
      setSort$,
      setLocal
    )
      .pipe(takeUntil(this._reset$))
      .subscribe();
  }

  private _setupPaginator() {
    this._paginatorSubscriptions?.unsubscribe();
    if (this._localData) {
      this._searchService.paging$.value.limit = this._localData.pageSize;
      this._searchService.sort$.next(this._localData.sort);
    }
    if (this._sessionData) {
      this._searchService.paging$.value.offset = this._sessionData.offset;
    }
    const pageChange = this._paginator
      ? merge(this._paginator.page, this._paginator.initialized)
      : of(null);

    const totalRecords = this._searchService.totalRecords$.pipe(
      tap((records) => {
        this.totalRecords = records;
        this._paginator.length = this._totalRecords;
      })
    );
    const getPaginator = this._searchService.paging$.pipe(
      tap((page) => {
        if (this._isLegacy) {
          this._selection.clear();
        }
        this._updateStateStorage('local', { pageSize: page.limit });
        this._updateStateStorage('session', { offset: page.offset });
        this._paginator.pageIndex = page.offset / page.limit;
        this._paginator.pageSize = page.limit;
      })
    );
    const setPaginator = pageChange.pipe(
      tap((page: LuiTableDataSourcePageEvent) => {
        if (page) {
          this._searchService.paging$.next({
            limit: page.pageSize,
            offset: page.pageIndex * page.pageSize,
          });
        }
      })
    );
    const setSession = this._sessionStorageConfigs.pipe(
      tap((a) => {
        this._setStateStorage('session', a);
      })
    );
    const setLocal = this._localStorageConfigs.pipe(
      tap((a) => {
        this._setStateStorage('local', a);
      })
    );
    this._paginatorSubscriptions = merge(
      getPaginator,
      setPaginator,
      totalRecords,
      setLocal,
      setSession
    )
      .pipe(takeUntil(this._reset$))
      .subscribe();
  }

  public isAllSelected() {
    return this._searchService.data$.value.every((row) =>
      this.selection.isSelected(row.poNumber) ? true : false
    );
  }

  public toggleAllRows() {
    this.isAllSelected()
      ? this._searchService.data$.value.forEach((row) =>
          this.selection.deselect(row.poNumber)
        )
      : this._searchService.data$.value.forEach((row) =>
          this.selection.select(row.poNumber)
        );
  }

  public toggleLegacy() {
    this._isLegacy = !this._isLegacy;
    sessionStorage.setItem(
      `${this.gridKey}` + 'isLegacy',
      JSON.stringify(this._isLegacy)
    );
  }
}
