import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PasswordResetService } from 'src/app/services/resetPassword/passwordReset.service';

@Component({
  selector: 'app-recover-password-component',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
  //#region Properties

  public recoverPasswordForm: FormGroup = this.formBuilder.group({
    username: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
  });

  public displayInstructions = false;

  //#endregion

  constructor(
    private resetPswdSrv: PasswordResetService,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit() {
    /** empty block */
  }

  //#region Methods

  public sendEmail(): void {
    this.resetPswdSrv.getPasswordRecovery(
      this.recoverPasswordForm.value['username']
    );
    this.displayInstructions = true;
  }

  //#endregion
}
