export enum ROLES {
  HEADING = 'heading',
  TAB = 'tab',
  TABLIST = 'tab-list',
  TABPANEL = 'tab-panel',
}

export enum STATES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const COMPONENT_NAMESPACE = 'tab-group';
