import { ActionCreator, DispatchSchema } from '@lbmx/action-ui';
import { AnalyticsStatus, DashboardState, Widgets } from '@lbmx/analytics';

export enum HttpMethod {
  DELETE = 'delete',
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

export interface SetWidget {
  index: number;
  name: string;
}

export interface DashboardActions {
  finalize: ActionCreator<DashboardState>;
  setDashboard: ActionCreator<DashboardState>;
  setConfig: ActionCreator<DashboardState>;
  setStatus: ActionCreator<DashboardState>;
  setWidget: ActionCreator<DashboardState>;
  setGridArea: ActionCreator<DashboardState>;
  updateState: ActionCreator<DashboardState>;
}

export const defaultDashboardState: DashboardState = {
  dashboard: [null, null, null, null, null, null],
  gridArea: [
    [0, 1],
    [2, 3],
    [4, 5],
  ],
  config: {},
  status: AnalyticsStatus.READY,
  settings: false,
};

export const dashboardSchema: DispatchSchema<DashboardActions, DashboardState> =
  {
    finalize: {
      command: (_, state: DashboardState) => {
        const status = state.status < 0 ? state.status : AnalyticsStatus.DONE;
        return { status };
      },
    },
    setDashboard: { command: (dashboard: string[]) => ({ dashboard }) },
    setGridArea: { command: (gridArea: number[][]) => ({ gridArea }) },
    setConfig: { command: (config: Widgets) => ({ config }) },
    setStatus: { command: (status: AnalyticsStatus) => ({ status }) },
    setWidget: {
      command: ({ index, name }: SetWidget, { dashboard }: DashboardState) => {
        const kpiDashboard = [...dashboard];
        kpiDashboard[index] = name;
        return { dashboard: [...kpiDashboard] };
      },
    },
    updateState: {
      command: (state: DashboardState) => {
        return state;
      },
    },
  };
