import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

export interface IHomeView {
  accountKey?: number;
  marketplaceImgKey?: number;
  marketplaceImg?: string;
  marketplaceLogoKey?: number;
  marketplaceLogo?: string;
  welcomeImgKey?: number;
  welcomeImg?: string;
  welcomeMessage?: string;
  marketplaceNews?: string;
  lbmxNews?: string;
  lbmxSupport?: string;
}
@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
})
export class HomeViewComponent {
  @Input('view') set viewSetter(view: IHomeView) {
    this.set(view);
  }

  public defaultDashboardState = {
    dashboard: [null, null, null, null, null, null],
    gridArea: [
      [0, 1],
      [2, 3],
      [4, 5],
    ],
  };
  public lbmxNews = '';
  public marketplaceNews = '';
  public lbmxSupport = '';
  public welcomeMessage = '';
  public welcomeImage: SafeStyle = '';
  public marketplaceImg: SafeStyle = '';

  constructor(private sanitizer: DomSanitizer) {
    /** empty */
  }

  public set(view: IHomeView) {
    this.welcomeImage = this.sanitizer.bypassSecurityTrustStyle(
      `url(${view?.welcomeImg})`
    );
    this.welcomeMessage = view?.welcomeMessage || '';

    this.marketplaceImg = this.sanitizer.bypassSecurityTrustStyle(
      `url(${view?.marketplaceImg})`
    );

    this.lbmxNews = view?.lbmxNews || '';
    this.marketplaceNews = view?.marketplaceNews || '';
    this.lbmxSupport = view?.lbmxSupport || '';
  }
}
