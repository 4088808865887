import { Injectable } from '@angular/core';
import { BaseHttpService } from '@lbmx/root-services';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigProvider } from '../../provider/config-provider';

export enum SiteType {
  MY_MARKETPLACE = 1,
  SUPPLY_CLOUD,
}

@Injectable({
  providedIn: 'root',
})
export class SiteTypeService {
  constructor(
    private configProvider: ConfigProvider,
    private http: BaseHttpService
  ) {
    this.http.baseUrl =
      this.configProvider.AppSetting?.uriConfiguration?.configurationRootURL;
    this.http
      .fetch<{ errorCode: number; record?: number }>({
        endpoint: '/site-type',
        httpMethod: 'GET',
      })
      .pipe(tap((res) => this.siteType$.next(res.record as SiteType)))
      .subscribe();
  }

  public get siteType(): SiteType {
    return this.siteType$.value;
  }
  public siteType$ = new BehaviorSubject<SiteType>(undefined);

  public get siteName(): string {
    switch (this.siteType) {
      case SiteType.SUPPLY_CLOUD:
        return 'supplycloud';
      default:
        return 'my-marketplace';
    }
  }
  public get homeUrl(): string {
    switch (this.siteType) {
      case SiteType.SUPPLY_CLOUD:
        return 'http://www.gosupplycloud.com';
      default:
        return 'http://www.lbmx.com';
    }
  }
}
