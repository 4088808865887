import { Component, OnInit } from '@angular/core';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import {
  BaseHttpService,
  SearchContainerLoaderService,
} from '@lbmx/search-container';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-make-payments',
  templateUrl: './make-payments.component.html',
  providers: [SearchContainerLoaderService, ConfirmationService],
})
export class MakePaymentsComponent implements OnInit {
  // search container loader states
  public searchContainerName = 'makePayments';
  public i18nSearchField = 'MAKE_PAYMENT.SEARCH';
  public toolbarActionsDispatcher = {};
  public baseUrl = `${this.configPrv.AppSetting?.uriMarketplace?.receivedInvoiceRootURL}/payment`;
  public columnActions = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService,
    private spinner: SpinnerService,
    private configPrv: ConfigProvider,
    private http: BaseHttpService,
    private searchContainerLoaderService: SearchContainerLoaderService,
    private store: Store<any>
  ) {
    http.baseUrl =
      this.configPrv.AppSetting?.uriMarketplace?.receivedInvoiceRootURL;
  }

  public ngOnInit(): void {
    /** empty block */
  }
}
