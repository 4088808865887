import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@lbmx/phoenix-lib-utils';
import { BaseHttpService } from '@lbmx/root-services';
import { finalize, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { OtpService } from '../../../services/otp/otp.service';

@Component({
  selector: 'lbmx-otp-new',
  templateUrl: './otp.component.html',
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable-next-line:component-class-suffix
export class OtpComponentNew implements AfterViewInit {
  public fields = new Array(6);
  public inputs: HTMLInputElement[];
  private _pointer = 0;
  public ninetyDaySelected = false;

  public endpointUrl: string;
  public navigateToUrl: string;
  public allowRetry = true;

  @Input() set endpoint(endpoint: string) {
    this.endpointUrl = endpoint;
  }

  @Input() set url(url: string) {
    this.navigateToUrl = url;
  }

  @Output() public otpClosed = new EventEmitter();

  constructor(
    public otpService: OtpService,
    private el: ElementRef,
    public http: BaseHttpService,
    public configPrv: ConfigProvider,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private otp: OtpService,
    private client: HttpClient,
    private location: Location
  ) {
    this.http.baseUrl = configPrv.AppSetting.uriBase.apiBaseUrl;
  }

  public ngAfterViewInit(): void {
    this.inputs = Array.from<any>(
      this.el.nativeElement.querySelectorAll('input[name="otp"]')
    );
    this.inputs[0]?.focus();
  }

  public handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');

    const pastedDigits = pastedData
      ?.split('')
      .filter((char) => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)
      .slice(0, this.inputs?.length - this._pointer)
      .join('');

    pastedDigits?.split('').forEach((digit, index) => {
      this.inputs[this._pointer + index].value = digit;
    });

    this._pointer =
      Math.min(this._pointer + pastedDigits.length, this.inputs.length) - 1;

    if (pastedDigits?.length > 0) {
      this.focusAndSelect(this.inputs[this._pointer]);
      this.handleSave();
    }
  }

  public handleKeyUp(event) {
    const current = this.getElementAtIndex(`${this._pointer}`);
    const next = this.getElementAtIndex(`${this._pointer + 1}`);
    const previous = this.getElementAtIndex(`${this._pointer - 1}`);

    // Forward
    this.handleMoveForward(current, next);

    // Backward
    this.handleBackspace(event, previous);

    // Save
    this.handleSave();
  }

  public handleFocus(event) {
    if (this._pointer !== event.target.id) {
      this.getElementAtIndex(`${this._pointer}`)?.focus();
    }
  }

  public allowNumbers(event) {
    const charCode = event.keyCode;
    return charCode >= 48 && charCode <= 57;
  }

  private getElementAtIndex(index: string): HTMLInputElement {
    return this.inputs[parseInt(index, 10)] as HTMLInputElement;
  }

  private focusAndSelect(inputElement: HTMLInputElement) {
    inputElement.focus();
    inputElement.select();
  }

  private handleSave() {
    if (this.getOtp()?.length === this.inputs?.length) {
      this.save();
    }
  }

  private handleMoveForward(current: HTMLInputElement, next: HTMLInputElement) {
    if (current?.value?.length === 1 && next) {
      this._pointer++;
      this.focusAndSelect(next);
    }
  }

  private handleBackspace(event: any, previous: HTMLInputElement) {
    if (event.key === 'Backspace' && previous) {
      this._pointer--;
      this.focusAndSelect(previous);
    }
  }

  private save() {
    this.spinner.on();
    if (!!this.endpointUrl && this.allowRetry) {
      this.allowRetry = false;
      const headers = new HttpHeaders().set('X-OTP', this.getOtp());
      this.http
        .fetch({
          endpoint: this.endpointUrl,
          httpMethod: 'POST',
          body: {
            otp: this.getOtp(),
            rememberThisDevice: this.ninetyDaySelected,
          },
          options: {
            headers,
          },
        })
        .pipe(
          tap(() => {
            this.otpService.status = 200;
          }),
          finalize(() => {
            this.spinner.off();

            switch (this.otpService.status) {
              case 401:
              case 404:
              case 403:
              case 500:
                this.clearOtpInput();
                break;
              case 410:
              case 429:
                this.otpClosed.emit();
                this.otpService.showOtpAuth = false;
                break;
              case 200:
                this.router.navigate([this.navigateToUrl]);
                this.otpService.showOtpAuth = false;
                this.otpService.request = null;
                break;
            }
          })
        )
        .subscribe();
    }
  }

  private clearOtpInput() {
    this.allowRetry = true;
    // if unauth reset inputs and try again
    this.inputs.forEach((input) => {
      input.value = '';
    });

    this._pointer = 0;
    this.getElementAtIndex(`${this._pointer}`)?.focus();
  }

  private getOtp() {
    return this.inputs.reduce((otp, input) => {
      return (otp += input?.value);
    }, '');
  }

  public ninetyDay() {
    this.ninetyDaySelected = !this.ninetyDaySelected;
  }
}
