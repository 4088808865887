import { Directive, HostBinding, Inject } from '@angular/core';
import { LUI_ACCORDION_ITEM, LuiAccordionItem } from './item';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lui-accordion-item-panel, [luiAccordionItemPanel]',
  exportAs: 'luiAccordionItemPanel',
})
// tslint:disable-next-line: directive-class-suffix
export class LuiAccordionItemPanel {
  constructor(
    @Inject(LUI_ACCORDION_ITEM)
    public accordionItem: LuiAccordionItem
  ) {}

  @HostBinding('attr.id')
  public readonly panelId = `lui-item-panel-${this.accordionItem.index}`;

  @HostBinding('class.expanded')
  get expanded() {
    return this.accordionItem.expanded;
  }

  @HostBinding('class.collapsed')
  get collapsed() {
    return !this.accordionItem.expanded;
  }

  @HostBinding('class')
  get nameSpace() {
    return 'lui-accordion-item-panel';
  }
  // @HostBinding('style')
  // get style() {
  //   return this.accordionItem.expanded
  //     ? { 'max-height': '100%' }
  //     : {
  //         'max-height': '0',
  //         // 'overflow-y': 'hidden',
  //       };
  // }
}
