import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng-lts';
import { AdvancedSearchModule } from '../advanced-search/module';
import { SimpleSearchModule } from '../simple-search/module';
import { ToolbarModule } from '../toolbar/module';
import { LuiLegacySearchContainer } from './legacy-search-container';

@NgModule({
  declarations: [LuiLegacySearchContainer],
  imports: [
    CommonModule,
    AdvancedSearchModule,
    SimpleSearchModule,
    ToolbarModule,
    ButtonModule,
    TranslocoModule,
    PerFormPrimengModule,
  ],
  exports: [LuiLegacySearchContainer],
})
export class LegacySearchContainerModule {}
