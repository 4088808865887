export const apiUrls = {
  automationOrderWizardUrl: () => {
    return `/automation/form/rule/reorder`;
  },
  newAutomationWizardUrl: () => {
    return '/automation/form/rule/new';
  },
  editAutomationWizardUrl: () => {
    return '/automation/form/rule/edit';
  },
  copyAutomationWizardUrl: () => {
    return '/automation/form/rule/copy';
  },
  bulkUpdate: () => {
    return '/bulk-update';
  },
  delete: () => {
    return '/delete';
  },
  ruleGenRefetch: () => {
    return '/automation/form/rule/refetch';
  },
  ruleSave: () => {
    return '/automation/form/rule/save';
  },
  newWarning: () => {
    return '/automation/form/warning/new';
  },
  editWarning: () => {
    return '/automation/form/warning/edit';
  },
  deleteNoSlash: () => {
    return 'delete';
  },
  viewWarning: (warningKey) => {
    return `/automation/warning/${warningKey}`;
  },
};

export const moduleUrls = {
  automationsMailbox: () => {
    return `/AutomationsModule/mailbox/automations-mailbox`;
  },
  automationOrderWizard: () => {
    return `/AutomationsModule/mailbox/automations-mailbox/order`;
  },
  warningsScreen: () => {
    return `/AutomationsModule/mailbox/automations-mailbox/warnings`;
  },
  newRules: () => {
    return `/AutomationsModule/rules/new`;
  },
  editRules: () => {
    return `/AutomationsModule/rules/edit`;
  },
  copyRules: () => {
    return `/AutomationsModule/rules/copy`;
  },
};
