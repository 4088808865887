import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { UtilsModule } from '@lbmx/phoenix-lib-utils';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule, ProgressSpinnerModule } from 'primeng-lts';
import { ExcludeOptionsContainerComponent } from './exclude-options-container.component';
import { ExcludeOptionsComponent } from './exclude-options.component';

@NgModule({
  declarations: [ExcludeOptionsComponent, ExcludeOptionsContainerComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    PerFormPrimengModule,
    ProgressSpinnerModule,
    ButtonModule,
    UtilsModule,
  ],
  exports: [ExcludeOptionsContainerComponent],
})
export class ExcludeOptionsModule {}
