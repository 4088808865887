import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Component
({
    selector: 'app-main-scroll-top',
    templateUrl: './main-scroll-top.component.html',
    styleUrls: ['./main-scroll-top.component.scss']
})

export class ScrollTopComponent implements OnInit {
    //#region Properties

    // public
    public onWindowScrolled = false;

    // private
    private document: Document;

    //#endregion

    constructor(@Inject(DOCUMENT) document: Document) {
        this.document = document;
    }

    public ngOnInit() {
        /** empty block */
    }

    //#region Methods

    @HostListener('window:scroll', [])
    public onWindowScroll() {
        if (
            window.pageYOffset ||
            this.document.documentElement.scrollTop ||
            this.document.body.scrollTop > 100
        ) {
            this.onWindowScrolled = true;
        } else if (
           this.onWindowScrolled && window.pageYOffset ||
           this.document.documentElement.scrollTop ||
           this.document.body.scrollTop < 10
        ) {
            this.onWindowScrolled = false;
        }
    }

    public  scrollToTop() {
        const self = this;

        (function smoothscroll() {
            const currentScroll = self.document.documentElement.scrollTop || self.document.body.scrollTop;

            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    //#endregion
}
