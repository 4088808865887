import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LUI_ACCORDION, LuiAccordion } from './accordion';
import { COMPONENT_NAMESPACE, ROLES } from './roles';
import { UniqueSelectionDispatcher } from './unique-selection-dispatcher';

export const LUI_ACCORDION_ITEM = new InjectionToken<LuiAccordionItem>(
  'LuiAccordionItem'
);

/** Used to generate unique ID for each accordion item. */
let nextId = 0;

/**
 * An basic directive expected to be extended and decorated as a component.  Sets up all
 * events and attributes needed to be managed by a CdkAccordion parent.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lui-accordion-item, [luiAccordionItem]',
  exportAs: 'luiAccordionItem',
  providers: [
    // Provide `CDK_ACCORDION` as undefined to prevent nested accordion items from
    // registering to the same accordion.
    { provide: LUI_ACCORDION, useValue: undefined },
    { provide: LUI_ACCORDION_ITEM, useExisting: LuiAccordionItem },
  ],
})

// tslint:disable-next-line: directive-class-suffix
export class LuiAccordionItem implements OnDestroy {
  /** Subscription to openAll/closeAll events. */
  private _openCloseAllSubscription = Subscription.EMPTY;

  private _expanded = false;

  /** Whether the AccordionItem is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  private _disabled = false;

  public namespace = COMPONENT_NAMESPACE;

  public panel = ROLES.PANEL;

  /** Event emitted every time the AccordionItem is closed. */
  @Output() public readonly closed: EventEmitter<void> =
    new EventEmitter<void>();
  /** Event emitted every time the AccordionItem is opened. */
  @Output() public readonly opened: EventEmitter<void> =
    new EventEmitter<void>();
  /** Event emitted when the AccordionItem is destroyed. */
  @Output() public readonly destroyed: EventEmitter<void> =
    new EventEmitter<void>();

  /**
   * Emits whenever the expanded state of the accordion changes.
   * Primarily used to facilitate two-way binding.
   * @docs-private
   */
  @Output() public readonly expandedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private _index: number = nextId++;
  get index(): number {
    return this._index;
  }

  /** The unique AccordionItem id. */
  @HostBinding('attr.id')
  public readonly id: string = `lui-accordion-child-${this._index}`;

  @HostBinding('class')
  get nameSpace() {
    return ROLES.ITEM;
  }

  /** Unregister function for _expansionDispatcher. */
  private _removeUniqueSelectionListener: () => void = () => {
    /* empty block */
    // tslint:disable-next-line: semicolon
  };

  // /** The unique section id. */
  // readonly sectionId: string = `accordion-body-${nextId}`;

  // /** The unique accordion id. */
  // readonly accordionId: string = `accordion${nextId}id`;

  /** Whether the AccordionItem is expanded. */
  @Input()
  @HostBinding('attr.aria-expanded')
  get expanded(): boolean {
    return this._expanded;
  }
  set expanded(expanded: boolean) {
    expanded = expanded;

    // Only emit events and update the internal value if the value changes.
    if (this._expanded !== expanded) {
      this._expanded = expanded;
      this.expandedChange.emit(expanded);

      if (expanded) {
        this.opened.emit();
        /**
         * In the unique selection dispatcher, the id parameter is the id of the CdkAccordionItem,
         * the name value is the id of the accordion.
         */
        const accordionId = this.accordion ? this.accordion.id : this.id;
        this._expansionDispatcher.notify(this.id, accordionId);
      } else {
        this.closed.emit();
      }

      // Ensures that the animation will run when the value is set outside of an `@Input`.
      // This includes cases like the open, close and toggle methods.
      this._changeDetectorRef.markForCheck();
    }
  }

  constructor(
    @Optional()
    @Inject(LUI_ACCORDION)
    @SkipSelf()
    public accordion: LuiAccordion,
    private _changeDetectorRef: ChangeDetectorRef,
    protected _expansionDispatcher: UniqueSelectionDispatcher
  ) {
    this._removeUniqueSelectionListener = _expansionDispatcher.listen(
      (id: string, accordionId: string) => {
        if (
          this.accordion &&
          !this.accordion.multi &&
          this.accordion.id === accordionId &&
          this.id !== id
        ) {
          this.expanded = false;
        }
      }
    );

    // When an accordion item is hosted in an accordion, subscribe to open/close events.
    if (this.accordion) {
      this._openCloseAllSubscription = this._subscribeToOpenCloseAllActions();
    }
  }

  /** Emits an event for the accordion item being destroyed. */
  public ngOnDestroy() {
    this.opened.complete();
    this.closed.complete();
    this.destroyed.emit();
    this.destroyed.complete();
    this._removeUniqueSelectionListener();
    this._openCloseAllSubscription.unsubscribe();
  }

  /** Toggles the expanded state of the accordion item. */
  public toggle(): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
    }
  }

  /** Sets the expanded state of the accordion item to false. */
  public close(): void {
    if (!this.disabled) {
      this.expanded = false;
    }
  }

  /** Sets the expanded state of the accordion item to true. */
  public open(): void {
    if (!this.disabled) {
      this.expanded = true;
    }
  }

  private _subscribeToOpenCloseAllActions(): Subscription {
    return this.accordion._openCloseAllActions.subscribe(
      (expanded: string | boolean) => {
        // Only change expanded state if item is enabled
        if (!this.disabled) {
          this.expanded = !!expanded;
        }
      }
    );
  }
}
