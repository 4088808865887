import { Field, FieldsDefinition } from '@lbmx/per-form';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function mapQueryParams(): OperatorFunction<
  [{ [key: string]: any }, FieldsDefinition<{ [key: string]: Field }>],
  [{ [key: string]: any }, FieldsDefinition<{ [key: string]: Field }>]
> {
  return (source) =>
    source.pipe(
      map(([queryParams, fieldDefinitions]) => {
        const params = Object.entries(queryParams).reduce(
          (_queryParams, [fieldKey, value]) => {
            return {
              ..._queryParams,
              [fieldKey]:
                fieldDefinitions[fieldKey]?.type === 'calendar'
                  ? new Date(`${value}`)
                  : value,
            };
          },
          {}
        );
        return [params, fieldDefinitions];
      })
    );
}
