import { Filters, Sort } from '@lbmx/analytics';
import { FieldsSchema, Form, PerForm } from '@lbmx/per-form';
import { add, sub } from 'date-fns';
import { FilterType } from './report-loader/report-loader.ui';

export interface ReportFilters {
  variances: Filters[];
  filters: Filters;
  sort: Sort;
}

export const createReportFilters = (form: PerForm): ReportFilters => {
  return Object.entries(form.values).reduce(
    (
      reportFilters: ReportFilters,
      [key, value]: [string, any]
    ): ReportFilters => {
      switch (form.schema[key].filterType) {
        case FilterType.VARIANCE:
          return {
            ...reportFilters,
            variances: [
              ...reportVariance(
                key,
                value ? value[0] : null,
                value ? value[1] : null,
                form.schema[key].varianceCount
              ),
            ],
          };
        case FilterType.FILTER:
          return {
            ...reportFilters,
            filters: {
              ...reportFilters.filters,
              ...doForType(form.schema, key, value),
            },
          };
        case FilterType.SORT_FIELD:
          return {
            ...reportFilters,
            sort:
              value === '' || value === null || value === undefined
                ? []
                : [
                    {
                      ...reportFilters.sort[0],
                      field: value,
                    },
                  ],
          };
        case FilterType.SORT_ORDER:
          return {
            ...reportFilters,
            sort:
              value === '' || value === null || value === undefined
                ? []
                : [
                    {
                      ...reportFilters.sort[0],
                      order: value,
                    },
                  ],
          };
        default:
          return { ...reportFilters };
      }
    },
    { variances: [], filters: {}, sort: [] }
  );
};

export const reportVariance = (
  name: string,
  min: any,
  max: any,
  count: number = 1
): Filters[] => {
  let variance: Filters[] = [];
  for (let i = 0; i < count; i++) {
    variance = [
      ...variance,
      dateRange(
        name,
        min ? sub(min, { years: i }) : null,
        max ? sub(max, { years: i }) : null
      ),
    ];
  }
  return variance;
};

export const createSort = (
  values: Form,
  sortField: string,
  orderField: string
): Sort => {
  return values[sortField]
    ? [
        {
          field: values[sortField],
          order: values[orderField] ? values[orderField] : 1,
        },
      ]
    : [];
};

export const createFilters = (
  schema: FieldsSchema<Form>,
  values: Form
): Filters => {
  return Object.entries(values).reduce(
    (filters: Filters, [key, value]: [string, any]) => ({
      ...filters,
      ...doForType(schema, key, value),
    }),
    {}
  );
};

export const doForType = (
  schema: FieldsSchema<Form>,
  name: string,
  value: any
) => {
  switch (schema[name].type) {
    case 'dateRange':
    case 'dateTimeRange':
      return dateRange(name, value ? value[0] : null, value ? value[1] : null);
    case 'checkboxSelection':
    case 'multiSelect':
      return eqArray(name, value);
    case 'text':
      return fz(name, value);
    default:
      return eq(name, value);
  }
};

const fz = (name: string, value = '') => {
  return value === '' ? {} : { [name]: { fz: [value] } };
};

const dateRange = (name: string, min: any, max: any) => {
  const body = Object.assign(
    {},
    min && { gte: [min.toISOString().split('T')[0]] },
    max && { lt: [add(max, { days: 1 }).toISOString().split('T')[0]] }
  );
  return min || max
    ? {
        [name]: body,
      }
    : {};
};

// const dateRange = ( name: string, min: any, max:)

const range = (name: string, min?: any, max?: any) => {
  const body = Object.assign(
    {},
    min && { gte: [`${min}`] },
    max && { lte: [`${max}`] }
  );
  return min || max
    ? {
        [name]: body,
      }
    : {};
};

const eqArray = (name: string, values: any[] = []) => {
  return values?.length === 0 || values?.length === undefined
    ? {}
    : { [name]: { eq: values } };
};

const eq = (name: string, value: any = null) => {
  return `${value}` && value !== null
    ? {
        [name]: {
          eq: [
            value.constructor === Date
              ? value.toISOString().split('T')[0]
              : `${value}`,
          ],
        },
      }
    : {};
};
