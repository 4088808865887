import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Action, ActionDispatcher, ActionUiService } from '@lbmx/action-ui';
import {
  AnalyticsStatus,
  DashboardState,
  TemplateTypes,
} from '@lbmx/analytics';
import { actions, selectors } from '@lbmx/app-state';
import {
  AnalyticsKpi,
  AnalyticsState,
  KpiConfig,
} from '@lbmx/app-state/lib/interfaces';
import { AppSetting } from '@lbmx/types';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'primeng';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  exhaustMap,
  finalize,
  first,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { KpiSelectionModalComponent } from '../select-kpi/kpi-selection-modal/kpi-selection-modal.component';
import {
  DashboardActions,
  dashboardSchema,
  defaultDashboardState,
  HttpMethod,
} from './dashboard.ui';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  providers: [ActionUiService, DialogService],
})
export class DashboardComponent implements OnInit {
  @Input() public defaultDashboardState: DashboardState;
  @Input() public stateKey: string;

  public appSettings: AppSetting = this.configProvider.AppSetting;

  public templateTypes = TemplateTypes;
  public state: DashboardState;
  public fetch = new Subject();
  private analyticsState = new BehaviorSubject({});
  private analyticsState$: Observable<AnalyticsState> = this.store.pipe(
    select(selectors.analyticsFeature),
    first((analyticsFeature) => analyticsFeature !== null),
    tap((analyticsState) => this.analyticsState.next(analyticsState))
  );
  private dashboardSettings$: Observable<any>;
  public actions: ActionDispatcher<DashboardActions, DashboardState> =
    Action.createDispatcher(dashboardSchema);

  constructor(
    public actionUi: ActionUiService<DashboardState>,
    private configProvider: ConfigProvider,
    public dialogService: DialogService,
    private store: Store<any>,
    private http: HttpClient,
    private router: Router
  ) {
    this.actionUi.initialize(defaultDashboardState, this.actions);
  }

  public ngOnInit(): void {
    this.dashboardSettings$ = this.store.pipe(
      select(selectors.setting, { key: this.stateKey }),
      first((dashboardSettings) => dashboardSettings !== null)
    );

    this.actions.updateState.dispatch(this.defaultDashboardState);

    this.actionUi.state
      .pipe(
        tap((state) => (this.state = { ...state })),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();
    this.fetch
      .pipe(
        tap(() => this.actions.setStatus.dispatch(AnalyticsStatus.LOADING)),
        exhaustMap(() =>
          forkJoin([this.dashboardSettings$, this.analyticsState$]).pipe(
            map(([dashboardSettings, analyticsState]) => {
              if (Object.entries(dashboardSettings || {}).length > 0) {
                this.actions.setDashboard.dispatch(dashboardSettings.dashboard);
                this.actions.setGridArea.dispatch(dashboardSettings.gridArea);
              }

              return this.transformAnalyticsState(analyticsState.kpis);
            }),
            catchError((err) => {
              this.actions.setStatus.dispatch(AnalyticsStatus.ERROR);
              return of(err);
            }),
            finalize(() => this.actions.finalize.dispatch())
          )
        ),
        tap((value) => {
          this.actions.setConfig.dispatch(value);
        }),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();

    this.fetch.next();
  }

  public transformAnalyticsState(kpis: AnalyticsKpi) {
    return Object.entries(kpis).reduce(
      (kpiConfigs, [kpiName, config]: [string, KpiConfig]) => ({
        ...kpiConfigs,
        [kpiName]: this.transformConfig(config, kpiName),
      }),
      {}
    );
  }

  public transformConfig(config: KpiConfig, kpiName: string) {
    /**
     * splitting the kpi name won't be necessary when the pim and documents
     * kpi requests implement the same interface
     */
    const moduleName = kpiName.split('.')[0];

    return {
      ...config,
      request: this.buildRequest(
        this.buildEndpoint(moduleName, config),
        config.httpMethod,
        config.Filters
      ).pipe(map((res: { [key: string]: number }) => res?.count ?? res?.value)),
    };
  }

  public show(index: number) {
    const ref = this.dialogService.open(KpiSelectionModalComponent, {
      data: {
        analyticsState: this.analyticsState.value,
      },
      showHeader: false,
      styleClass: 'kpi-dialog',
    });
    ref.onClose
      .pipe(
        tap((name) => {
          if (name) {
            this.actions.setWidget.dispatch({ name, index });
            this.store.dispatch(
              actions.updateSettings({
                endPoints: this.appSettings.uriUserState,
                stateKey: this.stateKey,
                state: {
                  dashboard: this.state.dashboard,
                  gridArea: this.state.gridArea,
                },
              })
            );
          }
        }),
        takeUntil(this.actionUi.shouldDestroy)
      )
      .subscribe();
  }

  private buildEndpoint(moduleName: string, config: any): string {
    return `${
      moduleName === 'pim'
        ? this.appSettings.uriProduct.baseUri
        : this.appSettings.uriMarketplace[`${config.service}RootURL`]
    }${config.endpoint}`;
  }

  private buildRequest(
    endpoint: string,
    httpMethod: string,
    body: any
  ): Observable<object> {
    switch (httpMethod) {
      case HttpMethod.GET:
        return this.http.get(endpoint, {
          params: this.buildDocumentsBody(body),
          withCredentials: true,
        });
      case HttpMethod.POST:
        return this.http.post(endpoint, body, { withCredentials: true });
      case HttpMethod.PUT:
        return this.http.put(endpoint, body, { withCredentials: true });
      case HttpMethod.DELETE:
        return this.http.delete(endpoint, { withCredentials: true });
    }
  }

  private buildDocumentsBody(body: { [key: string]: any }) {
    const { Filters, ...documentsBody } = body;
    body = {
      ...documentsBody,
      ...Filters,
    };
    return body;
  }

  public navigate(config) {
    const filters = config.Filters?.Filters;
    this.router.navigate([config.redirectUrl], {
      queryParams: { filters: JSON.stringify(filters) },
    });
  }
}
