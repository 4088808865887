import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerFormModule } from '@lbmx/per-form';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng-lts';
import { LuiSimpleSearch } from './simple-search';

@NgModule({
  declarations: [LuiSimpleSearch],
  imports: [
    CommonModule,
    ButtonModule,
    TranslocoModule,
    PerFormPrimengModule,
    PerFormModule,
  ],
  exports: [LuiSimpleSearch],
})
export class SimpleSearchModule {}
