import {
  CDK_TREE_NODE_OUTLET_NODE,
  CdkNestedTreeNode,
  CdkTree,
  CdkTreeNode,
  CdkTreeNodeDef,
} from '@angular/cdk/tree';
import {
  AfterContentInit,
  Attribute,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  IterableDiffers,
  OnDestroy,
  TemplateRef,
} from '@angular/core';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 * Wrapper for the CdkTree node with Material design styles.
 */
@Directive({
  selector: 'lui-tree-node',
  exportAs: 'luiTreeNode',
  providers: [{ provide: CdkTreeNode, useExisting: LuiTreeNode }],
})

// tslint:disable-next-line: directive-class-suffix
export class LuiTreeNode<T> extends CdkTreeNode<T> {
  public static ngAcceptInputTypeDisabled: BooleanInput;
  public tabIndex: number;

  @Input() public role: 'treeitem' | 'group' = 'treeitem';

  @HostBinding('attr.aria-expanded') get expanded() {
    return this.isExpanded;
  }
  @HostBinding('attr.aria-level') get leveled() {
    return this.role === 'treeitem' ? this.level : null;
  }
  @HostBinding('attr.role') get attributeRole() {
    return 'node';
  }
  @HostBinding('class') get class() {
    return 'lui-tree-node';
  }

  @Input() public index: number;
  @Input() public disabled: boolean;

  constructor(
    protected _elementRef: ElementRef<HTMLElement>,
    protected _tree: CdkTree<T>,
    @Attribute('tabindex') tabIndex: string
  ) {
    super(_elementRef, _tree);
    this.tabIndex = Number(tabIndex) || 0;
  }
}

/**
 * Wrapper for the CdkTree node definition with Material design styles.
 */
@Directive({
  selector: '[luiTreeNodeDef]',
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['when: luiTreeNodeDefWhen'],
  providers: [{ provide: CdkTreeNodeDef, useExisting: LuiTreeNodeDef }],
})

// tslint:disable-next-line: directive-class-suffix
export class LuiTreeNodeDef<T> extends CdkTreeNodeDef<T> {
  // tslint:disable-next-line:no-input-rename
  @Input('luiTreeNode') public data: T;
  constructor(templateRef: TemplateRef<any>) {
    super(templateRef);
  }
}

/**
 * Wrapper for the CdkTree nested node with Material design styles.
 */
@Directive({
  selector: 'lui-nested-tree-node',
  exportAs: 'luiNestedTreeNode',
  providers: [
    { provide: CdkNestedTreeNode, useExisting: LuiNestedTreeNode },
    { provide: CdkTreeNode, useExisting: LuiNestedTreeNode },
    { provide: CDK_TREE_NODE_OUTLET_NODE, useExisting: LuiNestedTreeNode },
  ],
})
// tslint:disable-next-line: directive-class-suffix
export class LuiNestedTreeNode<T>
  extends CdkNestedTreeNode<T>
  implements AfterContentInit, OnDestroy
{
  public static ngAcceptInputTypDdisabled: BooleanInput;
  // tslint:disable-next-line:no-input-rename
  @Input('luiNestedTreeNode') public node: T;

  /** Whether the node is disabled. */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /** Tabindex for the node. */
  @Input()
  get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex;
  }
  set tabIndex(value: number) {
    // If the specified tabIndex value is null or undefined, fall back to the default value.
    this._tabIndex = value != null ? value : 0;
  }
  private _tabIndex: number;

  @HostBinding('attr.aria-expanded') get expanded() {
    return this.isExpanded;
  }
  @HostBinding('attr.role') get attributeRole() {
    return 'nested-node';
  }
  @HostBinding('class') get class() {
    return 'lui-nested-tree-node';
  }

  constructor(
    protected _elementRef: ElementRef<HTMLElement>,
    protected _tree: CdkTree<T>,
    protected _differs: IterableDiffers,
    @Attribute('tabindex') tabIndex: string
  ) {
    super(_elementRef, _tree, _differs);
    this.tabIndex = Number(tabIndex) || 0;
  }

  // This is a workaround for https://github.com/angular/angular/issues/23091
  // In aot mode, the lifecycle hooks from parent class are not called.
  // TODO(tinayuangao): Remove when the angular issue #23091 is fixed
  public ngAfterContentInit() {
    super.ngAfterContentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
