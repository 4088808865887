// tslint-disable
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchService } from '@lbmx/root-services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lui-toolbar',
  templateUrl: './toolbar.html',
})
// tslint:disable-next-line: component-class-suffix
export class LuiToolbar implements OnInit {
  @Input() public toolbarLabel = '';
  @Input() public controlId = '';
  @Output() public buttonClick: EventEmitter<string> = new EventEmitter();

  public buttons = [];

  constructor(private searchService: SearchService) {}

  public ngOnInit(): void {
    this.searchService.toolbarButtons$
      .asObservable()
      .pipe(
        tap((toolbarButtons: any) => {
          this.buttons = Object.values(toolbarButtons);
        })
      )
      .subscribe();
  }

  public onButtonClick(value: string) {
    this.buttonClick.emit(value);
  }
}
