import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ActiveTabService } from './active-tab-service';
import { ROLES } from './roles';

@Component({
  selector: 'lui-tab',
  templateUrl: './tab.html',
  encapsulation: ViewEncapsulation.None,
})

// tslint:disable-next-line: component-class-suffix
export class LuiTab {
  public panel = ROLES.TABPANEL;
  @Input() public label: string;

  @HostBinding('hidden') get hidden() {
    return this.label !== this.activeTabSrv.activeTab;
  }

  public isActive = false;

  constructor(public activeTabSrv: ActiveTabService) {}
}
