import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LuiTab } from './tab';
import { LuiTabGroup } from './tab-group';
import { LuiTabHeader } from './tab-header';

@NgModule({
  declarations: [LuiTab, LuiTabGroup, LuiTabHeader],
  imports: [CommonModule],
  exports: [LuiTab, LuiTabGroup, LuiTabHeader],
})
export class TabModule {}
