import {
  Directive,
  HostBinding,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { COMPONENT_NAMESPACE } from './roles';

/** Used to generate unique ID for each accordion. */
let nextId = 0;

/**
 * Injection token that can be used to reference instances of `CdkAccordion`. It serves
 * as alternative token to the actual `CdkAccordion` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const LUI_ACCORDION = new InjectionToken<LuiAccordion>('LuiAccordion');

/**
 * Directive whose purpose is to manage the expanded state of CdkAccordionItem children.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'lui-accordion, [luiAccordion]',
  exportAs: 'luiAccordion',
  providers: [{ provide: LUI_ACCORDION, useExisting: LuiAccordion }],
})

// tslint:disable-next-line: directive-class-suffix
export class LuiAccordion implements OnDestroy, OnChanges {
  /** Emits when the state of the accordion changes */
  // Is this necessary?
  public readonly _stateChanges = new Subject<SimpleChanges>();

  /** Stream that emits true/false when openAll/closeAll is triggered. */
  public readonly _openCloseAllActions: Subject<boolean> =
    new Subject<boolean>();

  private _index: number = nextId++;
  get index(): number {
    return this._index;
  }

  /** A readonly id value to use for unique selection coordination. */
  @HostBinding('attr.id')
  public readonly id: string = `lui-accordion-${this._index}`;

  @HostBinding('class')
  get nameSpace() {
    return COMPONENT_NAMESPACE;
  }

  /** Whether the accordion should allow multiple expanded accordion items simultaneously. */
  @Input()
  get multi(): boolean {
    return this._multi;
  }
  set multi(multi: boolean) {
    this._multi = multi;
  }
  private _multi = false;

  /** Opens all enabled accordion items in an accordion where multi is enabled. */
  public openAll(): void {
    if (this._multi) {
      this._openCloseAllActions.next(true);
    }
  }

  /** Closes all enabled accordion items. */
  public closeAll(): void {
    this._openCloseAllActions.next(false);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this._stateChanges.next(changes);
  }

  public ngOnDestroy() {
    this._stateChanges.complete();
    this._openCloseAllActions.complete();
  }
}
