import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Form, PerForm } from '@lbmx/per-form';
import {
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { AppSetting } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { SelectItemGroup } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import {
  ReportBuilderReportConfig,
  ReportTemplate,
  SingleQueryResponse,
} from '../flexmonster-loader/flexmonster-loader.ui';

@Component({
  selector: 'app-flexmonster',
  templateUrl: './flexmonster.component.html',
  styleUrls: ['./flexmonster.component.scss'],
})
export class FlexmonsterComponent implements OnInit, OnDestroy{
  public appSettings: AppSetting = this.configPrv.AppSetting;
  public reportEndpoint = '';
  public form: PerForm;
  public params: Form;
  private _onDestroySubject = new Subject();
  public reportRequest: Observable<any>;
  public response: any;
  public groupedDataSources: SelectItemGroup[];
  public filteredDataSources: any[];
  public recordData: object[];
  public selectedReportTemp: ReportTemplate;

  constructor(
    private configPrv: ConfigProvider,
    public httpClient: HttpClient,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private toast: ToastrNotificationService,
    private translocoService: TranslocoService
  ) {}

  public onDataSourceChange(event: any) {
    const selectedItem = this.getItemByValue(event.value, event.originalEvent.srcElement.innerText);
    this.http
      .post<SingleQueryResponse>(
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint + '/SearchByReportKey' || '',
        {
          ...selectedItem,
        },
        { withCredentials: true }
      )
      .subscribe((response) => {
        this.selectedReportTemp = { ...response.record };
      });
  }

  public getItemByValue(value: string, label: string) {
    for (const group of this.groupedDataSources) {
      const item = group.items.find(i => i.value === value && i.label === label);
      if (item) {
        const report = {
          reportId : item.value,
          reportName : item.label,
          isGenerated: item['isGenerated']
        };
        return report;
      }
    }
    return null;
  }

  public OnReportSaved(isSaved: boolean) {
    if (isSaved) {
      setTimeout(() => {
        this.PopulateDataSourceDropDown();
        this.cdr.detectChanges();
        this.toast.successNotify(
          this.translocoService.translate('REPORTBUILDER.TOAST_REPORT_SAVED')
        );
      }, 400);
    }
  }
  public PopulateDataSourceDropDown() {
    this.http
      .get<ReportBuilderReportConfig>(
        this.configPrv.AppSetting.uriAnalytics.getReportBuilderDashboardConfig,
        { withCredentials: true }
      )
      .pipe(
        tap((response) => {
          this.groupedDataSources = [...response.fields.report.options];
          this.groupedDataSources = [...this.groupedDataSources];
        })
      )
      .subscribe();
  }
  public ngOnInit(): void {
    this.PopulateDataSourceDropDown();
  }

  public ngOnDestroy(): void {
    this._onDestroySubject.next(true);
  }
}
