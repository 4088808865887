import { Directionality } from '@angular/cdk/bidi';
import { CdkTree, CdkTreeNode, CdkTreeNodePadding } from '@angular/cdk/tree';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

/**
 * Wrapper for the CdkTree padding with Material design styles.
 */
@Directive({
  selector: '[luiTreeNodePadding]',
  providers: [{ provide: CdkTreeNodePadding, useExisting: LuiTreeNodePadding }],
})
// tslint:disable-next-line: directive-class-suffix
export class LuiTreeNodePadding<T> extends CdkTreeNodePadding<T> {
  /** The level of depth of the tree node. The padding will be `level * indent` pixels. */
  @Input('luiTreeNodePadding')
  public set level(value: number) {
    super.level = value;
  }
  /** The indent for each level. Default number 40px from material design menu sub-menu spec. */
  @Input('luiTreeNodePaddingIndent') public set indent(value: number) {
    super.indent = value;
  }
  constructor(
    treeNode: CdkTreeNode<T>,
    tree: CdkTree<T>,
    renderer: Renderer2,
    element: ElementRef<HTMLElement>,
    dir: Directionality
  ) {
    super(treeNode, tree, renderer, element, dir);
  }
}
