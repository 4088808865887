import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OtpService } from '../../../services/otp/otp.service';

@Component({
  selector: 'lbmx-otp',
  templateUrl: './otp.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OtpComponent implements AfterViewInit {
  public fields = new Array(6);
  public inputs: HTMLInputElement[];
  private _pointer = 0;

  constructor(
    public ref: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    public otpService: OtpService,
    private el: ElementRef
  ) {}

  public ngAfterViewInit(): void {
    this.inputs = Array.from<any>(
      this.el.nativeElement.querySelectorAll('input')
    );
    this.inputs[0]?.focus();
  }

  public handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');

    const pastedDigits = pastedData
      ?.split('')
      .filter((char) => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57)
      .slice(0, this.inputs?.length - this._pointer)
      .join('');

    pastedDigits?.split('').forEach((digit, index) => {
      this.inputs[this._pointer + index].value = digit;
    });

    this._pointer =
      Math.min(this._pointer + pastedDigits.length, this.inputs.length) - 1;

    if (pastedDigits?.length > 0) {
      this.focusAndSelect(this.inputs[this._pointer]);
      this.handleSave();
    }
  }

  public handleKeyUp(event) {
    const current = this.getElementAtIndex(`${this._pointer}`);
    const next = this.getElementAtIndex(`${this._pointer + 1}`);
    const previous = this.getElementAtIndex(`${this._pointer - 1}`);

    // Forward
    this.handleMoveForward(current, next);

    // Backward
    this.handleBackspace(event, previous);

    // Save
    this.handleSave();
  }

  public handleFocus(event) {
    if (this._pointer !== event.target.id) {
      this.getElementAtIndex(`${this._pointer}`)?.focus();
    }
  }

  public allowNumbers(event) {
    const charCode = event.keyCode;
    return charCode >= 48 && charCode <= 57;
  }

  private getElementAtIndex(index: string): HTMLInputElement {
    return this.inputs[parseInt(index, 10)] as HTMLInputElement;
  }

  private focusAndSelect(inputElement: HTMLInputElement) {
    inputElement.focus();
    inputElement.select();
  }

  private handleSave() {
    if (this.getOtp()?.length === this.inputs?.length) {
      this.save();
    }
  }

  private handleMoveForward(current: HTMLInputElement, next: HTMLInputElement) {
    if (current?.value?.length === 1 && next) {
      this._pointer++;
      this.focusAndSelect(next);
    }
  }

  private handleBackspace(event: any, previous: HTMLInputElement) {
    if (event.key === 'Backspace' && previous) {
      this._pointer--;
      this.focusAndSelect(previous);
    }
  }

  private save() {
    this.ref.close(this.getOtp());
  }

  private getOtp() {
    return this.inputs.reduce((otp, input) => {
      return (otp += input?.value);
    }, '');
  }
}
