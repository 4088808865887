import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService, ToastrNotificationService } from '@lbmx/phoenix-lib-utils';
import { BaseHttpService } from '@lbmx/root-services';
import { SearchContainerLoaderService } from '@lbmx/search-container';
import { Request as BuilderRequest, } from '@lbmx/web-core';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from 'primeng-lts';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { apiUrls } from 'src/app/features/automations-module/url-config';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-ordered-by-location-exclusion',
  templateUrl: './ordered-by-location-exclusion.component.html',
  styleUrls: ['./ordered-by-location-exclusion.component.scss'],
  providers: [SearchContainerLoaderService, ConfirmationService],
})
export class OrderedByLocationExclusionComponent {
  constructor(
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
    private toast: ToastrNotificationService,
    private spinner: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private configPrv: ConfigProvider,
    private http: BaseHttpService,
    private searchContainerLoaderService: SearchContainerLoaderService
  ) {
    http.baseUrl = this.baseUrl;
  }

  public selectedExcludeOptions: any = {};
  public locationsToExclude: any[];
  public showExcludeOptionsDialog = false;
  public exclusionType: any;

  // search container loader states
  public selections: any[];
  public searchContainerName = 'orderedByLocationSearch';
  public showGenerateFileDialog = false;

  public i18nSearchField = 'ACTIONS.SEARCH_ORDRED_BY_LOCATION';
  public params: { [key: string]: any } = {};
  public baseUrl = `${this.configPrv.AppSetting?.uriAnalytics?.queryEndpoint}` + '/Exclusion/ordered-by-location';
  public excludeUrl = `${this.baseUrl}` + '/exclude-options/form';

  public toolbarActionsDispatcher = {
    excludeTransactionsOnly: (selection: any[]) => {
      this.locationsToExclude = [...selection];
      this.exclusionType = 1;
      this.showExcludeOptionsDialog = true;
    },

    excludeRebatesOnly: (selection: any[]) => {
      this.locationsToExclude = [...selection];
      this.exclusionType = 2;
      this.showExcludeOptionsDialog = true;
    },

    excludeBoth: (selection: any[]) => {
      this.locationsToExclude = [...selection];
      this.exclusionType = 3;
      this.showExcludeOptionsDialog = true;
    },
  };
  public columnActions = {};

  public closeExcludeOptionsDialog() {
    this.showExcludeOptionsDialog = false;
  }

  public updateExcludeOptions() {
    this.searchContainerLoaderService.refreshSearch();
  }

}
