import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  LuiWidgetWrapperModule,
  LuiWizardV3Module,
} from '@lbmx/per-form-primeng';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule, DialogModule } from 'primeng-lts';
import { WizardPopupComponent } from './wizard-popup.component';

@NgModule({
  declarations: [WizardPopupComponent],
  imports: [
    CommonModule,
    DialogModule,
    LuiWizardV3Module,
    LuiWidgetWrapperModule,
    TranslocoModule,
    ButtonModule,
  ],
  exports: [WizardPopupComponent],
})
export class WizardPopupModule {}
