import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { selectors } from '@lbmx/app-state';
import {
  AuthenticateService,
  UserAuthRequest,
  VerifyAuthRequest,
} from '@lbmx/phoenix-lib-auth';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { BaseHttpService } from '@lbmx/root-services';
import { UserState } from '@lbmx/types';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { OtpService } from 'src/app/services/otp/otp.service';

@Component({
  selector: 'app-user-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Properties
  private navigateToUrl: string;
  private showReAuthMessage: boolean;
  private initialRememberMeState: boolean;
  private rememberMe: boolean;
  private rememberDevice: boolean;
  private verifyCode: string;
  public authorizationMessage = {};
  // public showOtpAuth = false;
  public isCredentialInvalid = false; // used in the html component
  public unknownError = false; // used in the html component
  public subscription: Subscription;
  public message = {
    badRequest: () =>
      this.translocoService.translate('GROUP.FAILURE_TOAST_MESSAGE'),
  };
  @ViewChild('username') public usernameRef: ElementRef;

  public marketingUrl: SafeResourceUrl;
  public showAdCampaign = false;
  public verifyUrl = '';

  public loginForm: FormGroup = this.formBuilder.group({
    username: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    password: ['', [Validators.required]],
    checked: [''],
  });
  public subscriptions: Subscription[] = [];

  //#endregion
  constructor(
    private authSrv: AuthenticateService,
    private spinnerSrv: SpinnerService,
    private translocoService: TranslocoService,
    private formBuilder: FormBuilder,
    private configProvider: ConfigProvider,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private toastrService: ToastrNotificationService,
    private http: BaseHttpService,
    private sanitizer: DomSanitizer,
    private otpService: OtpService
  ) {
    this.verifyUrl = '/security/auth/verify';
  }

  public ngAfterViewInit(): void {
    if (this.usernameRef && this.usernameRef.nativeElement) {
      this.usernameRef.nativeElement.focus();
    }
  }

  public async ngOnInit() {
    this.http.baseUrl =
      this.configProvider.AppSetting.uriConfiguration.configurationRootURL;
    this.http
      .fetch<{ errorCode: number; record?: string }>({
        endpoint: '/marketing',
        httpMethod: 'GET',
      })
      .pipe(
        tap((res) => {
          const marketingUrl = res.record;

          if (typeof marketingUrl === 'string' && marketingUrl.length > 0) {
            this.showAdCampaign = true;
            this.marketingUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(marketingUrl);
          }
        })
      )
      .subscribe();

    this.navigateToUrl =
      this.route.snapshot.queryParams['nextUrl'] || '/default';
    this.showReAuthMessage = this.route.snapshot.queryParams['reAuth'] || false;

    this.subscriptions.push(
      this.store
        .pipe(
          select(selectors.userFeature),
          map((userState: UserState) => {
            if (
              new Date(userState.tokenExpires) > new Date() &&
              userState.profile.toolKeys.length > 0
            ) {
              this.router.navigate([this.navigateToUrl]);
            }
          })
        )
        .subscribe()
    );

    const cookieStatus = await this.authSrv.getCookieRememberMe().toPromise();

    this.rememberMe = cookieStatus.rememberMeStatus;

    if (this.rememberMe) {
      this.loginForm.controls['checked'].setValue(true);

      const cookieUser = await this.authSrv.getCookieUserName().toPromise();
      this.loginForm.controls['username'].setValue(cookieUser.userName);
    }

    if (this.rememberMe == null) {
      this.rememberMe =
        this.loginForm.value['checked'].value === null
          ? false
          : this.loginForm.value['checked'];
    }
    this.initialRememberMeState = this.rememberMe;
    this.spinnerSrv.off();
    this.subscription = this.loginForm
      .get('password')
      .valueChanges.subscribe((password) => {
        if (password !== this.loginForm.value.password) {
          this.isCredentialInvalid = false;
        }
      });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  //#region Methods
  public verify(data: any) {
    this.verifyCode = data.code;
    this.rememberDevice = data.rememberDevice;
    this.spinnerSrv.on();
    this.authSrv.verify(
      this.buildVerificationRequest(),
      this.configProvider.AppSetting,
      () => {
        this.router.navigate([this.navigateToUrl]);
        this.spinnerSrv.off();
      },
      (err) => {
        if (err.error && err.status === 401) {
          this.authorizationMessage = {
            status: false,
            message:
              this.translocoService.translate('COMMON.INVALID_OTP') || '',
          };
        } else {
          this.authorizationMessage = {
            status: false,
            message:
              this.translocoService.translate('COMMON.UNKNOWN_ERROR') || '',
          };
        }
        this.spinnerSrv.off();
      }
    );
  }

  public authenticate(): void {
    this.spinnerSrv.on();
    this.authSrv.authenticate(
      this.buildLoginRequest(),
      this.configProvider.AppSetting,
      () => {
        this.router.navigate([this.navigateToUrl]);
        this.spinnerSrv.off();
      },
      (err) => {
        if (err.status === 401) {
          this.isCredentialInvalid = true;
          this.spinnerSrv.off();
        } else {
          this.toastrService.errorNotify(this.message.badRequest());
          this.spinnerSrv.off();
        }
      },
      () => {
        this.spinnerSrv.off();
        this.otpService.showOtpAuth = true;
      }
    );
  }

  public toggleAuthorization(event: Event) {
    this.authorizationMessage = {};
    this.otpService.showOtpAuth = !this.otpService.showOtpAuth;
  }

  public checkBoxStatus(): void {
    this.rememberMe = this.loginForm.value['checked'];
  }

  public emptyPassword() {
    this.loginForm.get('password').reset();
  }

  //#region Helpers

  private buildLoginRequest(): UserAuthRequest {
    const request: UserAuthRequest = new UserAuthRequest();
    request.username = this.loginForm.value['username'].trim();
    request.password = this.loginForm.value['password'].trim();
    request.rememberMe = this.rememberMe;

    return request;
  }

  private buildVerificationRequest(): VerifyAuthRequest {
    const verifyRequest: VerifyAuthRequest = new VerifyAuthRequest();
    verifyRequest.username = this.loginForm.value['username'].trim();
    verifyRequest.password = this.loginForm.value['password'].trim();
    verifyRequest.OTP = this.verifyCode.trim();
    verifyRequest.rememberDevice = this.rememberDevice;
    return verifyRequest;
  }
  //#endregion
}
