import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';

import { selectors } from '@lbmx/app-state';
import { NavItem } from '@lbmx/types';

import { translate } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { routePermissions } from './permissions';

@Component({
  selector: 'app-main-navigation-container',
  templateUrl: './main-navigation-container.component.html',
})
export class MainNavigationContainerComponent implements OnInit {
  public allowedModules$: Observable<{ [key: string]: boolean }>;
  public navigationItems$: Observable<NavItem[]>;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private store: Store,
    private toastrNotificationService: ToastrNotificationService
  ) {}

  public ngOnInit() {
    this.navigationItems$ = this.store.pipe(select(selectors.navItems));
  }
}
