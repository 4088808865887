import { HttpClient } from '@angular/common/http';
import { EventEmitter, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  ColumnDefinitions,
  SearchFilters,
  TemplateTypes,
} from '@lbmx/analytics';
import { Form, GenerateFormService, PerForm } from '@lbmx/per-form';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import { FlexmonsterPivot } from 'ng-flexmonster';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectItemGroup } from 'primeng/api';
import { ReportQuery } from '../reportBuilder.module/reportBuilder';
import {
  DataSource,
  DropdownFilterOptions,
  ExportParams,
  QueryResponse,
  ReportBuilderReportConfig,
  ReportTemplate,
  SingleQueryResponse,

  // TableAlignment,
} from './flexmonster-loader.ui';
export class InputTextHelpTextDemo {
  public value: string | undefined;
}

@Component({
  selector: 'app-flexmonster-loader',
  templateUrl: './flexmonster-loader.component.html',
  styleUrls: ['./flexmonster-loader.component.scss'],

  encapsulation: ViewEncapsulation.None, // added this to overwrite primeng styling for dropdown filter.
})
export class FlexmonsterLoaderComponent implements OnDestroy, OnChanges {
  @Output() public isReportSaved = new EventEmitter<boolean>();
  @Input() public selectedDataSourceItem: ReportTemplate;
  public reportRequest: Observable<any>;
  public defaultQuery: SearchFilters;
  public tableRequest: Observable<{
    data: Array<{ [key: string]: any }>;
  }>;
  public form: PerForm;
  public params: Form;
  public saveForm = new FormGroup({
    reportname: new FormControl('', {
      validators: [Validators.required],
    }),
    reportdescription: new FormControl('', {
      validators: [Validators.required],
    }),
  });

  get IsReportNameValid() {
    return (
      this.saveForm.controls.reportname.touched &&
      this.saveForm.controls.reportname.dirty &&
      this.saveForm.controls.reportname.invalid
    );
  }
  get IsReportDescriptionValid() {
    return this.saveForm.controls.reportdescription.touched;
  }
  public reportQuery: ReportQuery = {
    dataSource: {
      data: null,
      type: 'json',
      mapping: null,
    },
    slice: null,
    options: {
      configuratorActive: false,
    },
    localization: {
      grid: {
        dateInvalidCaption: 'No Date',
      },
    },
  };
  public visible = false;

  public dataRequest: Observable<Array<{ [key: string]: any }>>;

  public filterForm: PerForm;

  public data: Array<{ [key: string]: any }> = null;
  public footer: { [key: string]: any } = null;
  public columnsToDisplay: string[] = [];
  public columns: ColumnDefinitions = {};
  public title = '';
  public templateTypes = TemplateTypes;
  public dateRangeFields: string[] = [];
  public recordData;

  @ViewChild('pivot') public pivot!: FlexmonsterPivot;

  private _onDestroy = new Subject();
  public getRequestBodyFromResponse: any;
  public flexmonster: any; // not sure about this!!
  public groupedDataSources: SelectItemGroup[];
  public filteredDataSources: any[];
  public reportname = '';
  public reportdescription = '';

  public savedReport: ReportTemplate = new ReportTemplate();
  public flexmonsterLicenseKey: string =
    this.configPrv.AppSetting.uriAnalytics.flexmonsterLicenseKey;

  public savedReportBuilder;
  public customReportName = '';

  constructor(
    private formGenerator: GenerateFormService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public configPrv: ConfigProvider,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService
  ) {
    this.groupedDataSources = [];
    this.exportHandler = this.exportHandler.bind(this);
  }

  public grandTotalsoptions = [
    {
      label: 'Select',
      value: 'select',
    },
    {
      label: 'Show grand totals',
      value: 'on',
    },
    {
      label: 'Show for rows',
      value: 'rows',
    },
    {
      label: 'Show for columns',
      value: 'columns',
    },
    {
      label: 'Do not show grand totals',
      value: 'off',
    },
  ];

  public subTotalsoptions = [
    {
      label: 'Select',
      value: 'select',
    },
    {
      label: 'Show subtotals',
      value: 'on',
    },
    {
      label: 'Show for rows',
      value: 'rows',
    },
    {
      label: 'Show for columns',
      value: 'columns',
    },
    {
      label: 'Do not show subtotals',
      value: 'off',
    },
  ];

  public layoutOptions = [
    {
      label: 'Select',
      value: 'select',
    },
    {
      label: 'Flat form',
      value: 'flat',
    },
    {
      label: 'Classic form',
      value: 'classic',
    },
    {
      label: 'Compact form',
      value: 'compact',
    },
  ];

  public selectedGrandTotal = 'select';
  public selectedSubtTotal = 'select';
  public selectedLayout = 'select';

  public swapPositions(tabs: any, a: number, b: number) {
    tabs[a].rightGroup = false;
    tabs[b].rightGroup = false;
    [tabs[a], tabs[b]] = [tabs[b], tabs[a]];
  }

  public filterDataSources(event: any) {
    const query = event.query.toLowerCase();
    this.filteredDataSources = this.groupedDataSources.reduce((acc, group) => {
      const filteredItems = group.items.filter((item) =>
        item.label.toLowerCase().includes(query)
      );
      return acc.concat(filteredItems);
    }, []);
  }

  public ChangeConfiguratorStatus(status: boolean): void {
    this.pivot.flexmonster.clear();
    const report = this.pivot.flexmonster.getReport({
      withDefaults: true,
    });

    const myOptions = report['options'];
    myOptions.configuratorActive = this.reportQuery.options.configuratorActive =
      status;
    this.pivot.flexmonster.setOptions(myOptions);

    this.reportQuery.options = myOptions;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedDataSourceItem'].currentValue != null) {
      this.onDropdownSourceChange(
        changes['selectedDataSourceItem'].currentValue
      );
    }
  }

  public onDropdownSourceChange(event: any) {
    this.FetchReport(event.endPoint);
  }

  public saveToServer = () => {
    this.visible = true;

    if (
      this.saveForm.invalid ||
      this.saveForm.value.reportname === null ||
      this.saveForm.value.reportname === ''
    ) {
      this.saveForm.markAllAsTouched();
      return;
    }

    this.savedReportBuilder = this.pivot.flexmonster.getReport();
    this.pivot.flexmonster.setReport(null);
    this.pivot.flexmonster.save({
      filename: 'report.json',
      destination: 'server',
      url:
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint +
        '/Monitor/GetHealth',
      serverContentType: 'application/json',
      callbackHandler: this.reportSaved,
    });
  }

  public reportSaved = (result, error) => {
    result = this.savedReportBuilder;
    this.pivot.flexmonster.setReport(this.reportQuery);

    this.visible = false;
    this.savedReport.reportName = this.saveForm.value.reportname;
    this.savedReport.description = this.saveForm.value.reportdescription;
    this.savedReport.jsonconfig = JSON.stringify(result.slice);
    this.savedReport.dataSource = this.selectedDataSourceItem.dataSource;
    this.savedReport.endPoint = this.selectedDataSourceItem.endPoint;

    if (result.options['configuratorActive'] != null) {
      result.options['configuratorActive'] = false;
    }

    this.savedReport.reportOptions = JSON.stringify(result.options);
    this.savedReport.reportFormat = JSON.stringify(result.formats);
    this.http
      .post<ReportTemplate>(
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint + '/save' || '',
        {
          ...this.savedReport,
        },
        { withCredentials: true }
      )
      .subscribe(
        (suc) => {
          this.isReportSaved.emit(true);
        },
        (err) => {
          if (err?.error?.errorCode === 3) {
            this.toast.errorNotify(
              this.translocoService.translate(
                'REPORTBUILDER.TOAST_REPORT_ALREADY_EXIST'
              )
            );
          } else {
            this.toast.errorNotify(
              this.translocoService.translate('MESSAGES.GENERIC_ERROR')
            );
          }
        }
      );

    this.saveForm.reset({
      reportname: '',
      reportdescription: '',
    });
  }

  public exportHandler = (params: ExportParams) => {
    const currentDateTime = new Date().toISOString().replace(/:/g, '-');
    const filename = `${this.customReportName}_${currentDateTime}`;

    this.pivot.flexmonster.exportTo(params.type, {
      ...params.config,
      filename,
    });
  }

  public customizeToolbar(toolbar: any) {
    if (toolbar) {
      let tabs = toolbar.getTabs();
      toolbar.getTabs = () => {
        tabs = tabs.filter((tab) => tab.id !== 'fm-tab-open');
        tabs = tabs.filter((tab) => tab.id !== 'fm-tab-connect');
        tabs = tabs.filter((tab) => tab.id !== 'fm-tab-share');

        this.customReportName = this.selectedDataSourceItem.reportName.replace(
          / /g,
          '_'
        );
        tabs[1].menu[1].handler = this.exportHandler;
        tabs[1].menu[1].args = {
          type: 'html',
          config: {
            filename: this.customReportName,
          },
        };
        tabs[1].menu[2].handler = this.exportHandler;
        tabs[1].menu[2].args = {
          type: 'csv',
          config: {
            filename: this.customReportName,
          },
        };
        tabs[1].menu[3].handler = this.exportHandler;
        tabs[1].menu[3].args = {
          type: 'excel',
          config: {
            filename: this.customReportName,
          },
        };
        tabs[1].menu[4].handler = this.exportHandler;
        tabs[1].menu[4].args = {
          type: 'image',
          config: {
            filename: this.customReportName,
          },
        };
        tabs[1].menu[5].handler = this.exportHandler;
        tabs[1].menu[5].args = {
          type: 'pdf',
          config: {
            filename: this.customReportName,
          },
        };

        const saveTab = tabs.find((tab) => tab.id === 'fm-tab-save');

        if (!saveTab) {
          return tabs;
        }

        saveTab.handler = this.saveToServer;

        const iconStyle = document.createElement('style');
        iconStyle.textContent = `
        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-left>li>a .fm-svg-icon,
        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-right>li>a .fm-svg-icon,
        #fm-toolbar-wrapper #fm-toolbar>li>a .fm-svg-icon {
          display: none;
        }
        `;
        document.head.appendChild(iconStyle);

        const style = document.createElement('style');
        style.textContent = `
        #fm-toolbar-wrapper #fm-toolbar .fm-toolbar-group-right li.fm-divider {
          display: none;
        }
        `;
        document.head.appendChild(style);

        const leftDividerstyle = document.createElement('style');
        leftDividerstyle.textContent = `
        #fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content {
          overflow-x: hidden;
          overflow-y: hidden;
        }
        `;
        document.head.appendChild(leftDividerstyle);

        const formatStyle = document.createElement('style');
        formatStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text {
          color: #bd0024;
          font-size: 18px;
        }
        `;
        document.head.appendChild(formatStyle);

        const formatLabelStyle = document.createElement('style');
        formatLabelStyle.textContent = `
        #fm-toolbar-wrapper .fm-toolbar-ui .fm-ir-horizontal label {
          text-transform: none;
          color: black;
          font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: 550;
          font-size: 13px;
        }
        `;
        document.head.appendChild(formatLabelStyle);

        const formatPanelStyle = document.createElement('style');
        formatPanelStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-panel-content {
          height: 570px;
         }
        `;
        document.head.appendChild(formatPanelStyle);

        const formatCellStyle = document.createElement('style');
        formatCellStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-format-cells {
          top:  0px !important;
         }
        `;
        document.head.appendChild(formatCellStyle);

        const optionsPopupStyle = document.createElement('style');
        optionsPopupStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-options {
          top:  0px !important;
         }
        `;
        document.head.appendChild(optionsPopupStyle);

        const barStyle = document.createElement('style');
        barStyle.textContent = `
        ul#fm-toolbar {
          background-color: #f2f2f2 !important;
          height: 76px !important;
        }
        `;
        document.head.appendChild(barStyle);

        const fontStyle = document.createElement('style');
        fontStyle.textContent = `
        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-left>li>a .fm-tab-label:hover,
        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-right>li>a .fm-tab-label:hover,
        #fm-toolbar-wrapper #fm-toolbar>li>a .fm-tab-label:hover {
          background-color: #2d2d2d;
          color: #ffffff;
        }

        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-left>li>a .fm-tab-label,
        #fm-toolbar-wrapper #fm-toolbar>.fm-toolbar-group-right>li>a .fm-tab-label,
        #fm-toolbar-wrapper #fm-toolbar>li>a .fm-tab-label {
          top: 20px !important;
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 6px;
          padding-bottom: 7px;
          height:25px;
          letter-spacing: 0.3px;
          color: #525252;
          margin: 10px;
          min-width: 81px;
          background-color: #ffffff;
          width: initial;
          transition: background-color 0.3s ease;
          font: normal normal normal 12px / 13px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
        `;
        document.head.appendChild(fontStyle);

        const tabStyle = document.createElement('style');
        tabStyle.textContent = `
        #fm-toolbar-wrapper #fm-toolbar, #fm-toolbar-wrapper #fm-toolbar a, #fm-toolbar-wrapper #fm-toolbar div,
        #fm-toolbar-wrapper #fm-toolbar input, #fm-toolbar-wrapper #fm-toolbar li, #fm-toolbar-wrapper #fm-toolbar p,
        #fm-toolbar-wrapper #fm-toolbar select, #fm-toolbar-wrapper #fm-toolbar span, #fm-toolbar-wrapper #fm-toolbar table,
        #fm-toolbar-wrapper #fm-toolbar table td, #fm-toolbar-wrapper #fm-toolbar table th, #fm-toolbar-wrapper #fm-toolbar table tr,
        #fm-toolbar-wrapper #fm-toolbar textarea, #fm-toolbar-wrapper #fm-toolbar ul {
          margin-right:30px;
        }
        `;
        document.head.appendChild(tabStyle);

        const barSpaceStyle = document.createElement('style');
        barSpaceStyle.textContent = `
        #fm-toolbar-wrapper #fm-toolbar {
          heght:76px;
        }
        `;
        document.head.appendChild(barSpaceStyle);

        const fieldsStyle = document.createElement('style');
        fieldsStyle.textContent = `
        #fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:nth-child(2) {
          position: absolute;
          bottom: 0;
          margin: 0 12px 15px 0;
          display: flex;
          flex-direction: row-reverse;
        }
        `;
        document.head.appendChild(fieldsStyle);

        const fieldsButtonStyle = document.createElement('style');
        fieldsButtonStyle.textContent = `
        #fm-pivot-view .fm-ui-btns-row .fm-ui-btn:last-child, #fm-toolbar-wrapper .fm-ui-btns-row .fm-ui-btn:last-child {
          margin-right: 10px;
        }
        `;
        document.head.appendChild(fieldsButtonStyle);

        const fieldsPopupStyle = document.createElement('style');
        fieldsPopupStyle.textContent = `
        #fm-pivot-view > div.fm-ui-element.fm-ui.fm-fields-view-wrap.fm-fields-opened > div {
          top: -81px !important;
        }
        `;
        document.head.appendChild(fieldsPopupStyle);

        const fieldsTitleStyle = document.createElement('style');
        fieldsTitleStyle.textContent = `
        #fm-pivot-view div.fm-ui-window .fm-popup-title {
          color: #bd0024;
          font-size: 18px;
        }
        `;
        document.head.appendChild(fieldsTitleStyle);

        const fieldsConStyle = document.createElement('style');
        fieldsConStyle.textContent = `
        #fm-pivot-view .fm-fields-view .fm-popup-content .fm-ui-row {
          height: 90%;
        }
        `;
        document.head.appendChild(fieldsConStyle);

        const calcStyle = document.createElement('style');
        calcStyle.textContent = `
        #fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure {
          display: none !important;
        }
      `;
        document.head.appendChild(calcStyle);

        const layoutStyle = document.createElement('style');
        layoutStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-title-bar .fm-toolbox {
          position: absolute;
          bottom: 0;
          display: flex;
          flex-direction: row-reverse;
        }
      `;
        document.head.appendChild(layoutStyle);

        const layoutButtonStyle = document.createElement('style');
        layoutButtonStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-toolbox.fm-ui-col .fm-ui-btn:last-child {
          margin-right: 10px;
        }
      `;
        document.head.appendChild(layoutButtonStyle);

        const layoutContStyle = document.createElement('style');
        layoutContStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-options > div {
          height: 500px;
        }
      `;
        document.head.appendChild(layoutContStyle);

        const layoutTitleStyle = document.createElement('style');
        layoutTitleStyle.textContent = `
        #fm-toolbar-wrapper .fm-toolbar-ui div.fm-title-2 {
          color: #525252;
          font: normal normal normal 14px / 15px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-weight: bold;
          text-transform: capitalize;
        }
      `;
        document.head.appendChild(layoutTitleStyle);

        const layoutRadioStyle = document.createElement('style');
        layoutRadioStyle.textContent = `
        #fm-pivot-view .fm-ui .fm-ui-col-2, #fm-toolbar-wrapper .fm-ui .fm-ui-col-2 {
          color: #525252;
          font: normal normal normal 12px / 13px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-weight: bold;
        }
      `;
        document.head.appendChild(layoutRadioStyle);

        const radioLabelStyle = document.createElement('style');
        radioLabelStyle.textContent = `
        #fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label {
          font: normal normal normal 12px / 13px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
      `;
        document.head.appendChild(radioLabelStyle);

        const cancelButtonlStyle = document.createElement('style');
        cancelButtonlStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-toolbox .fm-ui-btn:last-child:hover {
          background-color: #e0e0e0;
        }

        #fm-toolbar-wrapper div.fm-panel .fm-toolbox .fm-ui-btn:last-child {
          border: none;
          background-color: #f9f9f9;
          border-radius: 0;
          font: normal normal normal 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          text-transform: capitalize;
        }
      `;
        document.head.appendChild(cancelButtonlStyle);

        const applyButtonlStyle = document.createElement('style');
        applyButtonlStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-toolbox .fm-ui-btn {
          border: none;
          border-radius: 0;
          font: normal normal normal 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          text-transform: capitalize;
          margin-right: 10px;
        }
      `;
        document.head.appendChild(applyButtonlStyle);

        const fieldsApplylStyle = document.createElement('style');
        fieldsApplylStyle.textContent = `
        #fm-pivot-view a.fm-ui-btn-dark, #fm-pivot-view button.fm-ui-btn-dark,
        #fm-toolbar-wrapper a.fm-ui-btn-dark, #fm-toolbar-wrapper button.fm-ui-btn-dark {
          text-transform: capitalize;
          border-radius: 0;
          border: none;
          font: normal normal normal 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
      `;
        document.head.appendChild(fieldsApplylStyle);

        const fieldsCancelStyle = document.createElement('style');
        fieldsCancelStyle.textContent = `
        #fm-pivot-view .fm-ui-btns-row .fm-ui-btn:last-child, #fm-toolbar-wrapper .fm-ui-btns-row .fm-ui-btn:last-child:hover {
          background-color: #e0e0e0;
        }

        #fm-pivot-view .fm-ui-btns-row .fm-ui-btn:last-child, #fm-toolbar-wrapper .fm-ui-btns-row .fm-ui-btn:last-child {
          border: none;
          background-color: #f9f9f9;
          border-radius: 0;
          font: normal normal normal 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          text-transform: capitalize;
        }
      `;
        document.head.appendChild(fieldsCancelStyle);

        const conditionalStyle = document.createElement('style');
        conditionalStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-conditional {
          top: 0 !important;
          height: 90%;
        }
      `;
        document.head.appendChild(conditionalStyle);

        const conditionalContStyle = document.createElement('style');
        conditionalContStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-conditional .fm-panel-content{
          height: 100%;
        }
      `;
        document.head.appendChild(conditionalContStyle);

        const conditionalAddStyle = document.createElement('style');
        conditionalAddStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-conditional .fm-panel-content .fm-popup-content .fm-popup-placeholder {
        display: none;
        }
      `;
        document.head.appendChild(conditionalAddStyle);

        const conditionalButtonStyle = document.createElement('style');
        conditionalButtonStyle.textContent = `
        #fm-toolbar-wrapper > div.fm-popup.fm-panel.fm-toolbar-ui.fm-ui.fm-popup-conditional > div > div.fm-title-bar.fm-ui-row > div.fm-toolbox.fm-ui-col > button.fm-ui-btn.fm-ui-btn-light.fm-button-add{
          bottom: 23rem;
          left: 7.5rem;
        }
      `;
        document.head.appendChild(conditionalButtonStyle);

        const conditionalTitleStyle = document.createElement('style');
        conditionalTitleStyle.textContent = `
        #fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text.fm-ui-col {
          margin-right: 17rem;
        }
        `;
        document.head.appendChild(conditionalTitleStyle);

        this.swapPositions(tabs, 0, 8);
        this.swapPositions(tabs, 1, 6);
        this.swapPositions(tabs, 2, 7);
        this.swapPositions(tabs, 3, 8);
        this.swapPositions(tabs, 4, 6);
        this.swapPositions(tabs, 6, 8);
        this.swapPositions(tabs, 7, 8);

        const optionsTab = tabs.find((tab) => tab.id === 'fm-tab-options');
        if (optionsTab) {
          optionsTab.handler = () => {
            this.showOptionsPopup();
          };
        }

        document
          .querySelectorAll('.ui-dropdown-trigger-icon')
          .forEach((trigger) => {
            trigger.addEventListener('click', function() {
              const select = this.parentNode.querySelector('.select-items');
              select.classList.toggle('select-hide');
            });
          });

        document.querySelectorAll('.select-items div').forEach((item) => {
          item.addEventListener('click', function() {
            const selected = this.parentNode.previousElementSibling;
            selected.textContent = this.textContent;
            this.parentNode.classList.add('select-hide');
          });
        });

        return tabs;
      };
    }
  }

  public FetchReport(endpoint: string): void {
    this.reportRequest = this.http
      .get<ReportBuilderReportConfig>(
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint + endpoint,
        { withCredentials: true }
      )
      .pipe(
        tap((response: ReportBuilderReportConfig) => {
          this.defaultQuery = response?.record?.defaultQuery;
          this.dataRequest = this.http
            .post<QueryResponse>(
              this.configPrv.AppSetting.uriAnalytics.queryEndpoint +
                '/QueryV2' +
                response?.record?.endpoint || '',
              {
                ...response?.record?.defaultQuery,
              },
              { withCredentials: true }
            )
            .pipe(
              map((res) => res?.records || []),
              tap((res) => {
                this.data = res;
                this.getData(this.data);
                if (
                  this.pivot &&
                  this.pivot.flexmonster &&
                  this.pivot.flexmonster.toolbar
                ) {
                  this.customizeToolbar(this.pivot.flexmonster.toolbar);
                }
              })
            );
        }),
        tap((response: ReportBuilderReportConfig) => {
          const mappingObj = {};
          response.record.columns.map((col) => {
            const key = `${col.valueField}`;
            mappingObj[key] = {
              caption: col.label,
              type: col.columnType,
            };
          });
          this.reportQuery.dataSource.mapping = mappingObj;
        })
      );
    this.pivot?.flexmonster?.setReport(this.reportQuery);
  }

  public getData(tapData) {
    this.reportQuery.options['configuratorActive'] = false;
    this.recordData = tapData;
    this.reportQuery.dataSource.data = tapData;
  }

  public ngOnDestroy(): void {
    this._onDestroy.next(true);
    this.pivot?.flexmonster?.dispose();
  }

  public showOptionsPopup() {
    this.selectedGrandTotal =
      this.pivot.flexmonster.getOptions().grid.showGrandTotals;
    this.selectedSubtTotal =
      this.pivot.flexmonster.getOptions().grid.showTotals;
    this.selectedLayout = this.pivot.flexmonster.getOptions().grid.type;

    const popup = document.querySelector(
      '#custom-options-popup'
    ) as HTMLElement;
    const popoupOverlay = document.querySelector(
      '#popupOverlay'
    ) as HTMLElement;

    popup.classList.remove('hideCustomPopup');
    popoupOverlay.classList.remove('hideCustomPopup');
    popoupOverlay.onclick = this.closeOptionsPopup.bind(this);
  }

  public closeOptionsPopup() {
    const popup = document.querySelector(
      '#custom-options-popup'
    ) as HTMLElement;
    const popupOverlay = document.querySelector('#popupOverlay') as HTMLElement;
    popup.classList.add('hideCustomPopup');
    popupOverlay.classList.add('hideCustomPopup');
  }

  public submitOptions() {
    const options = this.pivot.flexmonster.getOptions();
    options.grid.type = this.selectedLayout;
    options.grid.showTotals = this.selectedSubtTotal;
    options.grid.showGrandTotals = this.selectedGrandTotal;
    this.pivot.flexmonster.setOptions(options);
    this.pivot.flexmonster.refresh();
    this.closeOptionsPopup();
  }

  public LoadReportBuilder(event: any) {
    if (this.selectedDataSourceItem) {
      if (
        this.selectedDataSourceItem.jsonconfig === null ||
        this.selectedDataSourceItem.jsonconfig === '' ||
        !('jsonconfig' in this.selectedDataSourceItem)
      ) {
        this.ChangeConfiguratorStatus(true);
        this.reportQuery.slice = null;
        this.reportQuery.options.showDefaultSlice = false;
      } else {
        this.ChangeConfiguratorStatus(false);
        this.reportQuery.slice = this.PopulateSlice();
        this.reportQuery.options = this.PopulateOptions();
        this.reportQuery.formats = this.PopulateFormats();
      }
    }

    this.pivot.flexmonster.setReport(this.reportQuery);
  }

  public PopulateSlice(): any {
    if (this.selectedDataSourceItem.jsonconfig == null) {
      return;
    }

    return JSON.parse(this.selectedDataSourceItem.jsonconfig);
  }

  public PopulateFormats(): any {
    if (this.selectedDataSourceItem.reportFormat == null) {
      return;
    }

    return JSON.parse(this.selectedDataSourceItem.reportFormat);
  }

  public PopulateOptions(): any {
    if (this.selectedDataSourceItem.reportOptions == null) {
      return;
    }

    const options = JSON.parse(this.selectedDataSourceItem.reportOptions);

    return options;
  }
}
