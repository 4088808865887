import { Form, FormDefinition } from '@lbmx/per-form';

export const detailsForm = (row: {
  [key: string]: any;
}): FormDefinition<Form> => ({
  fields: {
    info: {
      type: 'detail',
      options: Object.entries(row).map(
        ([key, value]: [string, any]): { label: string; value: string } => ({
          label: key,
          value: JSON.stringify(value),
        })
      ),
    },
  },
});
