import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ActiveTabService } from './active-tab-service';
import { COMPONENT_NAMESPACE, ROLES } from './roles';
import { LuiTab } from './tab';

@Component({
  selector: 'lui-tab-group',
  templateUrl: './tab-group.html',
  providers: [ActiveTabService],
})

// tslint:disable-next-line: component-class-suffix
export class LuiTabGroup implements AfterContentInit {
  @Input() public activeIndex = 0;

  @ContentChildren(LuiTab, { descendants: true })
  public children: QueryList<LuiTab>;

  public tabs: string[] = [];
  public activeTab: number;
  public tabContent: TemplateRef<any>;

  public namespace = COMPONENT_NAMESPACE;
  public panel = ROLES.TABPANEL;

  constructor(public activeTabSrv: ActiveTabService) {}

  public ngAfterContentInit(): void {
    this.activeTab = this.activeIndex;

    this.children.toArray().map((x) => {
      this.tabs.push(x.label);
    });

    this.activeTabSrv.activeTab =
      this.children.toArray()[this.activeIndex].label;

    this.activeTabSrv.activeIndex = this.activeIndex;
  }
}
