import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { EnvService } from './app/services/env.service';
import { EnvServiceProvider } from './app/services/EnvServiceProvider';

require('./env.js');

const injector = Injector.create({ providers: [EnvServiceProvider] });
const service = injector.get(EnvService);

if (service.Prod === 'true') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
