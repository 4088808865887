import { ColumnDefinitions, SearchFilters } from '@lbmx/analytics';
import { FieldsDefinition, Form } from '@lbmx/per-form';

export enum TableAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum FilterType {
  FILTER = 'filter',
  SORT_FIELD = 'sortField',
  SORT_ORDER = 'sortOrder',
  VARIANCE = 'variance',
}

export interface ColumnsConfig {
  valueField: string;
  label: string;
  alignment: TableAlignment;
  columnType: string;
}

export interface ReportConfig {
  record: {
    endpoint: string;
    label: string;
    filters: FieldsDefinition<Form>;
    defaultQuery: SearchFilters;
    columns: ColumnsConfig[];
    totalRowQuery: SearchFilters;
  };
}

export interface QueryResponse {
  records: Array<{ [key: string]: any }>;
  totalRecords: number;
}

export interface Footer {
  [key: string]: any;
}

// export const buildFilters;
