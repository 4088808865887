import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ActiveTabService {
  public activeTab: string;
  public activeIndex: number;

  public setActiveTab(tab: string, index: number) {
    this.activeTab = tab;
    this.activeIndex = index;
  }
}
