import { Component, OnInit } from '@angular/core';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import {
  BaseHttpService,
  SearchContainerLoaderService,
} from '@lbmx/search-container';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-open-receivables',
  templateUrl: './open-receivables.component.html',
  providers: [SearchContainerLoaderService, ConfirmationService],
})
export class OpenReceivablesComponent implements OnInit {
  // search container loader states
  public searchContainerName = 'openReceivables';
  public i18nSearchField = 'OPEN_RECEIVABLE.SEARCH';
  public toolbarActionsDispatcher = {};
  public baseUrl = `${this.configPrv.AppSetting?.uriMarketplace?.sentInvoiceRootURL}/collection`;
  public columnActions = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService,
    private spinner: SpinnerService,
    private configPrv: ConfigProvider,
    private http: BaseHttpService,
    private searchContainerLoaderService: SearchContainerLoaderService,
    private store: Store<any>
  ) {
    http.baseUrl =
      this.configPrv.AppSetting?.uriMarketplace?.sentInvoiceRootURL;
  }

  public ngOnInit(): void {
    /** empty block */
  }
}
