import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GenerateFormService, PerForm } from '@lbmx/per-form';
import { LoadingState, SearchService } from '@lbmx/root-services';

import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { mapQueryParams } from './advanced-search.operators';
import { formDefinition } from './advanced-search.ui';

@Component({
  selector: 'lui-advanced-search',
  templateUrl: './advanced-search.html',
})
// tslint:disable-next-line: component-class-suffix
export class LuiAdvancedSearch implements OnInit, OnDestroy {
  public fieldDefinitions: { [key: string]: any } = {};
  public appliedIcon = 'assets/images/filter-on.png';
  public form: PerForm;
  public fieldSets = {};
  public LoadingState = LoadingState;

  private _onDestroy = new Subject();
  private queryParams = this.activatedRoute.queryParams.pipe(
    map((params) => JSON.parse(params?.filters || '{}'))
  );
  public fieldsDefinition = this.searchService.fieldsDefinition;

  constructor(
    public searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private formGenerator: GenerateFormService
  ) {}

  public ngOnInit(): void {
    combineLatest([this.queryParams, this.fieldsDefinition])
      .pipe(
        mapQueryParams(),
        tap(([queryParams, fieldDefinitions]) => {
          this.fieldDefinitions = fieldDefinitions;
          this.form = this.formGenerator.init(
            formDefinition(this.fieldDefinitions),
            queryParams
          );
          this.fieldSets = this.form.fieldSets;
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  public preserveOrder() {
    return 0;
  }

  public handleClear() {
    // TODO: have to set proper on clear values
    this.form.patch(this.form.defaultValues);
  }

  public handleAdvancedSearch() {
    // TODO: have to set proper on clear values
  }

  public ngOnDestroy(): void {
    this._onDestroy.next(true);
  }
}
