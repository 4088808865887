import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  ColumnDefinition,
  ColumnDefinitions,
  TemplateTypes,
} from '@lbmx/analytics';
import {
  Field,
  FieldsDefinition,
  Form,
  FormControlRole,
  GenerateFormService,
  PerForm,
} from '@lbmx/per-form';
import { TranslocoService } from '@ngneat/transloco';
import csvDownload from 'json-to-csv-export';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { createReportFilters } from '../filter';
import {
  ColumnsConfig,
  Footer,
  QueryResponse,
  ReportConfig,
  TableAlignment,
} from './report-loader.ui';

@Component({
  selector: 'app-report-loader',
  templateUrl: './report-loader.component.html',
  styleUrls: ['./report-loader.component.scss'],
})
export class ReportLoaderComponent implements OnChanges, OnDestroy {
  @Input() public endpoint = '';

  public reportRequest: Observable<any>;
  public tableRequest: Observable<{
    data: Array<{ [key: string]: any }>;
    footer: Footer;
  }>;
  public dataRequest: Observable<Array<{ [key: string]: any }>>;
  public footerRequest: Observable<Footer>;
  public filterForm: PerForm;

  public data: Array<{ [key: string]: any }> = null;
  public footer: { [key: string]: any } = null;
  public columnsToDisplay: string[] = [];
  public columns: ColumnDefinitions = {};
  public title = '';
  public templateTypes = TemplateTypes;
  public dateRangeFields: string[] = [];

  private _onDestroy = new Subject();

  constructor(
    private formGenerator: GenerateFormService,
    private http: HttpClient,
    public configPrv: ConfigProvider,
    private translocoService: TranslocoService
  ) {}

  public ngOnChanges(): void {
    this.reportRequest = this.http
      .get<ReportConfig>(
        this.configPrv.AppSetting.uriAnalytics.queryEndpoint + this.endpoint,
        { withCredentials: true }
      )
      .pipe(
        tap((response: ReportConfig) => {
          this.dateRangeFields = [];
          const fields = Object.entries(response?.record?.filters).reduce(
            (
              form: FieldsDefinition<Form>,
              [key, value]: [string, Field]
            ): FieldsDefinition<Form> => {
              this.dateRangeFields =
                value.type !== 'dateRange'
                  ? [...this.dateRangeFields]
                  : [...this.dateRangeFields, key];
              return value.type !== 'dateRange'
                ? { ...form, [key]: { ...value } }
                : {
                    ...form,
                    [key]: {
                      ...value,
                      validations: [
                        {
                          test: (formValues) => {
                            return (
                              !!formValues[key] &&
                              (formValues[key]
                                ? !!formValues[key][0]
                                : false) &&
                              (formValues[key] ? !!formValues[key][1] : false)
                            );
                          },
                          message: this.translocoService.translate(
                            'ANALYTICS.ENTER_DATE_RANGE'
                          ),
                        },
                      ],
                      defaultValue: null,
                    },
                  };
            },
            {}
          );
          this.filterForm = this.formGenerator.init({
            layout: {
              'grid-template-columns': '1fr 1fr 1fr 1fr',
              'column-gap': '1.5em',
            },
            controls: {
              export: {
                label: this.translocoService.translate('ACTIONS.EXPORT'),
                role: FormControlRole.SECONDARY,
                callback: (form) => {
                  csvDownload({
                    data: [
                      ...this.data.map((value) =>
                        Object.entries(this.columns).reduce(
                          (
                            newValue: { [key: string]: any },
                            [key, _]: [string, ColumnDefinition]
                          ): { [key: string]: any } => {
                            return { ...newValue, [key]: value[key] ?? '' };
                          },
                          {}
                        )
                      ),
                      Object.entries(this.columns).reduce(
                        (
                          newValue: { [key: string]: any },
                          [key, _]: [string, ColumnDefinition]
                        ): { [key: string]: any } => {
                          return { ...newValue, [key]: this.footer[key] ?? '' };
                        },
                        {}
                      ),
                    ],
                    filename: this.dateRangeFields[0]
                      ? `${this.title.split(' ').join('_')}-${
                          form.values[this.dateRangeFields[0]][0]
                            .toISOString()
                            .split('T')[0] ?? ''
                        }_${
                          form.values[this.dateRangeFields[0]][1]
                            .toISOString()
                            .split('T')[0] ?? ''
                        }`
                      : `${this.title.replace(/ /g, '_')}`,
                    delimiter: ',',
                    headers: [
                      ...Object.entries(this.columns).map(
                        ([_, { label }]: [string, ColumnDefinition]): string =>
                          label || ''
                      ),
                    ],
                  });
                },
                disabled: (form) => !form.valid || !this.data || !this.footer,
              },
            },
            fields,
          });
          this.dateRangeFields.forEach((value) =>
            this.filterForm.setStatus(value, 'dirty', true)
          );
          this.columns = this.buildColumns(response?.record?.columns);
          this.columnsToDisplay = this.buildColumnsToDisplay(
            response?.record?.columns
          );
          this.title = response?.record?.label || '';
        }),
        tap((response: ReportConfig) => {
          this.tableRequest = null;
          this.filterForm.values$
            .pipe(
              tap((value) => {
                const reportFilters = createReportFilters(this.filterForm);
                this.data = null;
                this.footer = null;
                this.dataRequest = this.http
                  .post<QueryResponse>(
                    this.configPrv.AppSetting.uriAnalytics.queryEndpoint +
                      response?.record?.endpoint || '',
                    {
                      ...response?.record?.defaultQuery,
                      variances: [
                        ...reportFilters.variances,
                        ...(response?.record?.defaultQuery.variances || []),
                      ],
                      sort: [
                        ...reportFilters.sort,
                        ...(response?.record?.defaultQuery.variances || []),
                      ],
                      filters: {
                        ...reportFilters.filters,
                        ...(response?.record?.defaultQuery.filters || {}),
                      },
                    },
                    { withCredentials: true }
                  )
                  .pipe(
                    map((res) => res?.records || []),
                    tap((res) => (this.data = res))
                  );
                this.footerRequest = this.http
                  .post<QueryResponse>(
                    this.configPrv.AppSetting.uriAnalytics.queryEndpoint +
                      response?.record?.endpoint || '',
                    {
                      ...response?.record?.totalRowQuery,
                      variances: [
                        ...reportFilters.variances,
                        ...(response?.record?.totalRowQuery.variances || []),
                      ],
                      filters: {
                        ...reportFilters.filters,
                        ...(response?.record?.totalRowQuery.filters || {}),
                      },
                    },
                    { withCredentials: true }
                  )
                  .pipe(
                    map((res) => res?.records[0] || {}),
                    tap((res) => (this.footer = res))
                  );
                if (this.filterForm.isValidForm(value)) {
                  this.tableRequest = forkJoin({
                    data: this.dataRequest,
                    footer: this.footerRequest,
                  });
                }
              }),
              takeUntil(this._onDestroy)
            )
            .subscribe();
        }),
        takeUntil(this._onDestroy)
      );
  }

  public ngOnDestroy(): void {
    this._onDestroy.next(true);
  }

  public buildColumns(columnsConfig: ColumnsConfig[]): ColumnDefinitions {
    return columnsConfig.reduce(
      (
        columnsDef: ColumnDefinitions,
        { valueField, label, alignment }: ColumnsConfig
      ): ColumnDefinitions => {
        return {
          ...columnsDef,
          [valueField]: {
            label,
            styles: { ...this.getAlignment(alignment) },
          },
        };
      },
      {}
    );
  }

  public getAlignment(alignment: TableAlignment) {
    switch (alignment) {
      case TableAlignment.RIGHT:
        return {
          header: { 'content-end': true },
          data: { 'content-end': true },
        };
      case TableAlignment.CENTER:
        return { header: { center: true }, data: { center: true } };
      case TableAlignment.LEFT:
        return { header: { left: true }, data: { left: true } };
      default:
        return {};
    }
  }

  public buildColumnsToDisplay(columnsConfig: ColumnsConfig[]): string[] {
    return columnsConfig.map(
      (columnConfig: ColumnsConfig) => columnConfig.valueField
    );
  }
}
