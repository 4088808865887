import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LuiAccordion } from './accordion';
import { LuiAccordionItem } from './item';
import { LuiAccordionItemControl } from './item-control';
import { LuiAccordionItemPanel } from './item-panel';
import { LuiAccordionStandardItem } from './standard-item';

@NgModule({
  declarations: [
    LuiAccordion,
    LuiAccordionItem,
    LuiAccordionItemControl,
    LuiAccordionItemPanel,
    LuiAccordionStandardItem,
  ],
  imports: [CommonModule],
  exports: [
    LuiAccordion,
    LuiAccordionItem,
    LuiAccordionItemControl,
    LuiAccordionItemPanel,
    LuiAccordionStandardItem,
  ],
})
export class LuiAccordionModule {}
