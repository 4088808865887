import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng-lts';
import {
  LuiPaginatorComponent,
} from './paginator.component';

import { DropdownModule } from 'primeng/dropdown';
@NgModule({
  declarations: [ LuiPaginatorComponent],
  imports: [CommonModule, FormsModule, DropdownModule, ButtonModule, BrowserModule, BrowserAnimationsModule],
  exports: [ LuiPaginatorComponent],
})
export class LuiPaginatorModule {}
