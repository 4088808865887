import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Action, ActionDispatcher, ActionUiService } from '@lbmx/action-ui';

import { SearchService } from '@lbmx/root-services';

import urlJoin from 'proper-url-join';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

import {
  defaultLSCState,
  LSCActions,
  lscSchema,
  LSCState,
} from './leagacy-search-container.ui';

@Component({
  selector: 'lui-legacy-search-container',
  templateUrl: './legacy-search-container.html',
  providers: [ActionUiService],
  encapsulation: ViewEncapsulation.None,
})
// tslint:disable-next-line: component-class-suffix
export class LuiLegacySearchContainer implements OnInit, OnDestroy {
  private _onDestroy: Subject<any> = new Subject();
  public actions: ActionDispatcher<LSCActions, LSCState> =
    Action.createDispatcher(lscSchema);
  public state: LSCState;
  constructor(
    public actionUi: ActionUiService<LSCState>,
    private activatedRoute: ActivatedRoute,
    private configProvider: ConfigProvider,
    private searchService: SearchService
  ) {}

  public ngOnInit() {
    this.actionUi.initialize(defaultLSCState, this.actions);
    const configuration = this.activatedRoute.data.pipe(
      tap(
        (data: {
          appSettingKeys: string[];
          path: string;
          [key: string]: any;
        }) => {
          const [appSettingKey, appSettingSubKey]: string[] =
            data?.appSettingKeys;
          this.searchService.configUrl = urlJoin(
            this.configProvider.AppSetting[appSettingKey][appSettingSubKey],
            data?.path
          );
          this.searchService.dataUrl = urlJoin(
            this.configProvider.AppSetting[appSettingKey][appSettingSubKey],
            data?.path
          );
        }
      )
    );

    const _state = this.actionUi.state.pipe(
      tap((state) => (this.state = { ...state })),
      takeUntil(this.actionUi.shouldDestroy)
    );

    const searchFilters = this.searchService.searchFilters$.asObservable().pipe(
      tap((filters: { [key: string]: any }) => {
        Object.keys(filters?.filters || {}).length > 0
          ? this.actions.setFiltersApplied.dispatch(true)
          : this.actions.setFiltersApplied.dispatch(false);
      })
    );

    forkJoin([configuration, _state, searchFilters])
      .pipe(takeUntil(this._onDestroy))
      .subscribe();
  }

  public onToolbarButtonClick(value: string) {
    // todo
  }

  public ngOnDestroy(): void {
    this.searchService.reset();
    this._onDestroy.next(true);
  }
}
