import { Component, Input } from '@angular/core';
import { ILBMXMegaMenu } from './lbmx-mega-menu.interface';

@Component({
  selector: 'lbmx-nav-bar',
  templateUrl: './lbmx-nav-bar.component.html',
})
export class LBMXNavBarComponent {
  public navItemsLeft: ILBMXMegaMenu[] = [];
  public navItemsRight: ILBMXMegaMenu[] = [];
  public navItems: ILBMXMegaMenu[] = [];

  @Input('navItems') set navItemsSetter(navItems: ILBMXMegaMenu[]) {
    let aNavItemsRight = [];
    let aNavItemsLeft = [];
    if (navItems) {
      for (const item of navItems) {
        if (item.alignment === 'right') {
          aNavItemsRight = [...aNavItemsRight, item];
        } else {
          aNavItemsLeft = [...aNavItemsLeft, item];
        }
      }
    }
    this.navItemsLeft = aNavItemsLeft;
    this.navItemsRight = aNavItemsRight;
    this.navItems = [...aNavItemsLeft, ...aNavItemsRight];
  }

  public handleClick(event: any): void {
    let element = event.target as Element;
    element = this.getValidElementTarget(element);

    // mega menu button header is clicked: collapse or expand that submenu
    if (element.classList.contains('button-header')) {
      this.handleButtonHeaderOnClick(element);
      element = element.parentElement;
    } else if (element.classList.contains('mega-menu')) {
      this.handleButtonHeaderOnClick(element.firstElementChild);
    }

    // Toggle the 'displaying' class
    if (element.classList.contains('displaying')) {
      element.classList.remove('displaying');
    } else {
      element.classList.add('displaying');
    }
  }

  public toggleDisplay(event: any) {
    const navBarElement = document.getElementById('nav-bar');

    let element = event.target as Element;
    element = this.getValidElementTarget(element);

    // Toggle the 'displaying' class
    if (navBarElement.classList.contains('displaying')) {
      navBarElement.classList.remove('displaying');
    } else {
      navBarElement.classList.add('displaying');
    }
  }

  // Narrows down what the user clicked into a category of elements
  // to make handling a click easier
  private getValidElementTarget(element: Element): Element {
    let validTarget = element;
    // Main mobile navbar hamburger button
    if (element.classList.contains('menu-toggle')) {
      this.switchButtonIcon(element, 'pi-times', 'pi-bars');
      validTarget = element.parentElement;
    } else if (
      // Any of the submenu's labels or icons
      element.classList.contains('button-label') ||
      element.classList.contains('button-icon')
    ) {
      validTarget = element.parentElement;
    }
    return validTarget;
  }

  private switchButtonIcon(element: Element, class1: string, class2: string) {
    if (element.classList.contains(class1)) {
      element.classList.remove(class1);
      element.classList.add(class2);
    } else if (element.classList.contains(class2)) {
      element.classList.remove(class2);
      element.classList.add(class1);
    }
  }

  private handleButtonHeaderOnClick(buttonHeader: Element) {
    if (buttonHeader) {
      this.switchButtonIcon(
        buttonHeader.lastElementChild,
        'pi-angle-down',
        'pi-angle-right'
      );
    }
  }
}
