import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';
import {
  chartNoDataTest,
  kpiAdaptor,
  transformResponse,
} from './dashboard-template.ui';

@Component({
  selector: 'app-dashboard-template',
  templateUrl: './dashboard-template.component.html',
  styleUrls: ['./dashboard-template.component.scss'],
})
export class DashboardTemplateComponent implements OnInit {
  public endpoint = '';
  public adaptor = transformResponse;
  public kpiAdaptor = kpiAdaptor;
  public chartNoDataTest = chartNoDataTest;
  public load = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private configProvider: ConfigProvider
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap(() => (this.load = false)),
        delay(0),
        tap(() => (this.load = true)),
        tap(
          (data: { dashboard: string; [key: string]: any }) =>
            (this.endpoint = `${this.configProvider.AppSetting.uriAnalytics.getPurchasesDashboardConfig}${data?.dashboard}`)
        )
      )
      .subscribe();
  }
}
