import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResizableDirective } from './resizable.directive';
import { LuiTableHeader } from './table-header';

@NgModule({
  declarations: [LuiTableHeader, ResizableDirective],
  imports: [CommonModule, DragDropModule],
  exports: [LuiTableHeader],
})
export class TableHeaderModule {}
