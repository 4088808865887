import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { actions, actionTypes, selectors } from '@lbmx/app-state';
import { UserProfile, UserState } from '@lbmx/types';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  first,
  switchMap,
} from 'rxjs/operators';

import { ConfigProvider } from '../../provider/config-provider';
import _checkAuthorization from './authorization.check';
import _checkTerms from './terms.check';

@Injectable()
export class AuthGuardService implements CanActivateChild, CanActivate {
  private dispatcher: {
    [key: string]: (p: UserProfile, u: string) => Observable<boolean | UrlTree>;
  } = {
    checkTerms: (p: UserProfile, u: string): Observable<boolean | UrlTree> =>
      _checkTerms(p, { redirectUrl: '/terms', router: this.router }),
    checkAuthorization: (
      p: UserProfile,
      u: string
    ): Observable<boolean | UrlTree> => _checkAuthorization(p, { url: u }),
  };

  constructor(
    private actions$: Actions,
    private configPrv: ConfigProvider,
    private router: Router,
    private store: Store
  ) {}

  public canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._isAuthorized(state.url, ['checkTerms', 'checkAuthorization']);
  }

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._isAuthorized(state.url, ['checkAuthorization']);
  }

  private _isAuthorized(
    url: string,
    checks: string[]
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectors.userFeature),
      switchMap((userState: UserState) => {
        return this.ensureProfile(userState, url, checks);
      }),
      catchError(() => of(this.router.parseUrl(`/home`)))
    );
  }

  public ensureProfile(
    userState: UserState,
    url: string,
    checks: string[]
  ): Observable<boolean | UrlTree> {
    return userState.profile.userInfo.IsTermsSigned === null ||
      new Date(userState.tokenExpires) < new Date()
      ? this.fetchUserProfile(url, checks)
      : this.checker(checks, userState.profile, url);
  }

  private checker(
    checks: string[],
    profile: UserProfile,
    url: string
  ): Observable<boolean | UrlTree> {
    return from(checks).pipe(
      concatMap((check) => this.dispatcher[check](profile, url)),
      first((result) => result === false || result instanceof UrlTree, true)
    );
  }

  private fetchUserProfile(
    url: string,
    checks: string[]
  ): Observable<boolean | UrlTree> {
    this.refresh();
    return this.actions$.pipe(
      ofType(
        actions.refreshUserProfileSuccess,
        actions.refreshUserProfileFailure
      ),
      exhaustMap(
        (action): Observable<any> => {
          return of(
            action.type === actionTypes.REFRESH_USER_PROFILE_FAILURE
              ? this.router.parseUrl(`/login?nextUrl=${url}`)
              : this.checker(checks, action.userState.profile, url) ||
                  this.router.parseUrl(`/home`)
          );
        }
      )
    );
  }
  private refresh() {
    this.store.dispatch(
      actions.refreshUserProfile({
        endPoints: this.configPrv.AppSetting,
      })
    );
  }
}
