import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSetting } from '@lbmx/types';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

export interface IAnalyticsAuth {
  loginURL: string;
  redirectURL: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public appSettings: AppSetting = this.configPrv.AppSetting;

  constructor(
    private configPrv: ConfigProvider,
    private httpClient: HttpClient
  ) {}

  public getLoginUrls(): Observable<any> {
    return this.httpClient
      .get(this.appSettings.uriSecurity.analytics, { withCredentials: true })
      .pipe(
        exhaustMap((result: IAnalyticsAuth) => {
          return this.httpClient
            .get(result.loginURL, {
              withCredentials: true,
              responseType: 'text',
              headers: new HttpHeaders({
                'Content-type': 'text/html; charset=utf-8',
              }),
            })
            .pipe(
              map(() => result.redirectURL),
              catchError((error) => of(error))
            );
        })
      );
  }
}
