import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IAccount,
  IAccountResponse,
} from '@lbmx/marketplace-partners/lib/models/account.models';
import { BaseAPIService } from '@lbmx/phoenix-lib-core';
import { AppSetting } from '@lbmx/types';
import { map } from 'rxjs/operators';
import { IConfigCountry } from 'src/app/dtos/registration/registration.dto';
import {
  DateFormatTypes,
  ILanguages,
  ITimeZoneTypes,
  IUserProfileForm,
} from 'src/app/dtos/userProfile/userProfile.dto';
import { ConfigProvider } from 'src/app/provider/config-provider';

import {
  INotificationRequest,
  IUserNotificationResponse,
} from 'src/app/models/user.model';
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  public appSettings: AppSetting = this.configPrv.AppSetting;
  constructor(
    private baseApiService: BaseAPIService,
    private configPrv: ConfigProvider
  ) {}
  public getUserProfile(): Observable<any> {
    this.baseApiService.set(this.appSettings.uriAdministration.getUserProfile);
    return this.baseApiService.get();
  }
  public updateUserProfile(request: IUserProfileForm): Observable<any> {
    this.baseApiService.set(
      this.appSettings.uriAdministration.updateUserProfile
    );
    return this.baseApiService.post(request).pipe();
  }
  public getUserNotifications(): Observable<IUserNotificationResponse> {
    this.baseApiService.set(
      `${this.appSettings.uriMarketplace.getUser}/user/notification/getUserNotifications`
    );
    return this.baseApiService.get();
  }
  public updateUserNotifications(
    request: INotificationRequest[]
  ): Observable<any> {
    this.baseApiService.set(
      `${this.appSettings.uriMarketplace.getUser}/user/notification/addOrUpdateUserNotifications`
    );
    return this.baseApiService.post(request).pipe();
  }
  public getLanguageTypes(): Observable<any> {
    this.baseApiService.set(this.appSettings.uriAdministration.languageTypes);
    return this.baseApiService.get<ILanguages[]>().pipe();
  }

  public getdateFormatTypes(): Observable<any> {
    this.baseApiService.set(this.appSettings.uriAdministration.dateFormatTypes);
    return this.baseApiService.get<DateFormatTypes[]>();
  }
  public getTimezoneTypes(): Observable<ITimeZoneTypes> {
    this.baseApiService.set(this.appSettings.uriConfig.getTimeZoneTypes);
    return this.baseApiService.get<ITimeZoneTypes>().pipe();
  }

  public getCountries(): Observable<IConfigCountry[]> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriConfig.getCountries);
    return this.baseApiService.get<IConfigCountry[]>();
  }

  public getAccount(): Observable<IAccount> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseApiService.set(appSettings.uriMarketplacePartners.getAccount);
    return this.baseApiService.get<IAccountResponse>().pipe(
      map((accountResponse) => {
        return {
          ...accountResponse.account,
          details: accountResponse.account.details[0],
        };
      })
    );
  }
}
