import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { Marketplace } from '@lbmx/types';
import { ConfigProvider } from 'src/app/provider/config-provider';
import { IPortalTemplate, NavBarService } from '../../services/navbar/nav-bar.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { SecureService } from './secure.service';

@Component({
  selector: 'app-portal-modal',
  template: `<p-dialog
    [(visible)]="showModal"
    [showHeader]="false"
    [modal]="true"
    position="center"
    [draggable]="false"
    [resizable]="false"
    [responsive]="true"
    [closeOnEscape]="true"
    styleClass="lbmx-modal"
  >
    <div class=" vertical-flow modal-details-container">
      <h2 class="center">{{ 'NAV.NOPORTAL_TITLE' | transloco }}</h2>
      <ng-container *ngIf="selectOptions; else uploadFiles">
      </ng-container>
      <div class="vertical-flow space-between-050 space-before-000">
        <div class="vertical-flow space-between-050 overflow-wrap">
          <p [innerHtml]="mailText"></p>

        </div>
        <div class="vertical-flow space-between-050 overflow-wrap">
          <div class="center">
            <button
              pButton
              type="button"
              class="ds secondary"
              label="{{ 'ACTIONS.OK' | transloco}}"
              (click)="showModal = false"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>`,
})
export class PortalModalComponent implements OnInit, OnDestroy {
  public showModal: boolean;
  public mailText: string;
  private lastCall: number = Date.now();
  private emailText = '';
  private userName = '';
  private accountLBMXId = 0;
  private fullName = '';

  private _onDestroySubject = new Subject();

  @Input('showPortalModal') set showPortalModalSetter(time: number) {
    if (time || 0 > this.lastCall) {
        this.showModal = true;
    }
    this.lastCall = time;
  }

  constructor(
    private translocoService: TranslocoService,
    private navBarService: NavBarService,
    private store: Store<any>
) {}

  public ngOnInit(): void {
    this.store
      .pipe(
        select(selectors.userProfile),
        tap((userProfile) => {
          this.userName = userProfile?.sub;
          this.accountLBMXId = userProfile?.userInfo?.AccountLBMXId;
          this.fullName = userProfile?.userInfo?.FullName;
        }),
        takeUntil(this._onDestroySubject)
      )
      .subscribe();

    this.navBarService.getPortalTemplate().toPromise().then(
        (result: IPortalTemplate) => {
            this.emailText = result.emailText
                .replace('@@EMAIL@@', this.userName)
                .replace('@@FULLNAME@@', this.fullName)
                .replace('@@LBMXID@@', this.accountLBMXId.toString());
            this.mailText = this.translocoService.translate('NAV.NOPORTAL_TEXT', {
                toAddress: result.toAddress,
                subject: result.subject,
                emailText: this.emailText});
        }
    );
  }

  public ngOnDestroy() {
    this._onDestroySubject.next(true);
  }
}
