export class PasswordRecoveryRequest {
    public username: string;
}

export interface IResetLinkValidationResponse {
    userKey: number;
}

export class ResetPasswordRequest {
    public userKey: number;
    public newPassword: string;
    public token: string;
}

export class ResetToken {
    public token: string;
}
