import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule, CdTableModule } from '@lbmx/analytics';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { SearchContainerLoaderModule } from '@lbmx/search-container';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexmonsterPivotModule } from 'ng-flexmonster';
import { DynamicDialogModule, ProgressSpinnerModule } from 'primeng-lts';
import { ButtonModule } from 'primeng/button';
// import { InputTextModule } from 'primeng-lts';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DashboardLoaderComponent } from './dashboard-loader/dashboard-loader.component';
import { DashboardTemplateComponent } from './dashboard-template/dashboard-template.component';
import { ExcludeOptionsModule } from './exclusions/exclude-options/exclude-options.module';
import { OrderedByLocationExclusionComponent } from './exclusions/ordered-by-location-exclusion/ordered-by-location-exclusion.component';
import { SupplierLocationExclusionComponent } from './exclusions/supplier-location-exclusion/supplier-location-exclusion.component';
import { FlexmonsterLoaderComponent } from './flexmonster-loader/flexmonster-loader.component';
import { FlexmonsterComponent } from './flexmonster/flexmonster.component';
import { PurchasesBySupplierReportComponent } from './purchases-by-supplier-report/purchases-by-supplier-report.component';
import { ReportBuilderLandingComponent } from './report-builder-landing/report-builder-landing.component';
import { ReportLoaderComponent } from './report-loader/report-loader.component';
import { ReportsComponent } from './reports/reports.component';
import { WidgetSelectionComponent } from './widget-selection/widget-selection.component';

@NgModule({
  declarations: [
    DashboardLoaderComponent,
    DashboardTemplateComponent,
    PurchasesBySupplierReportComponent,
    ReportLoaderComponent,
    ReportsComponent,
    WidgetSelectionComponent,
    FlexmonsterComponent,
    FlexmonsterLoaderComponent,
    ReportBuilderLandingComponent,
    OrderedByLocationExclusionComponent,
    SupplierLocationExclusionComponent,
  ],
  imports: [
    AnalyticsModule,
    CdTableModule,
    CommonModule,
    DynamicDialogModule,
    PerFormPrimengModule,
    ProgressSpinnerModule,
    TranslocoModule,
    FlexmonsterPivotModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
    SearchContainerLoaderModule,
    ExcludeOptionsModule,
  ],
  exports: [DashboardLoaderComponent],
  entryComponents: [WidgetSelectionComponent],
})
export class MarketplaceAnalyticsModule {}
