import { Injectable } from '@angular/core';
import { BaseAPIService } from '@lbmx/phoenix-lib-core';
import { AppSetting } from '@lbmx/types';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public appSettings: AppSetting = this.configProvider.AppSetting;
  constructor(
    private baseApiService: BaseAPIService,
    private configProvider: ConfigProvider
  ) {}

  public getHome() {
    this.baseApiService.set(
      `${this.appSettings.uriMarketplacePartners.getAccount}/home`
    );

    return this.baseApiService.get();
  }

  public getURL(imageKey: number, accountKey: number): string {
    try {
      return `${this.appSettings.uriAdministration.accountAssetsEndPoint}/${imageKey}?accountKey=${accountKey}`;
    } catch {
      return '';
    }
  }
}
