import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  public data$: Observable<object>;
  public subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.subscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component(since routing data is not persistent on page refresh)
      if (e instanceof NavigationEnd) {
        this.initializeErrorInfo();
      }
    });
  }
  public ngOnInit(): void {
    /** empty block */
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  public initializeErrorInfo() {
    this.data$ = this.route.paramMap.pipe(map(() => window.history.state));
  }
}
