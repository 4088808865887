import { Component, OnDestroy } from '@angular/core';
import { TemplateTypes } from '@lbmx/per-form-primeng';
import { PopupService } from '@lbmx/root-services';
import { Request } from '@lbmx/web-core';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-wizard-popup',
  templateUrl: './wizard-popup.component.html',
  styleUrls: ['./wizard-popup.component.scss'],
})
export class WizardPopupComponent implements OnDestroy {
  public baseUrl = this.configProvider.AppSetting.uriBase.apiBaseUrl;
  public config$ = new BehaviorSubject<Request>(null);
  public templateTypes = TemplateTypes;

  private _onDestroy = new Subject();
  private _config = this.popupService.wizardConfig$.pipe(
    tap((request) => this.config$.next(request))
  );
  private _close = this.popupService.cancel$.pipe(tap(() => this.reset()));

  constructor(
    public popupService: PopupService,
    private configProvider: ConfigProvider
  ) {
    merge(this._config, this._close)
      .pipe(takeUntil(this._onDestroy))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
  }

  public reset(): void {
    this.config$.next(null);
    this.popupService.reset();
  }
}
