import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerFormModule } from '@lbmx/per-form';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { TranslocoModule } from '@ngneat/transloco';
import {
  AccordionModule,
  ButtonModule,
  CalendarModule,
  CheckboxModule,
  InputNumberModule,
  MultiSelectModule,
  RadioButtonModule,
} from 'primeng-lts';
import { LuiAccordionModule } from '../accordion/accordion.module';
import { LuiAdvancedSearch } from './advanced-search';

@NgModule({
  declarations: [LuiAdvancedSearch],
  imports: [
    CommonModule,
    AccordionModule,
    LuiAccordionModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    MultiSelectModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TranslocoModule,
    CalendarModule,
    InputNumberModule,
    PerFormPrimengModule,
    PerFormModule,
  ],
  exports: [LuiAdvancedSearch],
})
export class AdvancedSearchModule {}
