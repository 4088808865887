import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { selectors } from '@lbmx/app-state';

import { SpinnerService } from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Subscription, throwError } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';
import { AppError } from './app.error.helper.service';

export class HttpError {
  public message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements OnDestroy {
  public subscriptions: Subscription[] = [];
  constructor(
    private translocoSrv?: TranslocoService,
    private appError?: AppError,
    private spinnerSrv?: SpinnerService,
    private store?: Store,
    private actions$?: Actions
  ) {}

  public ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
  //#region Methods
  /**
   * @remarks
   * Checks for different error codes and creates an Observable<never>
   * and immediately emits an error notification.
   * The error is then passed directly to the AppError. The AppError
   * then calls the log function to log the error and show toast notification.
   *
   * At minimum every kind of error will be logged
   * @param error : actual error content (i.e. Promise Reject)
   * @returns
   * an Observable<never>
   */
  public handleHttpErrorResponse(error: HttpErrorResponse) {
    this.spinnerSrv.off();
    this.subscriptions.push(
      forkJoin([
        this.store.pipe(
          select(selectors.userProfile),
          take(1),
          switchMap((user) => {
            this.translocoSrv.setActiveLang(
              user.userInfo.LanguageCode || 'en-ca'
            );
            return this.translocoSrv.selectTranslate('COMMON').pipe(take(1));
          })
        ),
        this.store.select(selectors.userFeature).pipe(first()),
      ])
        .pipe()
        .subscribe(([translation, userFeature]) => {
          this.appError.log(error, error.message);
          if (
            !error.error ||
            (error.error &&
              (error.error.errorCode === 2 || error.error.errorCode === 1))
          ) {
            if (error.status === 401 || error.status === 403) {
              if (
                userFeature.tokenExpires !== null &&
                new Date(userFeature.tokenExpires) < new Date()
              ) {
                this.appError.showInfoMsg(translation.SESSION_EXPIRED);
              }
            } else {
              this.appError.showErrorMsg(translation.UNKNOWN_ERROR);
            }
          }
        })
    );
    // Server side error

    return throwError(error);
  }
  //#endregion
}
