import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerFormPrimengModule } from '@lbmx/per-form-primeng';
import { UtilsModule } from '@lbmx/phoenix-lib-utils';
import { SearchContainerLoaderModule } from '@lbmx/search-container';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule, DialogModule, ProgressSpinnerModule } from 'primeng-lts';
import { OpenReceivablesComponent } from './open-receivables.component';

@NgModule({
  declarations: [OpenReceivablesComponent],
  imports: [
    CommonModule,
    DialogModule,
    SearchContainerLoaderModule,
    TranslocoModule,
    UtilsModule,
    PerFormPrimengModule,
    ButtonModule,
    ProgressSpinnerModule,
  ],
})
export class OpenReceivablesModule {}
