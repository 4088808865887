import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';
import { InterceptorResponseService } from '@lbmx/root-services';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  public set request(request: HttpRequest<any>) {
    this._requestSubject.next(request);
  }
  public get request(): HttpRequest<any> {
    return this._requestSubject.value;
  }

  public set status(status: number) {
    this._statusSubject.next(status);
  }
  public get status(): number {
    return this._statusSubject.value;
  }

  private _requestSubject = new BehaviorSubject<HttpRequest<any>>(null);
  private _statusSubject = new BehaviorSubject<number>(null);

  public showOtpAuth = false;

  public showDialog$: Observable<boolean> = this._requestSubject
    .asObservable()
    .pipe(map((request) => request != null));

  private showStatus$: Observable<number> = this._statusSubject
    .asObservable()
    .pipe(
      filter((status) => status != null),
      tap((status) => this.OTPStatus(status))
    );

  constructor(
    private client: HttpClient,
    private translocoService: TranslocoService,
    private toast: ToastrNotificationService,
    private interceptorResponseService: InterceptorResponseService
  ) {
    this.showStatus$.subscribe();
  }

  public submitWithOTP(otp: string) {
    if (typeof otp !== 'string') {
      this.request = null;
      return;
    }

    const withOTP = this.request.clone({
      headers: new HttpHeaders({
        'x-otp': otp,
      }),
    });
    this.request = null;

    this.client
      .request(withOTP)
      .pipe(
        tap((response: any) => {
          if (response?.status === 200) {
            this.interceptorResponseService.success();
            this.status = response?.status;
          }
        })
      )
      .subscribe();
  }

  private OTPStatus(status: number) {
    switch (status) {
      case 200:
        this.toast.successNotify(
          this.translocoService.translate('GROUP.SUCCESS_TOAST_MESSAGE')
        );
        break;
      case 410:
        this.toast.errorNotify(this.translocoService.translate('OTP.EXPIRED'));
        break;
      case 429:
        this.toast.errorNotify(
          this.translocoService.translate(
            'OTP.VERIFICATION_FAILED_TOO_MANY_TIMES'
          )
        );
        break;
      default:
        break;
    }
  }
}
